.line{
    background-color: #282828;
    color: transparent;
    height: 2px;
    margin-bottom: 30px;
    margin-top: 30px;
}
.News {
    margin-top: 40px;
    .row {
        margin-right: 0px !important;
    }
}