.form {
    font-size: 14px;
    padding: 17px 20px;
    background-color: #152631;
    margin-top: 60px;
    margin-bottom: 20px;
    .title {
        font-size: 22px;
        padding-bottom: 14px;
    }

    .MessageI {
        color: black;
        font-family: Oxygen, Verdana, sans-serif;
        font-size: 14px;
        height: 138px;
        padding: 9px 10px;
        resize: none;
    }

    input {
        color: black !important;
        font-family: Oxygen, Verdana, sans-serif;
        font-size: 14px;
        padding: 9px;
    }

    .btn {
        width: auto;
        margin-top: 17px;
        color: white;

        .icon {
            margin-right: 10px;
            font-size: 1.2rem;
        }

        background-color: #d55a00;
        border-color: #d55a00;

        &:hover {
            background-color: #e46713 !important;
            border-color: #e46713 !important;
        }
    }

}