.card {
  //display: flex;
  //flex-direction: row;
  background: none repeat scroll 0 0 #282828;
  border-bottom: 15px solid black;
  display: inline-block;
  min-height: 163px;
  padding: 10px;
  vertical-align: top;
  width: 100%;
  //max-height: 190px;
  height: 100%;
  text-decoration: none !important;
  margin-top: 10px;
  //margin-bottom: 10px;

  background-color: #282828;

  padding: 10px;

  .image {
    /* width: 30%;
        height: 110px; */

    /* max-height: 300px; */
    //width: 180px;
    cursor: pointer;
    /* display: flex;
        justify-content: center; */
    //height: 100%;
    float: left;
    display: inline;

    margin-right: 10px;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100% !important;
    }

    img {
      height: 130px;
      width: 180px;
      object-fit: cover;

    }

    /* @media (min-width: 535px) {
            float: left;
            display: inline;
        } */
  }

  .title {
    text-overflow: ellipsis;
    line-height: 1.3;
    padding-top: 2px;
    font-size: 17px;
    font-family: Oxygen, Verdana, Sans-serif;
    font-weight: bold !important;
    text-transform: none !important;
    line-height: inherit;
    color: #ff9201;
    cursor: pointer;

  }

  /*  .description {
      margin-top: 10px;
      color: white;
      font-size: 13px;
      line-height: 1.3;
      font-family: Oxygen, Verdana, Sans-serif;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  } */
  .description {
    // ... (tu código existente)
    margin-top: 10px;
    color: white;
    font-size: 13px;
    line-height: 1.3;
    font-family: Oxygen, Verdana, Sans-serif;
    /* display: -webkit-box;
      -webkit-box-orient: vertical; */
    /* overflow: hidden;
      -webkit-line-clamp: 5;  */
    // Cambia este valor según tus necesidades
  }

  .content {
    width: 70%;
    //padding-left: 12px !important;
    padding: 0px 10px 0px 12px;
    height: 100%;

    .title {
      text-overflow: ellipsis;
      line-height: 1.3;
      padding-top: 2px;
      font-size: 17px;
      font-family: Oxygen, Verdana, Sans-serif;
      font-weight: bold !important;
      text-transform: none !important;
      line-height: inherit;
      color: #ff9201;
      cursor: pointer;

    }

    /*  .description {
            margin-top: 10px;
            color: white;
            font-size: 13px;
            line-height: 1.3;
            font-family: Oxygen, Verdana, Sans-serif;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        } */
    .description {
      // ... (tu código existente)
      margin-top: 10px;
      color: white;
      font-size: 13px;
      line-height: 1.3;
      font-family: Oxygen, Verdana, Sans-serif;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 5; // Cambia este valor según tus necesidades
    }

    .description-wrapper {
      position: relative;

      &:after {
        content: '...';
        position: absolute;
        bottom: 0;
        right: 0;
        background: linear-gradient(to right, rgba(40, 40, 40, 0), #282828 70%);
        padding: 0 8px; // Espacio entre los puntos suspensivos y el texto
      }
    }

    .registered {
      color: #6ad3ff;
      text-align: right;
      font-size: 13px;
      font-family: Oxygen, Verdana, Sans-serif;
      margin-top: 5px;
    }
  }

  .registered {
    color: #6ad3ff;
    text-align: right;
    font-size: 13px;
    font-family: Oxygen, Verdana, Sans-serif;
    margin-top: 5px;
  }

  &:hover {
    background-color: #1a1a1a;
  }
}