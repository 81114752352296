.cardLink{
    text-decoration: none !important;
}
.card {
    border: none;
    background-color: black;
    margin-bottom: 24px;
    color: white;

    //height: 274px;
    .top {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 203px;
        overflow: hidden;

        .icon {
            font-size: 80px;
            position: absolute;
            left: 0px;
            display: none;
            /* visibility: hidden;
            opacity: 0;
            transition: visibility 0.5s, opacity 0.5s, transform 0.6s linear; */
        }

    }

    .body {
        padding: 20px;

        .title {
            font-size: 30px;
        }
        .description {
            font-size: 12px;
            margin-top: 15px;
            line-height: 1.6em;
        }
    }

    &:hover {
        cursor: pointer;

        .top {
            .icon {
                display: block;
                /* visibility: visible;
                opacity: 1;
                transform: translateX(15px); */
            }
        }

        .body {
            .title {
                color: #ffa200;
            }
        }
    }

    @media (max-width: 768px) {
        .body {
            margin-top: -79px;
            z-index: 1000;
            background-color: rgba(0, 0, 0, 0.7);
        }
    }

    @media (max-width: 576px) {
        .top {
            .icon {
                font-size: 60px;
            }
        }
        .body {
            margin-top: -67px;
            .title {
                font-size: 20px;
            }
        }

    }

}