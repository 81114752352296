.topPart {
    .title {
        line-height: 70px;
        //font-size: 60px;
        //font-weight: bold;
        margin: 13px 0;
        margin-bottom: 20px;
        .btn{
            //background-color: transparent !important;
            border: 0px;
            padding: 0px;
            margin-left: 22px;
            color: #ff4837;
            font-size: 17.14px;
            cursor: pointer;
            text-decoration: none;
            .icon{
                margin-right: 10px;
            }
            &:hover {
                text-decoration: underline;
            }
            @media screen and (max-width: 768px){
                display: none;
            }
        }
    }

    .description {
        font-size: 20px;
        line-height: 30px;
        margin-top: 20px;
        margin-block-start: 1em;
        margin-block-end: 1em;
    }
    .warning {
        font-size: 20px;
        line-height: 1.5rem;
        margin: 20px 0;
        font-weight: bold;
    }
    @media  (max-width: 500px) {
        .title {
            font-size: 40px;
        }
        .description {
            font-size: 15px;
        }
        
    }
}