.modal{
    //$modal-content-color:  black;
    .header{
        //background-color: black;
        color: white;
        fill: white;
        border-bottom: none !important;
        button{
            color: white;
            fill: white;
        }
    }
    .body{
        //background-color: black;
        //color:black;
        .video{
            width: 100%;
            min-height: 300px;
            iframe{
                width: 100%;
                height: 100%;
            }
        }
        .title{
            line-height: 1.5em;
            font-size: 1.4em;
            margin: 1em 0em 1em 0;
            font-weight: bold;
        }
        .description{
            font-size: 1em;
        }
        border-bottom-left-radius: 10px !important;
    }      
}