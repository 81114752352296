.dropmenu{
    //background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);
    background-color: #393939;
    left: 0;
    position: absolute;
    right: 47px;
    width: 100%;
    opacity: 1;
    border-bottom: 3px solid white;
    z-index: 1;
    position: inherit;
    text-align: left;
    //height: 250px;
    top: -2px;
    @media screen and (max-width: 992px) {
       display: none !important;
    }
}