.container {
    padding-top: 60px;
    padding-left: 35px;

    .title {
        a {
            cursor: pointer;
        }

        font-size: 60px;
        line-height: 55px;
        margin-bottom: 60px;
        //font-weight: bold;
        word-wrap: break-word;
    }

    .content {
        .main {
            margin-right: 0px !important;
        }
    }

}

.aboutItems {
    .main {
        margin-right: 0px !important;

        .item {
            display: flex;
            flex-direction: column;

            .btn {
                width: auto;
                margin-top: auto;
                margin-bottom: 10px;
                margin-left: 40px;
                width: 170px;
                background-color: #bc1000;
                border-color: #bc1000;

                .icon {
                    margin-right: 10px;
                    font-size: 1.2rem;
                }

                &:hover {
                    background-color: #d12c10 !important;
                    border-color: #d12c10 !important;
                }
            }
        }
    }

}