.form {
    //font-size: 14px;
    padding: 17px 20px;
    background-color: white;
    color: black;
    margin-top: 60px;
    margin-bottom: 20px;
    font-family: Oxygen, Verdana, sans-serif;
    .title {
        font-size: 22px;
        padding-bottom: 14px;
        font-weight: bold;
    }
    .group{
        padding-bottom: 3%;
    }
    input {
        color: black !important;
        font-family: Oxygen, Verdana, sans-serif;
        font-size: 14px;
        padding: 9px;
        border: 1px solid #999;
    }

    .btn {
        width: auto;
        margin-top: 17px;
        color: white;
        background-color: #aaa;
        border-color: #aaa;

        &:hover {
            background-color: #777 !important;
            border-color: #777 !important;
        }
    }

}