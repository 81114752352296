.cont{
    font-size: 20px;
    background-color: #282828;
    margin-top: 20px;
    padding: 5px 23px 10px;
    .link{
        color: #FF0000;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
}