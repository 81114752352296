.option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .top {
        font-size: 30px;
        margin-top: 20px;
        margin-bottom: 30px;

        color: white !important;
        text-decoration: none !important;
        .icon {
            font-size: 65px;
        }

        &:hover {
            cursor: pointer;
            color: #daab00 !important;
        }

        @media (max-width: 768px) {
            display: flex !important;
            flex-direction: row-reverse;
            align-items: baseline;
            justify-content: space-between;
            margin-bottom: 0px;

            .icon {
                font-size: 45px;
            }

        }
    }

    .description {
        //max-width: 75%;
        font-size: 15px;

        @media (max-width: 768px) {
            text-align: start;
        }
    }
}