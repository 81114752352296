.bc{
    margin-top: 45px;
    margin-bottom: 21px;
    ol{
        margin-bottom: 0 !important;
        padding: 0 !important;
    }
    .option{
        a{
            color: white;
            text-decoration: none;
            &:hover{
                color: white;
                text-decoration: underline;
            }
        }
    }
    .active{
        color: #ffa200;
    }
}