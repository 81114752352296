.main {
    margin-top: 25px;
    color: #ababab;
    margin-top: 40px;
    font-size: 13px;

    .options{
        display: flex;
        flex-direction: row;
        //justify-content: flex-end;
        width: 100%;
        a{
            color: white;
            cursor: pointer;
            text-decoration: none;
            margin-right: 5px;
            &:hover{
                color:  white;
                text-decoration: underline;
            }
        }

    }

    .item {
        display: flex;
        flex-direction: row;

        .title {
            text-transform: capitalize;
            display: inline-block;
            min-width: 160px;
            color: #d5d5d5;
            font-weight: bold;
            padding-right: 10px;
            text-align: right;
        }
    }
}