.new {
    .content {
        margin-top: 30px;

        .title {
            //font-family: "Oxygen";
            font-size: 38px;
            font-weight: bold;
            margin-bottom: 15px;
        }

        .date {
            color: #58bdff;
            font-size: 24px;
            margin-bottom: 41px;

            @media (min-width: 992px) {
                margin-bottom: 17px;
            }
        }

        .image {
            img {
                width: 100% !important;
                height: auto !important;
            }
        }

        .text {
            color: white !important;
            margin-top: 37px;
            li {
                list-style: outside none disc;
                color: white !important;
                padding-bottom: 2px;
                line-height: 1.4em;
            }
        }

        .share {
            margin-top: 20px;
            margin-bottom: 20px;

        }
    }

    .newsComment {
        padding: 5px;
        background-color: white;
    }
}