.contactUs{
    margin-top: 57px;
    padding-left: 30px;
    .title{
        font-size: 48px;
    }
    .subtitle{
        font-size: 18px;
        padding-bottom: 20px;
    }
}