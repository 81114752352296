.card {
    margin-bottom: 65px;
    padding-left: 0;
    position: static;
    text-decoration: none !important;
    color: white;
    &:hover {
        color: white;
        text-decoration: none !important;
    }
    .top {
        background-repeat: no-repeat;
        background-size: auto auto;
        display: inline-block;
        font-size: 28px;
        font-weight: bold;
        padding-top: 202px;
        margin-bottom: 8px;
        width: 100%;
        position: relative;
        .icon{
            position: absolute;
            top: 58px;
            font-size: 80px;
            display: none;
            /* visibility: hidden;
            opacity: 0;
            transition: visibility 0.5s, opacity 0.5s, transform 0.6s linear; */
        }
        &:hover {
            cursor: pointer;
            color:#ffc800 !important;
            .icon{
                display: block;
                color:white;
                /* visibility: visible;
                opacity: 1;
                transform: translateX(15px); */
            }
        }
    }
    .description {
        font-size: 15px;
        margin-bottom: 60px;
        &:hover {
            color: white;
        }
    }
    .btn {
        width: auto;
        //margin-left: 12px;
        color: white;
        background-color: #25455a;
        border: none;
        bottom: 0px;
        position: absolute;
        .icon {
            margin-right: 10px;
            font-size: 1.2rem;
        }
        &:hover {
            background-color: #4c7892;
        }
    }
}