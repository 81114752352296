.card {
    border: none;
    /* background-color: black; */
    margin-bottom: 24px;
    position: relative;

    //height: 274px;
    .top {
        display: flex;
        justify-content: center;
        flex-direction: column;

        .icon {
            font-size: 80px;
            position: absolute;
            left: 0px;
            display: none;
            /* visibility: hidden;
            opacity: 0;
            transition: visibility 0.5s, opacity 0.5s, transform 0.6s linear; */
        }

    }

    .body {
        position: absolute;
        padding: 20px;
        margin-top: -79px;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.7);
        bottom: 0;

        &:hover {
            text-decoration: underline;
        }

        /* .title {
            font-size: 30px;

        } */

        .date {
            font-size: 24px;
            color: #58bdff;
        }

        @media (max-width: 993px) {
            .date {
                font-size: 14px;
                color: #58bdff;
            }

            .title {
                font-size: 20px;

            }
        }
    }

    &:hover {
        cursor: pointer;

        /* .top {
            .icon {
                display: block;
            }
        } */

        /* .body {
            .title {
                color: #ffa200;
                margin-top: -79px;
                z-index: 1000;
                background-color: rgba(0, 0, 0, 0.7);
            }
        } */
    }

    /* @media (max-width: 768px) {
        .body {}
    } */

}

.secondType {
    @media (max-width: 993px) {
        display: none;
    }
}