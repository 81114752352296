.socials{
	justify-content:end;
	.icon {
		cursor: pointer;
		fill: white;
		transition: fill 0.3s ease-in-out;
	}
	@media (max-width: 991px) {
		justify-content:start;
	}
}

