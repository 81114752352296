.news {
    float: right;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .title {
        //background-color: white;
        color: black;
        //width: auto;
        font-size: 20px;
        //padding: 5px;
        margin-top: 52px;
        //max-width: 200px;
        display: flex;
        justify-content: flex-end;
        text-align: end;
        .titleI{
            text-align: end;
            background-color: white;
            width: auto;
            padding: 5px;
            &:hover{
                cursor: pointer;
                background-color: rgb(221, 221, 221);
            }
        }
        
    }
    .arrows{
        display: flex;
        justify-content: flex-end;
        text-align: end;
        margin-top: 15px;
        margin-bottom: 15px;
        .arrow{
            background-color: white;
            border: none;
            font-size: 20px;
            padding: 0px 5px !important;
            .icon{
                fill: black;
            }
        }
        .space{
            width: 30px;
            //background-color: white;
        }
    }
    @media (min-width: 992px){
        .title {
            margin-top: 115px;
        }
    }
}
@media (max-width: 991px)
{
    .news {
        float: left ;
        .title{
            justify-content: flex-start;
            text-align: start;
            .titleI{
                text-align: start;
            }
        }
        .arrows{
            justify-content: flex-start;
        }
    }
}