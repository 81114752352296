.accordion {
    margin-bottom: 24px;
    .btn {
        color: white;
        width: 100%;
        text-align: start;
        border: none;
        font-size: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 23px 10px;
        margin: 6px;
    }

    .item {
        background-color: #282828;
        border: 0;

        .header {
            background-color: #282828;
            border-radius: 0px !important;

            button {
                background-color: #282828 !important;
                border-radius: 0px !important;

                &:hover {
                    border-color: transparent !important;
                    outline: 0;
                    box-shadow: none !important;
                }

                ::after {
                    color: #fff !important;
                }
            }
        }

        .collapse {
            border-radius: 0px !important;

            .body {
                padding: 12.4px;
                border-radius: 0px !important;
            }
        }
    }
}