.form {
    padding: 20px;
    width: 100%;
    //background: #d65a00;

    .title {
        font-size: 2.2em;
    }

    .description {
        font-size: 13px;
        margin-top: 7px;
    }

    .formGroup {
        font-size: 1.2em;
        margin-bottom: 5px;
        margin-top: 20px;
        width: 100%;

        input {
            font-family: Oxygen, Verdana, Sans-serif;
            color: black;
        }
    }

    .btn {
        margin-top: 17px !important;
        width: auto;
        background-color: var(--btncolor);
        border-color: var(--btncolor);

        .icon {
            margin-right: 5px;
            font-size: 1.2rem;
        }

        &:hover {
            background-color: var(--btncolor)  !important;
            border-color: var(--btncolor) !important;
        }
    }
}