.sideBar {
    margin-top: 57px;

    .title {
        font-size: 30px;
        margin-bottom: 60px;
    }

    .info {
        font-size: 13.98px;
        margin-top: 42px;
    }

    ul {
        list-style: none;
        padding: 0;

        .link {
            font-size: 20px;
        }

        a {
            color: #6ad3ff;
            text-decoration: none;

            &:hover {
                color: #6ad3ff;
                text-decoration: underline;
            }
        }
    }

    .btn {
        width: auto;
        //margin-left: 12px;
        color: white;

        .icon {
            margin-right: 10px;
            font-size: 1.2rem;
        }

        //margin-left: 12px;
        background-color: #bc1000;
        border-color: #bc1000;

        .icon {
            margin-right: 10px;
            font-size: 1.2rem;
        }

        &:hover {
            background-color: #d12c10 !important;
            border-color: #d12c10 !important;
        }
    }

}