.btn{
    float: right !important;
    width: auto;
    font-size: 1rem;
    background-color: black !important;
    border-color: black !important;
    margin-left: 12px;
    .icon{
        margin-right: 10px;
        font-size: 2.5rem;
    }
    &:hover{
        background-color: rgb(39, 39, 39) !important;
        border-color: rgb(39, 39, 39) !important;
    } 
}