.social_media_box {
	aspect-ratio: 1/1;
	background-color: #152631;
	padding: 0.7rem;
	cursor: pointer;
	transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
	&:hover {
		background-color: #1a2f3d;
		transform: scale(1.1);
	}
}

/* .btn {
	text-align: center;
	height: 32px;
	width: 32px;
	padding: 0;
	//border-radius: 0 5px 5px 0;
	background-color: #d1d1d1;
	color: black;
	border: 1px solid #d1d1d1;

	&:hover {
		background-color: #9c9c9c;
		color: black;
	}
} */
