.card {
    text-align: end;
    //max-width: 170px;
    .date{
        font-size: 15px;
        color: #a6a6a6;
        font-weight: bold;
    }
    .title{
        max-width: 200px;
        font-size: 18px;
        font-weight: bold;
    }
    .btn {
        width: auto;
        margin-top: 10px;
        color: white;
        //margin-left: 12px;
        background-color: #ff9200;
        border: none;
        .icon {
            margin-right: 10px;
            font-size: 1.2rem;
        }
        &:hover{
            color: white;
            background-color: #ffa623;
        }
    }
    .social{
        margin-top: 20px;
    }

}
@media (max-width: 991px) {
    .card {
        text-align: start;
        .title{
            max-width: 600px;
        }
    }
}