.welcome{
    .title{
        font-size: 4em;
        font-weight: bold;
        display: inline-block;
        margin-top: 52px;
        p{
            line-height: 1em !important;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
        .ofRwanda{
            font-size: 36px !important;
        }
    }
    .paragraph{
        font-size: 20px;
        line-height: 1.8em;
        max-width: 670px;
        margin-top: 20px;
    }
    @media (min-width: 992px){
        .title {
            margin-top: 115px;
        }
    }
}