.container {
    
    

    .content {
        .title {

            font-size: 40px;
            line-height: 55px;
            margin-bottom: 20px;
            //font-weight: bold;
            word-wrap: break-word;
    
            @media (min-width: 485px){
                font-size: 60px;
            }
        }
        padding-top: 60px;
        padding-left: 50px;
        padding-right: 40px;
        .description {
            font-size: 20px;
            margin-bottom: -18px !important;
            
        }

        .main {
            margin-right: 0px !important;
        }
    }

}