/*add your css rules in this file*/
/*** Post genocide image links  ***/
.post-gacaca {
	background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('https://genocidearchiverwanda.org.rw/rga/images/9/9d/GACACA.png');
}

.post-gacaca:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(to left, transparent, rgba(0, 0, 0, 0.4)),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('https://genocidearchiverwanda.org.rw/rga/images/9/9d/GACACA.png');
}

.post-ndi {
	background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('https://genocidearchiverwanda.org.rw/rga/images/2/23/Ndi_Umunyarwanda.jpg');
}

.post-ndi:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(to left, transparent, rgba(0, 0, 0, 0.4)),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('https://genocidearchiverwanda.org.rw/rga/images/2/23/Ndi_Umunyarwanda.jpg');
}

.post-tig {
	background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('../../../../assets/img/wx/post-TIG.png');
}

.post-tig:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(to left, transparent, rgba(0, 0, 0, 0.4)),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('../../../../assets/img/wx/post-TIG.png');
}

.post-ingando {
	background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('https://genocidearchiverwanda.org.rw/rga/images/f/fd/Ingando.jpg');
}

.post-ingando:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(to left, transparent, rgba(0, 0, 0, 0.4)),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('https://genocidearchiverwanda.org.rw/rga/images/f/fd/Ingando.jpg');
}

/*** Young people and peace building image links  ***/
.yp-blog {
	background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('../../../../assets/img/wx/yp-blog.png');
}

.yp-blog:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('../../../../assets/img/wx/yp-blog.png');
}

.yp-messages {
	background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('../../../../assets/img/wx/yp-messages.png');
}

.yp-messages:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('../../../../assets/img/wx/yp-messages.png');
}

.yp-art {
	background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('../../../../assets/img/wx/yp-artwork.png');
}

.yp-art:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('../../../../assets/img/wx/yp-artwork.png');
}

.yp-poems {
	background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('../../../../assets/img/wx/yp-poems.png');
}

.yp-poems:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('../../../../assets/img/wx/yp-poems.png');
}

/**MOVE TO MAIN**/
.sr-adv-title.sr-adv-title-alone {
	width: 100%;
}

.advancedsearch-box .header-search-box {
	border: 0 none;
	color: #939598;
	font-family: Verdana;
	font-size: 14px;
	height: 36px;
	margin-top: 0;
	padding-left: 5px;
	padding-top: 2px;
	vertical-align: top;
	width: 80%;
	width: calc(100% - 60px);
}

.advancedsearch-box {
	background: none repeat scroll 0 0 #484848;
	display: inline-block;
	padding: 20px;
	max-width: 100%;
	margin-bottom: 200px;
}

.advancedsearch-container-title {
	color: white;
	font-size: 18px;
	font-weight: normal;
	margin-bottom: 10px;
	vertical-align: middle;
}

.advancedsearch-filter {
	background: none repeat scroll 0 0 #727272;
	color: white;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 5px;
	margin-right: 10px;
	padding: 10px;
	vertical-align: middle;
	transition: all 0.4s;
}

.advancedsearch-container.advancedsearch-container-half {
	display: inline-block;
	box-sizing: border-box;
	padding-right: 85px;
	width: 50%;
	min-width: 400px;
}

.advancedsearch-container {
	margin-top: 38px;
}

/* .advancedsearch-container select {
	max-width: 100%;
} */

/* .mainpage-latest-news-title > a {
	color: black;
}
 */
/**Mini bootstrap**/
/* * {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
} */

/* span.dk_label {
	color: white;
	font-family: Oxygen;
} */

.searchbar {
	background: #484848;
	padding: 15px;
	margin-bottom: 135px;
}

.searchtimeperiod {
	margin-top: 15px;
}

.advancedsearch {
	position: relative;
}
/* *:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
} */

.container {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
@media (min-width: 768px) {
	.container {
		width: 750px;
	}
}
@media (min-width: 992px) {
	.container {
		width: 970px;
	}
	.mainpage-news-landing-recent-replace {
		position: absolute;
		right: 5%;
		top: 200px;
		text-align: right;
	}

	.mainpge-newsl-item-date {
		margin-top: 10px;
	}

	.mainpage-latest-news-title {
		display: block;
	}
}
@media (min-width: 1200px) {
	.container {
		width: 1170px;
	}
}
.container-fluid {
	margin-right: auto;
	margin-left: auto;
	overflow: hidden;
}
.row {
	margin-right: -15px;
	margin-left: -15px;
}
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
	float: left;
}
.col-xs-12 {
	width: 100%;
}
.col-xs-11 {
	width: 91.66666667%;
}
.col-xs-10 {
	width: 83.33333333%;
}
.col-xs-9 {
	width: 75%;
}
.col-xs-8 {
	width: 66.66666667%;
}
.col-xs-7 {
	width: 58.33333333%;
}
.col-xs-6 {
	width: 50%;
}
.col-xs-5 {
	width: 41.66666667%;
}
.col-xs-4 {
	width: 33.33333333%;
}
.col-xs-3 {
	width: 25%;
}
.col-xs-2 {
	width: 16.66666667%;
}
.col-xs-1 {
	width: 8.33333333%;
}
.col-xs-pull-12 {
	right: 100%;
}
.col-xs-pull-11 {
	right: 91.66666667%;
}
.col-xs-pull-10 {
	right: 83.33333333%;
}
.col-xs-pull-9 {
	right: 75%;
}
.col-xs-pull-8 {
	right: 66.66666667%;
}
.col-xs-pull-7 {
	right: 58.33333333%;
}
.col-xs-pull-6 {
	right: 50%;
}
.col-xs-pull-5 {
	right: 41.66666667%;
}
.col-xs-pull-4 {
	right: 33.33333333%;
}
.col-xs-pull-3 {
	right: 25%;
}
.col-xs-pull-2 {
	right: 16.66666667%;
}
.col-xs-pull-1 {
	right: 8.33333333%;
}
.col-xs-pull-0 {
	right: auto;
}
.col-xs-push-12 {
	left: 100%;
}
.col-xs-push-11 {
	left: 91.66666667%;
}
.col-xs-push-10 {
	left: 83.33333333%;
}
.col-xs-push-9 {
	left: 75%;
}
.col-xs-push-8 {
	left: 66.66666667%;
}
.col-xs-push-7 {
	left: 58.33333333%;
}
.col-xs-push-6 {
	left: 50%;
}
.col-xs-push-5 {
	left: 41.66666667%;
}
.col-xs-push-4 {
	left: 33.33333333%;
}
.col-xs-push-3 {
	left: 25%;
}
.col-xs-push-2 {
	left: 16.66666667%;
}
.col-xs-push-1 {
	left: 8.33333333%;
}
.col-xs-push-0 {
	left: auto;
}
.col-xs-offset-12 {
	margin-left: 100%;
}
.col-xs-offset-11 {
	margin-left: 91.66666667%;
}
.col-xs-offset-10 {
	margin-left: 83.33333333%;
}
.col-xs-offset-9 {
	margin-left: 75%;
}
.col-xs-offset-8 {
	margin-left: 66.66666667%;
}
.col-xs-offset-7 {
	margin-left: 58.33333333%;
}
.col-xs-offset-6 {
	margin-left: 50%;
}
.col-xs-offset-5 {
	margin-left: 41.66666667%;
}
.col-xs-offset-4 {
	margin-left: 33.33333333%;
}
.col-xs-offset-3 {
	margin-left: 25%;
}
.col-xs-offset-2 {
	margin-left: 16.66666667%;
}
.col-xs-offset-1 {
	margin-left: 8.33333333%;
}
.col-xs-offset-0 {
	margin-left: 0;
}
@media (min-width: 768px) {
	.col-sm-1,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12 {
		float: left;
	}
	.col-sm-12 {
		width: 100%;
	}
	.col-sm-11 {
		width: 91.66666667%;
	}
	.col-sm-10 {
		width: 83.33333333%;
	}
	.col-sm-9 {
		width: 75%;
	}
	.col-sm-8 {
		width: 66.66666667%;
	}
	.col-sm-7 {
		width: 58.33333333%;
	}
	.col-sm-6 {
		width: 50%;
	}
	.col-sm-5 {
		width: 41.66666667%;
	}
	.col-sm-4 {
		width: 33.33333333%;
	}
	.col-sm-3 {
		width: 25%;
	}
	.col-sm-2 {
		width: 16.66666667%;
	}
	.col-sm-1 {
		width: 8.33333333%;
	}
	.col-sm-pull-12 {
		right: 100%;
	}
	.col-sm-pull-11 {
		right: 91.66666667%;
	}
	.col-sm-pull-10 {
		right: 83.33333333%;
	}
	.col-sm-pull-9 {
		right: 75%;
	}
	.col-sm-pull-8 {
		right: 66.66666667%;
	}
	.col-sm-pull-7 {
		right: 58.33333333%;
	}
	.col-sm-pull-6 {
		right: 50%;
	}
	.col-sm-pull-5 {
		right: 41.66666667%;
	}
	.col-sm-pull-4 {
		right: 33.33333333%;
	}
	.col-sm-pull-3 {
		right: 25%;
	}
	.col-sm-pull-2 {
		right: 16.66666667%;
	}
	.col-sm-pull-1 {
		right: 8.33333333%;
	}
	.col-sm-pull-0 {
		right: auto;
	}
	.col-sm-push-12 {
		left: 100%;
	}
	.col-sm-push-11 {
		left: 91.66666667%;
	}
	.col-sm-push-10 {
		left: 83.33333333%;
	}
	.col-sm-push-9 {
		left: 75%;
	}
	.col-sm-push-8 {
		left: 66.66666667%;
	}
	.col-sm-push-7 {
		left: 58.33333333%;
	}
	.col-sm-push-6 {
		left: 50%;
	}
	.col-sm-push-5 {
		left: 41.66666667%;
	}
	.col-sm-push-4 {
		left: 33.33333333%;
	}
	.col-sm-push-3 {
		left: 25%;
	}
	.col-sm-push-2 {
		left: 16.66666667%;
	}
	.col-sm-push-1 {
		left: 8.33333333%;
	}
	.col-sm-push-0 {
		left: auto;
	}
	.col-sm-offset-12 {
		margin-left: 100%;
	}
	.col-sm-offset-11 {
		margin-left: 91.66666667%;
	}
	.col-sm-offset-10 {
		margin-left: 83.33333333%;
	}
	.col-sm-offset-9 {
		margin-left: 75%;
	}
	.col-sm-offset-8 {
		margin-left: 66.66666667%;
	}
	.col-sm-offset-7 {
		margin-left: 58.33333333%;
	}
	.col-sm-offset-6 {
		margin-left: 50%;
	}
	.col-sm-offset-5 {
		margin-left: 41.66666667%;
	}
	.col-sm-offset-4 {
		margin-left: 33.33333333%;
	}
	.col-sm-offset-3 {
		margin-left: 25%;
	}
	.col-sm-offset-2 {
		margin-left: 16.66666667%;
	}
	.col-sm-offset-1 {
		margin-left: 8.33333333%;
	}
	.col-sm-offset-0 {
		margin-left: 0;
	}
}
@media (min-width: 992px) {
	.col-md-1,
	.col-md-2,
	.col-md-3,
	.col-md-4,
	.col-md-5,
	.col-md-6,
	.col-md-7,
	.col-md-8,
	.col-md-9,
	.col-md-10,
	.col-md-11,
	.col-md-12 {
		float: left;
	}
	.col-md-12 {
		width: 100%;
	}
	.col-md-11 {
		width: 91.66666667%;
	}
	.col-md-10 {
		width: 83.33333333%;
	}
	.col-md-9 {
		width: 75%;
	}
	.col-md-8 {
		width: 66.66666667%;
	}
	.col-md-7 {
		width: 58.33333333%;
	}
	.col-md-6 {
		width: 50%;
	}
	.col-md-5 {
		width: 41.66666667%;
	}
	.col-md-4 {
		width: 33.33333333%;
	}
	.col-md-3 {
		width: 25%;
	}
	.col-md-2 {
		width: 16.66666667%;
	}
	.col-md-1 {
		width: 8.33333333%;
	}
	.col-md-pull-12 {
		right: 100%;
	}
	.col-md-pull-11 {
		right: 91.66666667%;
	}
	.col-md-pull-10 {
		right: 83.33333333%;
	}
	.col-md-pull-9 {
		right: 75%;
	}
	.col-md-pull-8 {
		right: 66.66666667%;
	}
	.col-md-pull-7 {
		right: 58.33333333%;
	}
	.col-md-pull-6 {
		right: 50%;
	}
	.col-md-pull-5 {
		right: 41.66666667%;
	}
	.col-md-pull-4 {
		right: 33.33333333%;
	}
	.col-md-pull-3 {
		right: 25%;
	}
	.col-md-pull-2 {
		right: 16.66666667%;
	}
	.col-md-pull-1 {
		right: 8.33333333%;
	}
	.col-md-pull-0 {
		right: auto;
	}
	.col-md-push-12 {
		left: 100%;
	}
	.col-md-push-11 {
		left: 91.66666667%;
	}
	.col-md-push-10 {
		left: 83.33333333%;
	}
	.col-md-push-9 {
		left: 75%;
	}
	.col-md-push-8 {
		left: 66.66666667%;
	}
	.col-md-push-7 {
		left: 58.33333333%;
	}
	.col-md-push-6 {
		left: 50%;
	}
	.col-md-push-5 {
		left: 41.66666667%;
	}
	.col-md-push-4 {
		left: 33.33333333%;
	}
	.col-md-push-3 {
		left: 25%;
	}
	.col-md-push-2 {
		left: 16.66666667%;
	}
	.col-md-push-1 {
		left: 8.33333333%;
	}
	.col-md-push-0 {
		left: auto;
	}
	.col-md-offset-12 {
		margin-left: 100%;
	}
	.col-md-offset-11 {
		margin-left: 91.66666667%;
	}
	.col-md-offset-10 {
		margin-left: 83.33333333%;
	}
	.col-md-offset-9 {
		margin-left: 75%;
	}
	.col-md-offset-8 {
		margin-left: 66.66666667%;
	}
	.col-md-offset-7 {
		margin-left: 58.33333333%;
	}
	.col-md-offset-6 {
		margin-left: 50%;
	}
	.col-md-offset-5 {
		margin-left: 41.66666667%;
	}
	.col-md-offset-4 {
		margin-left: 33.33333333%;
	}
	.col-md-offset-3 {
		margin-left: 25%;
	}
	.col-md-offset-2 {
		margin-left: 16.66666667%;
	}
	.col-md-offset-1 {
		margin-left: 8.33333333%;
	}
	.col-md-offset-0 {
		margin-left: 0;
	}
}
@media (min-width: 1200px) {
	.col-lg-1,
	.col-lg-2,
	.col-lg-3,
	.col-lg-4,
	.col-lg-5,
	.col-lg-6,
	.col-lg-7,
	.col-lg-8,
	.col-lg-9,
	.col-lg-10,
	.col-lg-11,
	.col-lg-12 {
		float: left;
	}
	.col-lg-12 {
		width: 100%;
	}
	.col-lg-11 {
		width: 91.66666667%;
	}
	.col-lg-10 {
		width: 83.33333333%;
	}
	.col-lg-9 {
		width: 75%;
	}
	.col-lg-8 {
		width: 66.66666667%;
	}
	.col-lg-7 {
		width: 58.33333333%;
	}
	.col-lg-6 {
		width: 50%;
	}
	.col-lg-5 {
		width: 41.66666667%;
	}
	.col-lg-4 {
		width: 33.33333333%;
	}
	.col-lg-3 {
		width: 25%;
	}
	.col-lg-2 {
		width: 16.66666667%;
	}
	.col-lg-1 {
		width: 8.33333333%;
	}
	.col-lg-pull-12 {
		right: 100%;
	}
	.col-lg-pull-11 {
		right: 91.66666667%;
	}
	.col-lg-pull-10 {
		right: 83.33333333%;
	}
	.col-lg-pull-9 {
		right: 75%;
	}
	.col-lg-pull-8 {
		right: 66.66666667%;
	}
	.col-lg-pull-7 {
		right: 58.33333333%;
	}
	.col-lg-pull-6 {
		right: 50%;
	}
	.col-lg-pull-5 {
		right: 41.66666667%;
	}
	.col-lg-pull-4 {
		right: 33.33333333%;
	}
	.col-lg-pull-3 {
		right: 25%;
	}
	.col-lg-pull-2 {
		right: 16.66666667%;
	}
	.col-lg-pull-1 {
		right: 8.33333333%;
	}
	.col-lg-pull-0 {
		right: auto;
	}
	.col-lg-push-12 {
		left: 100%;
	}
	.col-lg-push-11 {
		left: 91.66666667%;
	}
	.col-lg-push-10 {
		left: 83.33333333%;
	}
	.col-lg-push-9 {
		left: 75%;
	}
	.col-lg-push-8 {
		left: 66.66666667%;
	}
	.col-lg-push-7 {
		left: 58.33333333%;
	}
	.col-lg-push-6 {
		left: 50%;
	}
	.col-lg-push-5 {
		left: 41.66666667%;
	}
	.col-lg-push-4 {
		left: 33.33333333%;
	}
	.col-lg-push-3 {
		left: 25%;
	}
	.col-lg-push-2 {
		left: 16.66666667%;
	}
	.col-lg-push-1 {
		left: 8.33333333%;
	}
	.col-lg-push-0 {
		left: auto;
	}
	.col-lg-offset-12 {
		margin-left: 100%;
	}
	.col-lg-offset-11 {
		margin-left: 91.66666667%;
	}
	.col-lg-offset-10 {
		margin-left: 83.33333333%;
	}
	.col-lg-offset-9 {
		margin-left: 75%;
	}
	.col-lg-offset-8 {
		margin-left: 66.66666667%;
	}
	.col-lg-offset-7 {
		margin-left: 58.33333333%;
	}
	.col-lg-offset-6 {
		margin-left: 50%;
	}
	.col-lg-offset-5 {
		margin-left: 41.66666667%;
	}
	.col-lg-offset-4 {
		margin-left: 33.33333333%;
	}
	.col-lg-offset-3 {
		margin-left: 25%;
	}
	.col-lg-offset-2 {
		margin-left: 16.66666667%;
	}
	.col-lg-offset-1 {
		margin-left: 8.33333333%;
	}
	.col-lg-offset-0 {
		margin-left: 0;
	}
}

/** mini bootstrap**/

/** Mini bootstrap ext **/
.col-xs-5c,
.col-sm-5c,
.col-md-5c,
.col-lg-5c,
.col-xs-2c,
.col-sm-2c,
.col-md-2c,
.col-lg-2c {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.col-xlg-1,
.col-xlg-2,
.col-xlg-3,
.col-xlg-4,
.col-xlg-5,
.col-xlg-6,
.col-xlg-7,
.col-xlg-8,
.col-xlg-9,
.col-xlg-10,
.col-xlg-11,
.col-xlg-12 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.col-xs-5c {
	width: 20%;
	float: left;
}

.col-xs-2c {
	width: 40%;
	float: left;
}

@media (min-width: 768px) {
	.col-sm-5c {
		width: 20%;
		float: left;
	}

	.col-sm-2c {
		width: 40%;
		float: left;
	}
}

@media (min-width: 992px) {
	.col-md-5c {
		width: 20%;
		float: left;
	}

	.col-md-2c {
		width: 40%;
		float: left;
	}
}

@media (min-width: 1200px) {
	.col-lg-5c {
		width: 20%;
		float: left;
	}

	.col-lg-2c {
		width: 40%;
		float: left;
	}
}

@media (min-width: 1800px) {
	.col-xlg-1,
	.col-xlg-2,
	.col-xlg-3,
	.col-xlg-4,
	.col-xlg-5,
	.col-xlg-6,
	.col-xlg-7,
	.col-xlg-8,
	.col-xlg-9,
	.col-xlg-10,
	.col-xlg-11,
	.col-xlg-12 {
		float: left;
	}
	.col-xlg-12 {
		width: 100%;
	}
	.col-xlg-11 {
		width: 91.66666667%;
	}
	.col-xlg-10 {
		width: 83.33333333%;
	}
	.col-xlg-9 {
		width: 75%;
	}
	.col-xlg-8 {
		width: 66.66666667%;
	}
	.col-xlg-7 {
		width: 58.33333333%;
	}
	.col-xlg-6 {
		width: 50%;
	}
	.col-xlg-5 {
		width: 41.66666667%;
	}
	.col-xlg-4 {
		width: 33.33333333%;
	}
	.col-xlg-3 {
		width: 25%;
	}
	.col-xlg-2 {
		width: 16.66666667%;
	}
	.col-xlg-1 {
		width: 8.33333333%;
	}
	.col-xlg-pull-12 {
		right: 100%;
	}
	.col-xlg-pull-11 {
		right: 91.66666667%;
	}
	.col-xlg-pull-10 {
		right: 83.33333333%;
	}
	.col-xlg-pull-9 {
		right: 75%;
	}
	.col-xlg-pull-8 {
		right: 66.66666667%;
	}
	.col-xlg-pull-7 {
		right: 58.33333333%;
	}
	.col-xlg-pull-6 {
		right: 50%;
	}
	.col-xlg-pull-5 {
		right: 41.66666667%;
	}
	.col-xlg-pull-4 {
		right: 33.33333333%;
	}
	.col-xlg-pull-3 {
		right: 25%;
	}
	.col-xlg-pull-2 {
		right: 16.66666667%;
	}
	.col-xlg-pull-1 {
		right: 8.33333333%;
	}
	.col-xlg-pull-0 {
		right: auto;
	}
	.col-xlg-push-12 {
		left: 100%;
	}
	.col-xlg-push-11 {
		left: 91.66666667%;
	}
	.col-xlg-push-10 {
		left: 83.33333333%;
	}
	.col-xlg-push-9 {
		left: 75%;
	}
	.col-xlg-push-8 {
		left: 66.66666667%;
	}
	.col-xlg-push-7 {
		left: 58.33333333%;
	}
	.col-xlg-push-6 {
		left: 50%;
	}
	.col-xlg-push-5 {
		left: 41.66666667%;
	}
	.col-xlg-push-4 {
		left: 33.33333333%;
	}
	.col-xlg-push-3 {
		left: 25%;
	}
	.col-xlg-push-2 {
		left: 16.66666667%;
	}
	.col-xlg-push-1 {
		left: 8.33333333%;
	}
	.col-xlg-push-0 {
		left: auto;
	}
	.col-xlg-offset-12 {
		margin-left: 100%;
	}
	.col-xlg-offset-11 {
		margin-left: 91.66666667%;
	}
	.col-xlg-offset-10 {
		margin-left: 83.33333333%;
	}
	.col-xlg-offset-9 {
		margin-left: 75%;
	}
	.col-xlg-offset-8 {
		margin-left: 66.66666667%;
	}
	.col-xlg-offset-7 {
		margin-left: 58.33333333%;
	}
	.col-xlg-offset-6 {
		margin-left: 50%;
	}
	.col-xlg-offset-5 {
		margin-left: 41.66666667%;
	}
	.col-xlg-offset-4 {
		margin-left: 33.33333333%;
	}
	.col-xlg-offset-3 {
		margin-left: 25%;
	}
	.col-xlg-offset-2 {
		margin-left: 16.66666667%;
	}
	.col-xlg-offset-1 {
		margin-left: 8.33333333%;
	}
	.col-xlg-offset-0 {
		margin-left: 0;
	}
}
/** mini bootstrap ext **/

.mainpage-bg {
	background: url('../../../../assets/img/wx/home-bg.png') no-repeat scroll
		center -53px / cover transparent;
	box-shadow: 8px 10px 67px 39px black inset;
	box-shadow: 8px -200px 500px 238px black inset;
}

@media (max-width: 1200px) {
	.mainpage-bg {
		box-shadow: 8px -200px 300px 238px black inset;
	}
}
@media (max-width: 1000px) {
	.mainpage-bg {
		box-shadow: 8px -200px 250px 138px black inset;
	}
}

.mainpage-title {
	display: inline-block;
	margin-top: 52px;
}

.mainpage-description {
	max-width: 588px;
	margin-top: 7px;
}

/* .mainpage-description + p {
	margin-top: -7px;
} */
/* 
.mainpage-description span,
.mainpage-description p {
	line-height: 1.8em;
} */
.searchcriteriacont-sep {
	display: none;
}
.btn {
	color: white;
	display: inline-block;
	font-size: 14px;
	height: 40px;
	padding-left: 28px;
	padding-top: 12px;
	padding-right: 13px;
	margin-top: 17px;
	background: url('../../../../assets/img/wx/btn-arr.png') no-repeat scroll
		12px 16px #bc1000;
	cursor: pointer;
}

.btn:hover {
	background-color: #d12c10;
}

.btn-yellow {
	background-color: #ff9200;
}

.btn-yellow:hover {
	background-color: #ffa623;
}

.btn-orange {
	background-color: #d55a00;
}

.btn-orange:hover {
	background-color: #e46713;
}

.btn-gray {
	background-color: #555555;
}

.btn-gray:hover {
	background-color: #6d6d6d;
}

.btn-src {
	background-image: url('../../../../assets/img/wx/lupa-small.png');
	background-position: 10px 13px;
}

.blue-btn {
	background-color: #25455a;
}

.blue-btn:hover {
	background-color: #4c7892;
	text-decoration: none;
}

.lightblue-btn {
	background-color: #426881;
}

.lightblue-btn:hover {
	background-color: #5d819a;
	text-decoration: none;
}

.btn-lblue {
	background-color: #0e82cf;
}

.btn-lblue:hover {
	background-color: #3f9ced;
}

.btn-noarr {
	background-image: none;
	padding-left: 15px;
	padding-right: 15px;
}

.no-arr {
	background-image: none;
	padding-left: 13px;
}

.btn-small {
	background: url('../../../../assets/img/wx/btn-arr.png') no-repeat scroll
		8px 12px #bc1000;
	color: white;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	height: 32px;
	margin-top: 17px;
	padding-left: 23px;
	padding-right: 8px;
	padding-top: 7px;
}

.btn-small-no-margin {
	background: no-repeat scroll 8px 12px #152631;
	color: white;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	height: 32px;
	margin: 0;
	padding-left: 7px;
	padding-right: 7px;
	padding-top: 7px;
}

.btn-share {
	background: url('../../../../assets/img/wx/btn-share.png') no-repeat scroll
		8px 7px #d55a00;
	padding-left: 34px;
	margin-top: 0px;
}

.btn-nopadtop {
	border: 0;
	padding-top: 0;
}

.about-car-item .btn {
	bottom: 25px;
	/*position: absolute;*/
}

.no-arr-small {
	background: none no-repeat scroll 8px 12px #bc1000;
	padding-left: 8px;
}

.mainpage-video-watch {
	background: url('../../../../assets/img/wx/mainpage-play.png') no-repeat
		scroll 10% center black;
	display: inline-block;
	/*float: right;*/
	font-size: 1.3em;

	/*padding: 19px 12px 20px 28%;*/
	padding: 48px 12px 48px 28%;
	cursor: pointer;
	margin-top: 50px;

	/*width:100%;*/
	width: 120%;

	margin-left: -20px;
	border-top: 1px solid #6e6c6b;
	border-bottom: 1px solid #6e6c6b;
}

.mainpage-video-cont {
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.5);
	display: none;
	float: right;
	font-size: 1.3em;
	height: 100%;
	left: 0;
	margin: 0;
	padding: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 5;
}

.mainpage-video {
	display: inline-block;
	max-width: 600px;
	height: auto;
	/*left: 50%;*/
	margin-bottom: auto;
	margin-right: auto;
	margin-top: auto;
	position: absolute;
	top: 50%;
	background: black;
	width: 100%;
	padding: 20px;
}

/* .mainpage-video-embed iframe {
	width: 100%;
} */

.mainpage-video-desc {
	display: inline-block;
	font-size: 0.8em;
	position: relative;
	width: 80%;
}

.mainpage-video-close {
	display: inline-block;
	font-family: Verdana;
	font-size: 3em;
	position: absolute;
	right: -22%;
	text-align: center;
	top: 42%;
	vertical-align: top;
	width: 18%;
	cursor: pointer;
	background: url('../../../../assets/img/wx/mainpage-vid-close.png')
		no-repeat scroll center center rgba(0, 0, 0, 0);
	color: transparent;
}

.mainpage-searchbtn {
	background: url('../../../../assets/img/wx/btn-src.png') no-repeat scroll
		7px 12px #555555;
	border: 0 none;
	color: white;
	display: inline-block;
	font-size: 14px;
	height: 40px;
	padding-left: 26px;
	width: 90px;
	cursor: pointer;
}

.mainpage-searchtxt {
	font-size: 15px;
	padding-left: 8px;
	height: 40px;
	margin-right: 5px;
	/*width: 212px;*/
	width: 100%;
}

.mainpage-mobile-col-1 {
	display: table-cell;
	padding-right: 11px;
	width: 100%;
}

.mainpage-mobile-col-2 {
	display: table-cell;
}

.mainpage-search {
	/*margin-top: 38px;*/
	margin-top: 44px;
	/*float: left;*/

	border-bottom: 1px solid #6e6c6b;
	padding-bottom: 42px;
	margin-bottom: 41px;
}

.mainpage-latest-arrows {
	display: none;
}

@media (min-width: 609px) {
	.mainpage-video-watch {
		margin-top: 0px;
		margin-right: 5%;
		margin-left: 0px;
		width: auto;
		background: url('../../../../assets/img/wx/mainpage-play.png') no-repeat
			scroll 5% center black;
		padding: 19px 12px 20px 65px;
		float: right;

		border-top: 0;
		border-bottom: 0;
	}

	.mainpage-video {
		left: 50%;
		width: auto;
	}
}

/** PARTNERS **/
.partners-bg {
	background: url('../../../../assets/img/wx/partners-bg.png') no-repeat
		scroll center bottom / cover black;
	padding-bottom: 70px;
}

.partners-header {
	margin-bottom: 25px;
	padding-left: 0;
	padding-right: 0;
}

.partners-int,
.partners-local {
	/*display: inline-block;*/
	margin-right: 1%;
	padding-bottom: 40px;
	padding-left: 0;
	padding-right: 0;
	vertical-align: top;
	/*width: calc(73% / 2);*/
}

.partners-part {
	/*display: inline-block;*/
	/*width: 25%;*/
	vertical-align: top;
	padding-bottom: 40px;
	padding-left: 0;
	padding-right: 0;
}

.partners-int {
	background-color: #7c1e16;
}

.partners-int-title {
	background-color: #6a1a13;
	font-size: 3.5em;
	padding-bottom: 10px;
	padding-left: 15px;
	padding-top: 10px;
}

.partners-int-subt {
	font-size: 1.3em;
	margin-left: 10px;
	margin-top: 15px;
}

.partners-int-list li {
	list-style-image: url('../../../../assets/img/wx/btn-arr.png');
	/* font-weight: bold; */
	margin-bottom: 10px;
	cursor: pointer;
}

.partners-int-col1,
.partners-int-col2,
.partners-local-col1,
.partners-local-col2 {
	display: inline-block;
	width: 100%;
	vertical-align: top;
	padding-left: 10px;
	padding-right: 20px;
}

.partners-int-list-desc {
	/* display: none; */
	cursor: auto;
	font-weight: normal;
	margin-top: 10px;
	max-height: 0;
	overflow: hidden;
	margin-right: 100%;
	transition: max-height 2s ease-out, margin-right 0.3s ease-in-out;
}

.partners-int-list-desc.show {
	max-height: 500px;
	margin-right: 0;
	transition: max-height 2s ease-out, margin-right 0.3s ease-in-out;
	overflow: hidden;
}

.partners-int-list .partners-int-list-desc {
	font-size: 13px;
}
.partners-int-list .partners-int-list-desc a {
	font-size: 13px;
	line-height: 13px;
}

.partners-local {
	background-color: #d65a00;
}

.partners-local-title {
	background-color: #b64d00;
	font-size: 3.5em;
	padding-bottom: 10px;
	padding-left: 15px;
	padding-top: 10px;
}

.partners-a {
	display: inline-block;
	font-weight: bold;
	margin-top: 13px;
}

.partners-local-desc {
	padding-left: 10px;
	padding-right: 10px;
}

.partners-part {
	background-color: #25455a;
}

.partners-part-title {
	background-color: #1f3b4d;
	font-size: 3.5em;
	padding-bottom: 10px;
	padding-left: 15px;
	padding-top: 10px;
}

.partners-part-list-desc {
	display: none;
	cursor: auto;
	font-weight: normal;
}

.partners-part-list li {
	list-style-image: url('../../../../assets/img/wx/btn-arr.png');
	font-weight: bold;
	margin-bottom: 10px;
	cursor: pointer;
}

.partners-part-subt {
	font-size: 1.5em;
	margin-left: 20px;
	margin-top: 15px;
}

.partners-part-list {
	margin-left: 16px;
}

.partners-title {
	/*display: inline-block;*/
	font-size: 4.4em;
	font-weight: bold;
	padding: 0;
	/*width:30%;*/
}

.partners-list-sub {
	color: #ff9200;
	margin-bottom: 10px;
}

.partners-desc {
	/*display: inline-block;*/
	font-size: 1em;
	/*width: 70%;*/
	/*padding: 95px 0 0;*/
}

@media (min-width: 630px) {
	.partners-int-col1,
	.partners-int-col2,
	.partners-local-col1,
	.partners-local-col2 {
		width: 49%;
	}
}

@media (min-width: 992px) {
	.partners-int,
	.partners-local {
		width: 36.5%;
	}

	.partners-desc {
		padding: 15px 0 80px;
	}
}

@media (min-width: 1739px) {
	.partners-desc {
		padding: 15px 0 0px;
	}
}
/** PARTNERS END **/

/** MAINPAGE CAROUSEL**/
.carousel-arrow {
	border-left: 40px solid transparent;
	border-right: 40px solid transparent;
	border-top: 25px solid rgba(0, 0, 0, 0.8);
	height: 0;
	left: calc(50% - 40px);
	position: absolute;
	top: 16px;
	width: 0;
	z-index: 2;
}

.mainpage-car-item {
	display: inline-block;
	/*position: relative;*/
	width: 100%;
	vertical-align: top;
	/*margin-bottom: -485px;*/
}

.mainpage-car-text .mainpage-car-abstract {
	margin-bottom: 57px;
}

.mainpage-car-text .btn {
	bottom: 15px;
	position: absolute;
}

.mainpage-odd {
	background-color: #25455a;
}

.mainpage-even {
	background-color: #223b4b;
}

.owl-carousel .owl-item:nth-child(even) .mainpage-car-item {
	background-color: #25455a;
}

.owl-carousel .owl-item:nth-child(odd) .mainpage-car-item {
	background-color: #223b4b;
}

.mainpage-car-cats {
	font-size: 16px;
	display: none;
}

.mainpage-car-title {
	font-size: 30px;
	line-height: 30px;
	margin-bottom: 24px;
	/*min-height: 60px;*/
}

.owl-carousel .owl-item {
	float: none;
	display: table-cell;
}

.owl-carousel .owl-item:nth-child(even) {
	background-color: #25455a;
}

.owl-carousel .owl-item:nth-child(odd) {
	background-color: #223b4b;
}

.owl-carousel .owl-stage:after {
	display: table;
}

.mainpage-car-thumb {
	display: inline-block;
	position: relative;
	top: 0;
	width: 100%;
	line-height: 0;
}

/* .mainpage-car-thumb > img {
	margin: 0;
	padding: 0;
	width: 100%;
}
 */
.mainpage-car-text {
	padding: 15px 15px 15px;
}

.mainpage-carousel .owl-carousel .owl-wrapper {
	margin-bottom: -485px;
	overflow: hidden;
}
/** MAINPAGE CAROUSEL END **/

/** ABOUT CAROUSEL**/
.about-bg {
	padding-top: 60px;
	padding-bottom: 80px;
}
.about-title {
	font-size: 60px;
	line-height: 55px;
	margin-bottom: 60px;
	font-weight: bold;
	word-wrap: break-word;
}

.about-sep {
	padding-top: 50px;
}

.about-desc {
	line-height: 1.8em;
	padding-right: 20%;
	font-size: 1.1em;
}

.about-link.about-what {
	background: url('../../../../assets/img/wx/What_we_do.png') no-repeat scroll
		0 0 black;
	background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
		url('../../../../assets/img/wx/What_we_do.png');
	padding-top: 229px;
	margin-top: 50px;
}

.about-link.about-what:hover {
	background: url('../../../../assets/img/wx/What_we_do.png') no-repeat scroll
		0 0 black;
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		url('../../../../assets/img/wx/What_we_do.png');
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
		url('../../../../assets/img/wx/What_we_do.png');
	background-position: 31px 40%, 0 0;
	background-position: 31px 40%, 0 100%, 0 0;
}

.about-link.about-job {
	background: url('../../../../assets/img/wx/Job Oportunities.png') no-repeat
		scroll 0 0 black;
	background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
		url('../../../../assets/img/wx/Job Oportunities.png');
	padding-top: 229px;
	margin-top: 50px;
}

.about-link.about-job:hover {
	background: url('../../../../assets/img/wx/Job Oportunities.png') no-repeat
		scroll 0 0 black;
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		url('../../../../assets/img/wx/Job Oportunities.png');
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
		url('../../../../assets/img/wx/Job Oportunities.png');
	background-position: 31px 40%, 0 0;
	background-position: 31px 40%, 0 100%, 0 0;
}

.about-link.about-press {
	background: url('../../../../assets/img/wx/Press Room.png') no-repeat scroll
		0 0 black;
	background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
		url('../../../../assets/img/wx/Press Room.png');
	padding-top: 229px;
	margin-top: 50px;
	cursor: default;
}

@media (min-width: 768px) {
	.about-link.about-what {
		margin-top: 0px;
	}

	.about-bg {
		background: url('../../../../assets/img/wx/about-bg.png') no-repeat
			scroll center 0px / cover transparent;
	}
}

@media (min-width: 992px) {
	.about-link.about-press {
		margin-top: 0px;
	}
}

.about-link.about-press:hover {
	background: url('../../../../assets/img/wx/Press Room.png') no-repeat scroll
		0 0 black;
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		url('../../../../assets/img/wx/Press Room.png');
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
		url('../../../../assets/img/wx/Press Room.png');
	background-position: 31px 40%, 0 0;
	background-position: 31px 40%, 0 100%, 0 0;
}

.about-link.about-team {
	background: url('../../../../assets/img/wx/Our team.png') no-repeat scroll 0
		0 black;
	background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
		url('../../../../assets/img/wx/Our team.png');
	padding-top: 229px;
	margin-top: 50px;
}

.about-link.about-team:hover {
	background: url('../../../../assets/img/wx/Our team.png') no-repeat scroll 0
		0 black;
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		url('../../../../assets/img/wx/Our team.png');
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
		url('../../../../assets/img/wx/Our team.png');
	background-position: 31px 40%, 0 0;
	background-position: 31px 40%, 0 100%, 0 0;
}

@media (min-width: 768px) {
	.about-link.about-what {
		background-image: linear-gradient(black, black),
			url('../../../../assets/img/wx/What_we_do.png');
	}

	.about-link.about-what:hover {
		background-image: url('../../../../assets/img/wx/about-arr.png'),
			linear-gradient(black, black),
			url('../../../../assets/img/wx/What_we_do.png');
	}

	.about-link.about-job {
		background-image: linear-gradient(black, black),
			url('../../../../assets/img/wx/Job Oportunities.png');
	}

	.about-link.about-job:hover {
		background-image: url('../../../../assets/img/wx/about-arr.png'),
			linear-gradient(black, black),
			url('../../../../assets/img/wx/Job Oportunities.png');
	}

	.about-link.about-press {
		background-image: linear-gradient(black, black),
			url('../../../../assets/img/wx/Press Room.png');
	}

	.about-link.about-press:hover {
		background-image: url('../../../../assets/img/wx/about-arr.png'),
			linear-gradient(black, black),
			url('../../../../assets/img/wx/Press Room.png');
	}

	.about-link.about-team {
		background-image: linear-gradient(black, black),
			url('../../../../assets/img/wx/Our team.png');
	}

	.about-link.about-team:hover {
		background-image: url('../../../../assets/img/wx/about-arr.png'),
			linear-gradient(black, black),
			url('../../../../assets/img/wx/Our team.png');
	}
}

.about-col1,
.about-col2,
.about-col3 {
	display: inline-block;
	position: relative;
	width: 32%;
	vertical-align: top;
}

.about-title2 {
	font-size: 45px;
	line-height: 45px;
	margin-bottom: 15px;
	display: inline-block;
}

.about-title3 {
	display: inline-block;
	font-size: 24px;
	font-weight: bold;
	margin-left: 10px;
}

.about-link:hover {
	text-decoration: none;
	color: #ffa200;
	background-size: auto, 100% 90px, cover;
}

.about-link {
	/*background: none no-repeat scroll 0 0 black;*/
	color: white;
	font-size: 30px;
	line-height: 30px;
	/*max-width: 419px;*/
	padding-bottom: 15px;
	padding-left: 15px;
	width: 100%;
	display: inline-block;
	cursor: pointer;

	background-repeat: no-repeat;
	background-position: 0 100%, 0 0;
	/*background-size: 100% 62px, cover;*/
	background-size: 100% 90px, cover;
	background-origin: padding-box;
	background-attachment: scroll;
}

.about-car-cont {
	/*** To make inner divs seem to have the same height ***/
	overflow: hidden;
	position: relative;
	/* margin-left: -20px;
	margin-right: -5px; */
}

.about-car-item {
	/*display: table-cell;*/
	min-height: 247px;
	position: relative;
	vertical-align: top;
	/*** To make divs seem to have the same height ***/
	/*margin-bottom:-500em;
	padding-bottom:500em;*/
	/*float:none;
	display:table-cell;*/
	/*width: calc(100% / 3);*/
	padding-left: 0;
	padding-right: 0;
}

.about-car-title img {
	max-width: 90%;
}

.about-odd {
	background-color: #d55a00;
}

.about-even {
	background-color: #ff9200;
}

.about-car-cats {
	font-size: 16px;
}

.about-car-title {
	font-size: 25px;
	line-height: 30px;
	margin-bottom: 24px;

	display: table-cell;
	height: 110px;
	vertical-align: middle;
	word-wrap: break-word;
}

.about-car-more2 {
	/*  background: url("btn-arr.png") no-repeat scroll 12px 14px #d55a00; */
	cursor: pointer;
	color: white;
	display: inline-block;
	font-size: 14px;
	height: 40px;
	margin-top: 17px;
	padding-left: 28px;
	padding-top: 12px;
	width: auto;
	padding-right: 15px;
}

.about-car-more {
	background: url('../../../../assets/img/wx/btn-arr.png') no-repeat scroll
		12px 14px #bc1000;
	cursor: pointer;
	color: white;
	display: inline-block;
	font-size: 14px;
	height: 40px;
	margin-top: 17px;
	padding-left: 28px;
	padding-top: 12px;
	width: auto;
	padding-right: 15px;
}

.about-car-thumb {
	display: inline-block;
	position: relative;
	top: 0;
	width: 100%;
	line-height: 0;
}

.about-car-thumb > img {
	margin: 0;
	padding: 0;
	width: 100%;
}

.about-car-text {
	padding: 40px;
}

.about-map-thumb {
	display: inline-block;
	padding-bottom: 4px;
	padding-left: 15px;
	padding-top: 30px;
	vertical-align: top;
	width: 100%;

	/* float: left; */
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.about-map-text {
	display: inline-block;
	padding: 30px 15px 40px 15px;
	width: 100%;

	/* float: left; */
	/*position: relative;*/
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.about-map-text .about-car-title {
	display: block;
	height: auto;
}

.about-map-thumb img {
	width: 100% !important;
	height: auto !important;
}

.map-frame {
	height: 200px;
}

.column-centered,
.column-centered-big {
	background: none repeat scroll 0 0 #282828;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	padding: 5px 23px 10px;
}

@media (min-width: 371px) {
	.about-link {
		background-size: 100% 62px, cover;
	}
	.about-link:hover {
		background-size: auto, 100% 62px, cover;
	}
}

@media (min-width: 992px) {
	.about-link {
		background-size: 100% 90px, cover;
	}
	.about-link:hover {
		background-size: auto, 100% 90px, cover;
	}
}

@media (min-width: 1017px) {
	.about-link {
		background-size: 100% 62px, cover;
	}
	.about-link:hover {
		background-size: auto, 100% 62px, cover;
	}
}

@media (min-width: 530px) {
	.about-map-thumb {
		padding-left: 30px;
		width: 41.66666667%;
	}

	.about-map-text {
		padding: 30px 15px 80px 0;
		width: 58.33333333%;
	}
}

@media (min-width: 768px) {
	.about-map-thumb {
		width: 42.54%;
	}

	.about-map-text {
		width: 57.46%;
	}
}

@media (min-device-height: 480px) {
	.map-frame {
		height: 380px;
	}
}

@media (min-device-height: 640px) {
	.map-frame {
		height: 540px;
	}
}

@media (min-device-height: 1024px) {
	.map-frame {
		height: 900px;
	}
}
/** ABOUT CAROUSEL END **/

/** WHAT WE DO **/
.whatwe-bg {
	background-image: url('../../../../assets/img/wx/WhatWeDoBg.png');
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: cover;
	margin-left: -20px;
	margin-right: -10px;
	padding-left: 20px;
	padding-right: 10px;
	padding-top: 1px;
}

.whatwe-description > p {
	margin: 0;
	line-height: inherit;
}

.about-back {
	display: inline-block;
}

.about-back a {
	color: #ff4837;
	display: none;
	font-size: 17.14px;
	font-weight: normal;
	line-height: 17px;
	margin-left: 22px;
	padding-left: 19px;
	position: relative;
	top: -3px;

	background-image: url('../../../../assets/img/wx/arr-aboutback.png');
	background-position: 1px center;
	background-repeat: no-repeat;
}

.first-column,
.second-column {
	padding-left: 0;
	padding-right: 35px;
}

.third-column {
	padding-bottom: 25px;
}

.whatwe-item {
	background-color: black;
	margin-bottom: 45px;
	padding-bottom: 20px;
}

/* a.whatwe-link:hover {
	text-decoration: none;
} */

.whatwe-link {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	display: inline-block;
	/*font-size: 28px;*/
	font-size: 22px;
	margin-bottom: 22px;
	padding-bottom: 5px;
	padding-left: 20px;
	padding-top: 172px;
	text-decoration: none;
	width: 100%;
}

.whatwe-link:hover {
	color: #ffa200;
	background-size: auto auto, cover;
	background-position: 15px center, center;
}

.whatwe-description {
	font-size: 14px;
	padding-left: 20px;
	padding-right: 20px;
	background-color: black;
}

.what-collections {
	background-image: url('../../../../assets/img/wx/whatwe-collections.png');
}

.what-collections:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		url('../../../../assets/img/wx/whatwe-collections.png');
}

.what-digitalarchive {
	background-image: url('../../../../assets/img/wx/whatwe-digitalarchive.png');
}

.what-digitalarchive:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		url('../../../../assets/img/wx/whatwe-digitalarchive.png');
}

.what-library {
	background-image: url('../../../../assets/img/wx/whatwe-library.png');
}

.what-library:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		url('../../../../assets/img/wx/whatwe-library.png');
}

.what-physicalarchive {
	background-image: url('../../../../assets/img/wx/whatwe-physicalarchive.png');
}

.what-physicalarchive:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		url('../../../../assets/img/wx/whatwe-physicalarchive.png');
}

.what-building {
	background-image: url('../../../../assets/img/wx/whatwe-capacitybuilding.png');
}

.what-building:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		url('../../../../assets/img/wx/whatwe-capacitybuilding.png');
}

.what-educationfilm {
	background-image: url('../../../../assets/img/wx/whatwe-educationfilmproduction.png');
}

.what-educationfilm:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		url('../../../../assets/img/wx/whatwe-educationfilmproduction.png');
}

.whatwe-subtitle {
	font-size: 45.63px;
}

.whatwe-subtitle2 {
	margin-top: 50px;
}

.whatwe-subdesc {
	margin-top: 15px;
}

.whatwe-ul {
	padding-left: 14px;
}

.whatwe-ul > li {
	list-style: outside none disc;
	padding-left: 30px;
}

@media (min-width: 390px) {
	.whatwe-link {
		font-size: 28px;
	}

	/* .what-educationfilm {
		/*font-size: 20px;* /
	} */
}

@media (min-width: 768px) {
	.what-educationfilm {
		/*font-size: 26px;*/
		padding-top: 139px;
	}

	.about-back a {
		display: inline-block;
	}
}

@media (min-width: 910px) {
	/* .what-educationfilm {
		/*padding-top: 173px; * /
	} */
}

@media (min-width: 992px) {
	.what-educationfilm {
		/*font-size: 18px;*/
		padding-top: 183px;
	}

	.whatwe-description {
		font-size: 12px;
	}
}

@media (min-width: 1200px) {
	.what-educationfilm {
		/*font-size: 21px;*/
		padding-top: 182px;
	}
	.column-centered {
		width: 50%;
	}

	.column-centered-big {
		width: 75%;
	}
}

@media (min-width: 1500px) {
	.what-educationfilm {
		/*font-size: 28px;*/
		padding-top: 171px;
	}
}
/** WHAT WE DO END **/

/** POST GENOCIDE **/
.post-description {
	margin-top: 35px;
	width: 100%;
}

.post-item-list {
	margin-top: 55px;
	padding-left: 0;
	padding-right: 0;
}

.post-item-row {
	padding: 0;
	overflow: hidden;
	margin-bottom: 25px;
}

.post-item {
	color: #353535;
	padding-left: 0;
	padding-right: 25px;
}

.post-item > div {
	background-color: white;
	padding-bottom: 500px;
	margin-bottom: -500px;
}

.post-item-image img {
	height: auto !important;
	width: 100% !important;
	border: 0;
}

.post-item-title {
	color: #4c4c4c;
	font-size: 20px;
	font-weight: bold;
	padding-left: 13px;
	padding-right: 13px;
	padding-top: 9px;
	word-wrap: break-word;
}

.post-item-description {
	font-size: 12px;
	margin-top: 15px;
	padding-bottom: 20px;
	padding-left: 13px;
	padding-right: 13px;
}

.post-mapping-footer {
	clear: both;
}

.post-mapping-cont {
	background-color: #333333;
	margin-top: 55px;
	padding-left: 5px;
	padding-right: 5px;
	padding-top: 15px;
}

.post-first-column,
.post-second-column,
.post-third-column {
	padding-bottom: 25px;
}

.post-second-column {
	background-color: #464646;
	padding-top: 25px;
}

.post-first-column img,
.post-second-column img,
.post-third-column img {
	width: 100%;
	height: auto;
}

.post-mapping-title {
	font-size: 36px;
	text-align: center;
	margin-bottom: 27px;
}

.post-mapping-img {
	padding: 0;
	width: 100%;
}

.post-mapping-subtitle {
	font-size: 25px;
	font-weight: bold;
	width: 100%;
}

.post-mapping-subdesc {
	font-size: 12px;
	margin-bottom: 14px;
	margin-top: 13px;
	width: 100%;
}

.post-mapping-footer {
	background-color: #005f9f;
	/* margin-left: -20px;
	margin-right: -20px; */
	padding: 35px 19px 25px;
}

.post-mapping-footer-title {
	font-size: 26px;
}

.post-mapping-footer-desc {
	font-size: 14px;
	/*margin-top: 11px;*/
	padding: 0;
}

.post-mapping-footer-desc > div {
	padding-bottom: 9px;
}

.post-footer-row {
	padding: 0;
	margin-bottom: 50px;
}

.post-footer-img-link {
	background-repeat: no-repeat;
	display: block;
	font-size: 14px;
	line-height: 45px;
	margin-bottom: 11px;
	padding-left: 10px;
	padding-top: 100px;
	/*background-size: 238px auto, auto auto;*/
	background-size: cover;
}

.post-footer-img-link:hover {
	text-decoration: none;
	color: #ffb400;
	background-position: 15px center, 0 0px, 0 0px, 0 0;
	/*background-size: 28px 37px, 179px 145px, 238px auto, auto auto;*/
	background-size: 28px 37px, 179px 145px, cover, cover;
}

.post-footer-img-link.post-link-a:hover {
	text-decoration: none;
	color: #ffb400;
	background-position: 15px center, 0 0px, 0 0px, 0 0;
	/*background-size: 28px 37px, 179px 145px, 238px auto, auto auto;*/
	background-size: 28px 37px, 179px 145px, cover, cover;
}

.post-footer-item-cont {
	margin-top: 50px;
	padding-left: 0;
	padding-right: 0;
}

.post-footer-item-desc {
	font-size: 14px;
}

.post-footer-item {
	padding-left: 0px;
}

.post-item-image {
	height: 62px;
	overflow: hidden;
	width: 100%;
}

@media (min-width: 400px) {
	.post-item-image {
		height: 90px;
	}
}

@media (min-width: 475px) {
	.post-mapping-img {
		width: 50%;
	}
	.post-mapping-subtitle {
		width: 50%;
	}
	.post-mapping-subdesc {
		width: 50%;
	}

	.post-footer-img-link {
		font-size: 21px;
	}

	.catfirstHeading {
		font-size: 3.5em;
	}
}

@media (min-width: 500px) {
	.post-item-image {
		height: 120px;
	}
}

@media (min-width: 600px) {
	.post-item-image {
		height: 150px;
	}
}

@media (min-width: 900px) {
	.post-item-image {
		height: 235px;
	}
}

@media (min-width: 992px) {
	.post-description {
		width: 43%;
	}
	.post-second-column {
		background: linear-gradient(rgba(51, 51, 51, 1), rgba(67, 67, 67, 1))
			repeat scroll 0 0 transparent;
		padding-top: 0px;
	}

	.post-mapping-subdesc {
		padding-left: 0;
		padding-right: 0;
	}

	.post-mapping-img {
		width: 100%;
	}
	.post-mapping-subtitle {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		min-height: 64px;
		margin-top: 26px;
	}
	.post-mapping-subdesc {
		width: 100%;
	}

	.post-footer-item-cont {
		margin-top: 0px;
		padding-left: 15px;
		padding-right: 15px;
	}

	.post-footer-item {
		padding-left: 15px;
	}

	.post-footer-img-link {
		font-size: 14px;
	}

	.post-genocide-bg {
		background-image: url('../../../../assets/img/wx/PostGenocideBG.png');
		background-size: cover;
		/* margin-left: -20px;
		margin-right: -10px; */
		padding-left: 20px;
		padding-right: 10px;
		padding-top: 1px;
	}

	.post-first-column,
	.post-second-column,
	.post-third-column {
		position: static;
		display: table-cell;
		float: none;
		vertical-align: top;
	}

	.post-mapping-subdesc {
		margin-bottom: 42px;
		position: static;
	}

	.post-mapping-subdesc .btn {
		bottom: 39px;
		position: absolute;
	}

	.post-item-image {
		height: 100px;
	}
}

@media (min-width: 1200px) {
	.post-item-image {
		height: 128px;
	}
}

@media (min-width: 1300px) {
	.post-footer-img-link {
		font-size: 21px;
	}
}

/* @media (min-width: 1500px) {
	.post-footer-img-link {
		/*font-size: 165px;* /
	}
}

@media (min-width: 1900px) {
	.post-footer-img-link {
		/*font-size: 211px;* /
	}
} */
/** POST GENOCIDE END **/

/** YOUNG PEOPLE AND PEACE BUILDING **/
.young-peace-bg {
	background-image: url('../../../../assets/img/wx/YoungPeaceBG.png');
	margin-left: -20px;
	/* margin-right: -10px; */
	padding-left: 20px;
	padding-right: 10px;
	padding-top: 1px;
}

.collections-title.young-title-firstline {
	margin-bottom: -3px;
}

.young-peace-items-list {
	background-color: rgba(255, 255, 255, 0.4);
	padding: 20px 17.5px;
	margin-top: 35px;
}

.young-peace-item-row {
	padding: 0;
}

.young-peace-item-cont {
	padding: 5px 7.5px;
}

.young-peace-item {
	padding: 20px;
	background-color: rgba(255, 255, 255, 0.8);
}

.young-peace-title {
	color: #202020;
	font-size: 20px;
	/* font-weight: bold; */
	margin-bottom: 11px;
}

.young-peace-description {
	color: #202020;
	font-size: 12px;
	line-height: 1.5em;
	/*height:80px;*/
}

@media (min-width: 350px) {
	.young-peace-description {
		height: 80px;
	}
}

.young-peace-lower {
	margin-top: 40px;
	margin-bottom: 20px;
	overflow: hidden;
	padding: 0;
}

.young-peace-lower-white {
	background-color: #e3e3e3;
	padding: 50px 35px;
}

.yp-lower-wlinks {
	padding: 0;
}

@media (min-width: 350px) {
	.yp-lower-wlinks {
		padding: 0 15px;
	}
}

.yp-lower-wlink {
	margin-bottom: 15px;
	width: 100%;
}

.yp-lower-winfo {
	padding: 0;
}

.yp-lower-wtitle {
	color: #171717;
	font-size: 28px;
}

.yp-lower-wdesc {
	color: #171717;
	font-size: 14px;
	margin-top: 10px;
	margin-bottom: 15px;
}

.yp-lower-wlink-row {
	padding: 0;
}

.yp-white-link {
	background-repeat: no-repeat;
	/*background-size: 238px auto, 239px auto, auto auto;*/
	background-size: cover;
	display: block;
	font-size: 28px;
	padding-bottom: 8px;
	padding-left: 15px;
	padding-top: 101px;
	color: #a5a7a7;
	float: left;
	width: 100%;
}

.yp-white-link:hover {
	text-decoration: none;
	/*background-position: 15px center, 0 0px, 0 0px, 0 0;*/
	/*background-size: 17px 20px, 238px auto, auto auto;*/
	background-size: 17px 20px, cover, cover;
	color: white;
	background-position: 10px 110px, 0 0, 0 0;
	padding-left: 35px;
}

.young-peace-light-blue {
	background-color: #003b62;
	padding-left: 35px;
	padding-right: 35px;
	padding-top: 50px;
	padding-bottom: 500px;
	margin-bottom: -465px;
}

.yp-blue-title {
	font-size: 35px;
	margin-bottom: 35px;
}

.young-peace-dark-blue {
	background-color: #002c49;
	padding-left: 35px;
	padding-right: 35px;
	padding-top: 50px;
	padding-bottom: 500px;
	margin-bottom: -465px;
}

.yp-lblue-item-col {
	padding-left: 0;
	padding-right: 25px;
}

.yp-lblue-title {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 11px;
}

.yp-lblue-desc {
	font-size: 12px;
}

.yp-lblue-item {
	margin-bottom: 50px;
}

.yp-dblue-item {
	margin-bottom: 55px;
}

.yp-dblue-item-img {
	display: inline-block;
	margin-right: 3%;
	width: 100%;
}

.yp-dblue-item-img img {
	width: 100% !important;
	height: auto !important;
}

.yp-dblue-item-text {
	display: inline-block;
	width: 100%;
	vertical-align: top;
}

.yp-dblue-item-title {
	margin-bottom: 10px;
}

.yp-dblue-item-link {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 11px;
}

.yp-dblue-link {
	background-color: #002741;
	background-image: url('../../../../assets/img/wx/about-arr.png');
	background-position: 35px center;
	background-repeat: no-repeat;
	background-size: 15px 20px;
	font-size: 20px;
	margin-bottom: 10px;
	padding: 40px 40px 40px 75px;
	display: block;
	word-wrap: break-word;
	font-weight: normal;
}

.yp-dblue-item-link {
	background-color: #002741;
	background-image: url('../../../../assets/img/wx/about-arr.png');
	background-position: 35px center;
	background-repeat: no-repeat;
	background-size: 15px 20px;
	font-size: 20px;
	margin-bottom: 10px;
	padding: 40px 40px 40px 75px;
	display: block;
	word-wrap: break-word;
}

.yp-dblue-item-desc {
	font-size: 12px;
}

@media (min-width: 600px) {
	.yp-dblue-item-img {
		width: 23%;
	}

	.yp-dblue-item-text {
		width: 73%;
	}
}

@media (min-width: 570px) {
	.yp-lower-wlink {
		width: 50%;
	}
}

@media (min-width: 992px) {
	.yp-white-link {
		font-size: 18px;
	}

	.yp-white-link:hover {
		background-position: 15px 108px, 0 0px, 0 0;
		/*background-size: 11px 12px, 238px auto, auto auto;*/
		background-size: 11px 12px, cover, cover;
	}

	.young-peace-bg {
		background-repeat: no-repeat;
		background-size: contain;
	}
}

@media (min-width: 1055px) {
	.yp-white-link {
		font-size: 20px;
	}

	.yp-white-link:hover {
		background-position: 13px 107px, 0 0px, 0 0;
		/*background-size: 13px 15px, 238px auto, auto auto;*/
		background-size: 13px 15px, cover, cover;
	}
}

@media (min-width: 1255px) {
	.yp-white-link {
		font-size: 28px;
	}

	.yp-white-link:hover {
		background-position: 10px 110px, 0 0, 0 0;
		/*background-size: 17px 20px, 238px auto, auto auto;*/
		background-size: 17px 20px, cover, cover;
	}
}

/** YOUNG PEOPLE AND PEACE BUILDING END**/

.catfirstHeading {
	font-size: 3em;
	line-height: 1em;
	font-weight: normal;
	margin-bottom: 15px;
	margin-left: 1px;
	margin-top: 21px;
	padding-bottom: 13px;
	text-decoration: none;
	padding-left: 10px;
	word-wrap: break-word;
}

.subHeading {
	font-size: 16px;
	font-weight: normal;
	margin-top: -15px;
	padding-left: 12px;
}

.subHeadingLabel {
	font-weight: bold;
}

.browse-main {
	background: #484848;
	padding: 15px;
	margin-top: 20px;
}

.special-browse-main {
	margin-top: -1px;
}

.browse-item {
	background: url('../../../../assets/img/wx/browse-arr.png') no-repeat scroll
		95% center #727272;
	color: white;
	/* font-family: Verdana/*'Numans','Arial','Sans-serif'; */
	font-weight: bold;
	padding: 10px;
	vertical-align: middle;
	margin-bottom: 5px;
	cursor: pointer;
	font-size: 14px;
}

.special-browse-item {
	background-position: 8px center;
	background-color: #426881;
	padding-left: 25px;
}

.browse-item:hover {
	background-color: #616161;
}

.special-browse-item:hover {
	background-color: #315770;
}

.mainDiv {
	width: 100%;
	height: 100%;
}
.mainTable {
	width: 960px;
}
.header-logo {
	margin-left: 20px;
	max-width: 248px;
	min-width: 83px;
	width: 100%;
}
.tdmaincontent {
	vertical-align: top;
	padding: 0;
}
#wpTextbox1 {
	width: 100%;
}
#p-cactions {
	overflow: visible;
	white-space: nowrap;
	position: relative;
	display: inline;
	width: 100%;
}
#p-cactions ul {
	padding-left: 0;
	margin: 0;
	list-style: none;
	font-size: 10px;
	list-style-position: outside;
}
#p-cactions ul li {
	float: left;
	border: 1px solid #ffffff;
	margin-left: 2px;
	margin-right: 2px;
	padding-left: 2px;
	padding-right: 2px;
	background: white;
	display: inline;
}
#p-cactions ul li a {
	text-decoration: none;
	color: #002e46;
	position: relative;
}
#p-cactions ul li a:hover {
	text-decoration: underline;
	color: #0000cc;
}
#p-cactions .new a {
	text-decoration: underline;
	color: red;
	text-decoration: none;
}
#p-cactions .selected {
	background: #333333;
	padding-top: 3px;
	position: relative;
	top: -3px;
	border: none;
}
#p-cactions .selected a {
	color: white;
	font-weight: bold;
}
#divSearchForm {
	padding-top: 4px;
	display: none;
}
#divSearchForm div {
	text-align: right;
}

.divSearchForm.mobilesearch {
	position: relative;
	top: 55px;
}

@media (min-width: 640px) {
	.catfirstHeading {
		font-size: 4.4em;
	}
}

/**************** MENUS *******************************/
.sbMenu {
	font-size: 11px;
	margin-bottom: 5px;
	text-align: left;
}
.sbMenu h5 {
	font-size: 14px;
	margin: 0;
	color: #333333;
	background: #ffffff;
	padding: 2px;
}
.sbMenu .sbMenuContainer {
	text-align: left;
}

/*********** Contenido ********************/
#mainContent {
	vertical-align: top;
	padding-left: 20px;
	padding-right: 20px;
	text-align: left;

	/*font-family: Verdana, Arial, Sans-serf;*/
	font-size: 13px;
	z-index: 1;
}
.firstHeading {
	font-size: 4.4em;
	line-height: 1em;
	font-weight: normal;
	margin-bottom: 15px;
	margin-left: 1px;
	margin-top: 21px;
	padding-bottom: 13px;
	text-decoration: none;
}
.printfooter {
	display: none;
}

/* hr {
	color: #ccc;
} */
.gInstitucion {
	color: #a9a9a9;
	font-size: 13px;
	font-family: Arial, Helvetica, Sans-serif;
}
.gFecha {
	font-size: 10px;
	color: #a9a9a9;
	font-family: Arial, Helvetica, Sans-serif;
}
.gLine {
	/* background: url("../../../../assets/img/wx/dl.gif") repeat; */
	height: 4px;
	width: 100%;
	margin-bottom: 5px;
	margin-top: 5px;
}
.editsection {
	font-size: 10px;
}

.post-p {
	line-height: 1.5em;
}

/* h2 {
	font-size: 13px;
	border-bottom: 1px solid #eeeeee;
	padding-bottom: 5px;
}

 */

/* li {
	list-style-image: url('../../../../assets/img/wx/bullet.gif');
	list-style: outside none disc;
	color: white !important;
	padding-bottom: 2px;
	line-height: 1.4em;
} */
ul.partners-int-list {
	padding-left: 20px;
}
ul.partners-int-list li {
	color: white !important;
	padding-bottom: 2px;
	line-height: 1.4em;
	font-size: 13px;
	position: relative;
}
.post-link-a {
	color: white;
	text-decoration: none;
}
.post-link-a:hover {
	color: white;
	text-decoration: underline;
}
a.btn {
	color: white;
	text-decoration: none;
}
a.btn:hover {
	color: white;
	text-decoration: underline;
}

.sr-thumb {
	border: 0px;
	display: block;
	margin-right: 8px;
	margin-top: 3px;
	max-height: 300px;
	width: 180px;
}

/* div.sr-thumb {
	background-size: cover;
	height: 130px;
	max-width: 180px;
} */

.sr-paging-links {
	background: #bc1000;
	width: 34px;
	height: 34px;
	display: inline-block;
	vertical-align: top;
	text-align: center;
	font-weight: bold;
	padding-top: 7px;

	margin-top: 5px;
}

/* a:hover {
	text-decoration: underline;
} */
#globalWrapper {
	padding-top: 10px;
	position: relative !important;
	top: 0;
	width: 835px;
}
#gsmHeader {
	background: white;
	border: none;
	height: 91px;
}
#gsmHeader .strip {
	background-color: transparent;
	background-image: url('../../../../assets/img/wx/header_back.gif');
	background-repeat: no-repeat;
	background-position: -1px 0;
	width: 999px;
	height: 101px;
	border-bottom: 1px solid #333;
}
#gsmHeader .strip .linksheader {
	padding: 7px;
	padding-top: 8px;
	float: left;
	color: white;
	font-size: 12px;
	width: 800px;
}
#gsmHeader .strip .linksheader .leftlinks {
	float: left;
}
#gsmHeader .strip .linksheader .rightlinks .selected {
	font-weight: bold;
}
#gsmHeader .strip .linksheader .rightlinks {
	float: right;
}
#gsmHeader .strip .linksheader a {
	color: white;
}
.stars-section {
	margin-top: 25px;
	float: right;
	font-size: 10px;
	font-weight: bold;
}
.stars-section td {
	text-align: right;
}
.stars-section td.lefttd {
	text-align: left;
	padding-left: 5px;
}
.stars-section td span {
	font-weight: normal;
}
#globalWrapper .content {
	opacity: 0.5;
	filter: alpha(opacity = 50) !important;
}
#globalWrapper .contentx {
	opacity: 1;
	filter: alpha(opacity = 100) !important;
}
#globalWrapper .contentx td {
	padding: 8px;
}
#globalWrapper .content td {
	padding: 8px;
}
#catlinks a {
	color: gray;
	font-weight: bold;
}
.sbMenu .related {
	text-align: left;
	float: left;
	padding-left: 8px;
	margin-top: 6px;
}
.sbMenu .thumbPic img {
	width: 157px;
	height: 117px;
	border: none;
}
.sbMenu .link {
	padding: 3px 3px 3px 3px;
}
.sbMenu .link a {
	color: #100e62;
	font-size: 12px;
	font-family: Verdana, Arial, Helvetics, Sans-serif;
}

.t-tabler {
	border-collapse: collapse;
	border-style: none;
	border-width: 0;
	padding: 0 0 0 0;
	background-color: transparent;
}
.shadow-left {
	width: 100px;
	max-width: 100px;
	background-image: url('../../../../assets/img/wx/shadow_left.jpg');
	background-repeat: repeat-y;
	padding: 0 0 0 0;
}
.t-container {
	padding: 0 0 0 0;
	background-color: #ffffff;
	vertical-align: top;
}
.shadow-right {
	width: 100px;
	max-width: 100px;
	background-image: url('../../../../assets/img/wx/shadow_right.jpg');
	background-repeat: repeat-y;
	padding: 0 0 0 0;
}
/* #fr * body {
	background-color: #ffffff !important;
} */

.transcriptContent table tr td span img {
	position: relative;
	top: 8px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
}

.ns-200 pre {
	white-space: pre-wrap;
}

/************NEW GSM *************/
.wx-action-tabs {
	background: black;
	color: #bbbbbb;
	height: 20px;
	margin-bottom: 5px;
	margin: auto;
	width: 100%;
	z-index: 3;
	padding-bottom: 10px;
	display: inline-block;
}
.wx-action-tabs ul {
	margin: 0px;
	list-style-type: none;
	padding: 0px 0 0 15px;
}
.wx-action-tabs ul li {
	margin: 0px;
	list-style-type: none;
	float: left;
	padding: 5px;
	list-style-image: none;
	line-height: 1em;
}
.wx-action-tabs a {
	color: #bbbbbb;
	font-weight: bold;
	font-size: 13px;
	font-family: 'Arial', 'sans serif';
	border-bottom: medium none;
}
.wx-action-tabs a:hover {
	color: white;
	text-decoration: none;
}

#adminmenushadow {
	background-image: url('../../../../assets/img/wx/menu-shadow.png');
	background-position: right top;
	background-repeat: repeat-y;
}

#adminmenu {
	clear: left;
	list-style: none outside none;
	margin: 0;
	padding: 0;
	width: 200px;
}

.wx-has-current-submenu .wx-menu-arrow {
	-moz-transform: translate(200px);
	-webkit-transform: translate(200px);
	-o-transform: translate(200px);
	-ms-transform: translate(200px);
	transform: translate(200px);
	cursor: auto;
	height: 30px;
	margin: 0;
	position: absolute;
	right: 100%;
	width: 6px;
	z-index: 25;
}

#adminmenu .wx-has-current-submenu .wx-menu-arrow div {
	-moz-transform: matrix(-0.6, 1, 0.6, 1, 0, 0);
	-webkit-transform: matrix(-0.6, 1, 0.6, 1, 0, 0);
	-o-transform: matrix(-0.6, 1, 0.6, 1, 0, 0);
	-ms-transform: matrix(-0.6, 1, 0.6, 1, 0, 0);
	transform: matrix(-0.6, 1, 0.6, 1, 0, 0);
	height: 15px;
	left: -1px;
	position: absolute;
	top: 7px;
	width: 14px;
}

#adminmenu li.wx-not-current-submenu .wx-menu-arrow {
	-moz-transform: translate(145px);
	-webkit-transform: translate(145px);
	transform: translate(145px);
	border-style: solid;
	border-width: 1px 0;
	height: 28px;
	display: none;
}

#adminmenu li.wx-menu-separator {
	background: none repeat scroll 0 0 #dfdfdf;
	border-color: #cfcfcf;
}
#adminmenu li.wx-menu-separator {
	border-style: solid;
	border-width: 1px 0;
	cursor: inherit;
	height: 3px;
	margin: 0;
	padding: 0;
}

#adminmenushadow {
	bottom: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 6px;
	z-index: 20;
}

#adminmenuwrap {
	border-style: solid;
	z-index: 100002;
}

#adminmenu li {
	cursor: pointer;
	margin: 0;
	padding: 0;
	list-style-image: none;
	width: 100%;
}

#adminmenuwrap {
	float: left;
	position: relative;
}
#adminmenuback,
#adminmenuwrap {
	border-style: solid;
	border-width: 0 1px 0 0;
	background: black;
}

#adminmenu a {
	display: block;
	text-decoration: none;
	font-weight: bold;
	line-height: 18px;
	min-width: 10em;
	padding: 5px;
	font-family: sans-serif;
}

#adminmenu a:hover {
	text-decoration: none;
}

.feat-audio-title {
	display: none;
}

.feat-audio-cont {
	display: none;
}
.wx-has-current-submenu .wx-submenu {
	padding: 0;
	bottom: auto;
	left: auto;
	position: relative;
	right: auto;
	top: auto;
	z-index: 2;
	display: block;
}

.wx-has-current-submenu .wx-submenu .wx-submenu-wrap {
	border: 0 none;
	box-shadow: none;
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px;
	position: relative;
}

#adminmenu div.wx-submenu {
	background-color: transparent;
}

#adminmenu {
	list-style: none outside none;
}

#adminmenu .wx-submenu ul {
	padding: 4px 0;
	list-style: none outside none;
	margin: 0;
}

#adminmenu .wx-submenu a {
	padding-left: 12px;
	margin: 0px;
	font-size: 12px;
	line-height: 18px;
	border: none;
	font-weight: normal;
}

#adminmenu li .wx-submenu-wrap {
	border-style: solid solid solid none;
}

#adminmenu .wx-not-current-submenu .wx-submenu {
	display: none;
}

#adminmenu li.wx-menu-open {
	border-style: solid;
}

#adminmenu li.wx-menu-open .wx-submenu-wrap {
	border: 0 none;
}

#adminmenu li.wx-menu-open {
	border-color: #dfdfdf;
}
#adminmenu li.wx-menu-open {
	border-style: solid;
	border-width: 0 0 1px;
}

.mainpage-buttons {
	/*border-top: 1px solid #6e6c6b;*/
	display: inline-block;
	/*margin-bottom: 50px;*/
	margin-top: 20px;
	padding-top: 39px;
	vertical-align: top;
	width: 100%;
}

.mainpage-buttons {
	background-color: black;
	margin-left: -20px;
	margin-top: 0;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 59px;
	width: 114%;
}

@media (min-width: 390px) {
	.mainpage-buttons {
		width: 111%;
	}
}

@media (min-width: 500px) {
	.mainpage-buttons {
		width: 109%;
	}
}

@media (min-width: 535px) {
	.mainpage-buttons {
		background-color: transparent;
		margin-left: 0px;
		margin-top: 20;
		padding-left: 0px;
		padding-right: 0px;
		padding-top: 39px;
		width: 100%;
	}
}

.mainpage-link {
	color: white;
	display: inline-block;
	text-align: left;
	font-size: 12px;
	padding-left: 20px;
	padding-right: 20px;
	width: 100%;
	/*margin-bottom:15px;*/
	margin-bottom: 41px;
	vertical-align: top;
}

.mainpage-linkicon {
	color: white;
	cursor: pointer;
	display: inline-block;
	font-size: 30px;
	height: 30px;
	line-height: 30px;
	/* padding-top: 68px; */
	text-align: left;
	vertical-align: bottom;
	width: 100%;
	margin-bottom: 10px;

	margin-top: 10px;
}

.mainpage-linkicon:hover {
	text-decoration: none;
	color: #daab00;
}
.mainpage-collections {
	background: url('../../../../assets/img/wx/collections.png') no-repeat
		center right 0;
	background-size: contain;
}

.mainpage-collections:hover {
	background: url('../../../../assets/img/wx/collections-hov.png') no-repeat
		center right 0;
	background-size: contain;
}

.mainpage-featured {
	background: url('../../../../assets/img/wx/featured.png') no-repeat center
		right 0;
	background-size: contain;
}

.mainpage-featured:hover {
	background: url('../../../../assets/img/wx/featured-hov.png') no-repeat
		center right 0;
	background-size: contain;
}

.mainpage-stay {
	background: url('../../../../assets/img/wx/stay.png') no-repeat center right
		0;
	background-size: contain;
}

.mainpage-stay:hover {
	background: url('../../../../assets/img/wx/stay-hov.png') no-repeat center
		right 0;
	background-size: contain;
}

.mainpage-blog {
	background: url('../../../../assets/img/wx/blog.png') no-repeat center right
		0;
	background-size: contain;
}

.mainpage-blog:hover {
	background: url('../../../../assets/img/wx/blog-hov.png') no-repeat center
		right 0;
	background-size: contain;
}

.closetools {
	border-radius: 1px 1px 1px 1px;
	color: white;
	cursor: pointer;
	display: inline-block;
	/* float: right; */
	font-weight: bold;
	height: 15px;
	margin-right: 5px;
	margin-top: 4px;
	text-align: center;
	text-shadow: none;
	width: 20px;
	font-size: 14px;
}
.opentools .mas {
	float: right;
	padding: 3px 8px 3px 3px;
}
.opentools {
	display: none;
	font-weight: bold;
	height: 24px;
	cursor: pointer;
	vertical-align: middle;
	font-size: 13px;
}
.minimized .opentools {
	display: inline-block;
	border-top: 0px;
	/* dbackground: red; */
}
.opentools:hover .legend {
	width: 40px;
	transition: width 1s;
	-webkit-transition: width 1s; /* Safari */
	-moz-transition: width 1s; /* Safari */
}
.opentools .legend {
	width: 1px;
	transition: width 1s;
	-webkit-transition: width 1s; /* Safari */
	-moz-transition: width 1s; /* Safari */
	display: inline-block;
	overflow: hidden;
	font-family: sans-serif;
	padding-left: 5px;
	padding-top: 5px;
}

#adminmenuwrap.minimized {
	border: 0px;
}

.wx-search-header input[type='submit'] {
	border: none;
	margin-right: 5px;
}

.wx-search-header input[type='text'] {
	border: 1px solid #c5c5c5;
	height: 26px;
	width: 210px;
	padding-left: 25px;
	padding-right: 5px;
}

.wx-search-header #wx-sh-fulltext {
	-webkit-transition: width 1s ease-in-out;
	-moz-transition: width 1s ease-in-out;
	-o-transition: width 1s ease-in-out;
	transition: width 1s ease-in-out;
}

.wx-search-header #wx-sh-fulltext:focus {
	width: 265px;
}

.namespace_page_cont {
	margin-left: 12px;
}

.wx-search-subtitle {
	font-size: 20px;
	line-height: 19.5px;
	margin-left: 3px;
	margin-top: 28px;
	vertical-align: baseline;
	white-space: pre-wrap;
}

.namespace_page_cont .wx-search-form {
	margin-left: 4px;
}

.srchv {
	color: #b8b8b8;
	left: 4px;
	position: absolute;
	text-shadow: 0.03em 0.04em 0.04em #d9d9d9;
	top: 3px;
	font-size: 13.4px;
}

.searchsection {
	border: 1px solid #cccccc;
	border-radius: 3px 3px 3px 3px;
	box-shadow: 2px 2px 5px #aaaaaa;
	font-family: Arial, Verdana, Helvetica;
	font-size: 12px;
	height: auto;
	margin-bottom: 15px;
	margin-top: 5px;
	padding: 5px;
	width: 98%;
}
.searchsection h3 {
	margin-top: 2px;
	width: 99%;
	border: 1px solid #ccc;
	padding: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	box-shadow: 0 0 1px #aaaaaa;
	background: #efefef;
}
.searchsection iframe {
	border: none;
	width: 100%;
	height: 260px;
	border: none;
	display: none;
}
.searchsection .expandido {
	height: 320px;
}
.searchsection h3 span.ajx img {
	width: 20px;
	height: 5px;
}
.searchsection h3 span.switch {
	color: #001166;
	text-decoration: underline;
	float: right;
	font-size: 10px;
	font-weight: normal;
	cursor: pointer;
}

.wx-title {
	font-size: 20px;
	padding: 5px;
	padding-left: 0;
	color: black;
	margin-bottom: 5px;
	border-radius: 3px 3px 3px 3px;
	-moz-border-radius: 3px 3px 3px 3px;
	-webkit-border-radius: 3px 3px 3px 3px;
	font-weight: bold;
}

.wx-txt {
	color: white;
}

.topMenu {
	background: none repeat scroll 0 0 #676767;
	padding-bottom: 10px;
	padding-top: 10px;
}

.topMenu.topMenu-odd {
	background: none repeat scroll 0 0 #5c5c5c;
}

.topMenu h5 {
	color: white;
	font-family: Oxygen, Verdana, sans-serif;
	font-size: 1.2em;
	font-weight: normal;
	text-align: center;
	margin: 0;
}

/** Collection's look topMenu **/
.topMenuCont2 {
	background-color: #393939;
}

.topMenu.leftcolumn {
	background-color: #393939;
	padding: 56px 10px 25px;
	width: 60%;
	vertical-align: top;
}

.topMenu.leftcolumn:hover,
.topMenu.rightcolumn:hover {
	cursor: default;
}

.topMenu .subcolumn {
	float: left;
	padding: 0 20px;
	text-align: left;
	width: 33.33%;
}

.subcolumn .topMenuImageLink {
	font-size: 14px;
	font-weight: bold;
	line-height: 5px;
}

.topMenuImageLinkImg {
	display: inline-block;
	height: 182px;
	overflow: hidden;
}

.topMenuImageLinkImg img {
	width: 100% !important;
	height: auto !important;
	-webkit-filter: grayscale(100%);
	-moz-filter: grayscale(100%);
	filter: grayscale(100%);
	filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
	filter: gray;
}

.subcolumn .subcolumntitle {
	/*  background-image: url("arr-toplink.png"); */
	background-position: 0 4px;
	background-repeat: no-repeat;
	padding-left: 15px;
	line-height: 15px;
	margin: 5px 0;
}

.topMenu.leftcolumn .subcolumntitlecont:hover .subcolumntitle {
	background-image: url('../../../../assets/img/wx/arr-toplink-over.png');
}

.subcolumn .column-link {
	font-size: 14px;
}

.subcolumn .column-link .wx-link a,
.subcolumn .column-link a {
	line-height: 21px;
}

.topMenu.leftcolumn .subcolumntitlecont:hover .subcolumntitle a {
	color: #ff9201;
}

.subcolumn .column-link .wx-link a:hover,
.subcolumn .column-link a:hover {
	color: #b0b0b0;
}

.leftcolumn .subcolumnfooter {
	background-image: url('../../../../assets/img/wx/arr-toplinktext.png');
	background-position: 21px 38px;
	background-repeat: no-repeat;
	clear: both;
	font-size: 12px;
	padding: 31px 20px 7px 50px;
	text-align: left;
	color: #a7a7a7;
}

.topMenuCont2 {
	background-color: #393939;
}

.topMenu.rightcolumn {
	background-color: #393939;
	border: medium none;
	width: 40%;
	padding-left: 15px;
}

.topMenu.rightcolumn {
	text-align: left;
	width: 39%;
	border-left: 1px solid #696969;
	padding-top: 40px;
}

.topItemListCont .topItemRow {
	float: left;
}

.topItemListCont .topitemList {
	float: left;
	padding: 11px 25px;
	width: 33.33%;
}

.topItemListCont .topitemList.noHeader {
	padding-top: 49px;
}

.topItemListCont .toplistTitle {
	font-size: 14px;
	font-weight: bold;
	padding-bottom: 10px;

	background-image: url('../../../../assets/img/wx/arr-toplink.png');
	background-position: 0 5px;
	background-repeat: no-repeat;
	padding-left: 15px;
}

.topItemListCont .toplistTitle:hover {
	background-image: url('../../../../assets/img/wx/arr-toplink-over.png');
}

.topItemListCont .toplistTitle:hover .wx-link a,
.topItemListCont .toplistTitle:hover a {
	color: #ff9201;
}

.topItemRow .topItem {
	font-size: 14px;
}

.topItemRow .topItem a {
	line-height: 14px;
}

.topItemRow .topItem a:hover {
	color: #b0b0b0;
}

.topItemRow .topItem a:hover,
.topItemRow .topItem .wx-link a:hover,
.subcolumn .column-link a:hover,
.subcolumn .column-link .wx-link a:hover {
	color: #b0b0b0;
}

.topItemListCont .toplistTitle .wx-link a,
.topItemListCont .toplistTitle a {
	background-image: url('../../../../assets/img/wx/arr-toplink.png');
	background-position: 0 center;
	background-repeat: no-repeat;
	padding-left: 15px;
}

.topItemListCont .toplistTitle .wx-link a:hover,
.topItemListCont .toplistTitle a:hover {
	background-image: url('../../../../assets/img/wx/arr-toplink-over.png');
}

.topItemListCont .topItem .wx-link a,
.topItemListCont .toplistTitle a {
	line-height: 5px;
}

/*****/

/*** About us top menu ***/
.topMenu.singlecolumn {
	width: auto;
}

.topMenu.singlecolumn .topItemListCont .topItemRow {
	float: none;
}

.topMenu.singlecolumn .topItemListCont .topitemList {
	width: auto;
	padding: 7px 25px;
}

.topMenu.singlecolumn .topItemListCont .toplistTitle {
	padding-bottom: 0px;
}

/* .new-menu-dropdown.new-menu-dropdown-singlecol { */
/*width:238px;
	left:63%;*/
/*width: 100%;*/
/* } */
/*****/

.menu-dropdown {
	display: none;
}

.menu-logo img {
	margin-top: 10px;
	width: 100%;
}

.headercont {
	width: 100%;
}
/*END OF TOP MENU*/

.sidebar-cont {
	padding-left: 0;
	padding-right: 0px;
}

.sidebar {
	padding: 20px;
	width: 100%;
	background: #25455a;
}

.sidebar.sidebar-orange {
	background: #d65a00;
}

.sidebar.sidebar-red {
	background: #bc1000;
}

.sidebar.sidebar-lightblue {
	background: #ff9201;
}

.sidebar.sidebar-yellow {
	background: #3f6881;
}

.sidebar-gray {
	background-color: #484848;
}

.sidebar-desc {
	font-size: 13px;
	margin-top: 7px;
}

.searchButton {
	color: #ffffff;
	/* position:absolute; */

	/* top:7px; */

	/* left:8px; */
	/* font: bold 1em arial,verdana,sans-serif; */
	margin-left: 5px;
	display: inline-block;

	/*height: 36px;
	width: 46px;*/
}

#wx-sh-form {
	position: relative;
}

form#wx-sh-form-mov {
	position: relative;
	text-align: center;
}

#wx-sh-form-mov input[type='text'] {
	border: 1px solid #c5c5c5;
	height: 26px;
	padding-left: 25px;
	padding-right: 5px;
	width: 80%;
}

#wx-sh-form-mov .searchButton {
	left: calc(10% + 3px);
	position: absolute;
	top: 7px;
}

.sr-tools {
	width: 100%;
	margin-bottom: 10px;
}

.sr-paging-low {
	float: right;
}

.goback {
	float: right;
}

.refine {
	float: right;
	margin-left: 10px;
	margin-right: 5px;
}

.leyend {
	float: left;
}

.rp-container {
	border: none;
	border-radius: 5px 5px 5px 5px;
	-moz-border-radius: 5px 5px 5px 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	padding: 10px;
	background-color: #fefefe;
}

.rp-title {
	font-size: 25px;
	border: none;
	min-height: 29px;
}

.img-float-left {
	float: left;
	padding-right: 10px;
	padding-bottom: 5px;
	margin-top: 15px;
}

.sr-desc-cont {
	/*padding-right:15px;*/
	margin-bottom: 10px;
	width: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.sr-desc-cont a {
	color: #ff9201;
}

.sr-desc-td-parent .sr-desc-cont {
	padding-right: 0px;
}

.sr-hit-descripction {
	margin-top: 10px;
}
.sr-hit-title {
	font-weight: bold;

	color: white;
	font-size: 17px;
}

.resultlist {
	line-height: 18px;
}

.searchTd .lbl {
	font-weight: bold;
}

.sr-desc-tr {
	/*display: table;*/
	display: table-row;
	width: 100%;
}

.sr-desc-tr-parent,
.sr-desc-tr-parent-three {
	display: block;
}

.sr-desc-td {
	background: none repeat scroll 0 0 #282828;
	border-bottom: 15px solid black;
	display: inline-block;
	min-height: 163px;
	padding: 10px;
	vertical-align: top;
	width: 100%;
}

.sr-desc-td.sr-desc-td-document {
	width: 100%;
	position: relative;
	padding-bottom: 50px;
}

.sr-desc-td.sr-desc-td-document .blue-btn {
	bottom: 10px;
	position: relative;
}

.sr-desc-td:hover {
	background-color: #1a1a1a;
}

#gsmsr {
	font-size: 13px;
}

.glifos-db-dialog {
	top: -150px;
	position: absolute;
}

.glifos-login-dialog {
	top: -150px;
	position: absolute;
}

/* .header-logo {
} */

.header-logo2 {
	float: right;
	display: none;
}

#wx-sh-form ::-webkit-input-placeholder {
	font-style: italic;
}

#wx-sh-form :-moz-placeholder {
	/* Firefox 18- */
	font-style: italic;
}

#wx-sh-form ::-moz-placeholder {
	/* Firefox 19+ */
	font-style: italic;
}

#wx-sh-form :-ms-input-placeholder {
	font-style: italic;
}

.logo a {
	border: medium none;
}

.login-btn-cont,
.logout-btn-cont,
.register-btn-cont {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 100003;
	display: none;
}

.register-btn-cont {
	right: 80px;
}

.login-iframe {
	border: 0 none;
	position: absolute;
	right: 0;
	top: 27px;
	height: 300px;
	width: 315px;
}

.loginframe {
	background-color: #bc1000;
	padding: 15px;
}

.loginframe h2,
.loginframe #userloginForm p {
	display: none;
}

.userloginMsg {
	color: white;
	font-family: Oxygen, Verdana;
	font-size: 13.88px;
	display: none;
}

.loginframe .userloginMsg {
	display: block;
}

.loginframe #userloginForm table {
	margin-top: 15px;
	width: 100%;
}

.loginframe .mw-label {
	font-size: 13.88px;
	font-weight: bold;
}

.loginframe .loginText,
.loginframe .loginPassword {
	border: 0 none;
	color: #6b6b6b;
	font-size: 13.88px;
	padding: 7px 10px;
	width: 100%;
}

.loginframe .loginPassword {
	letter-spacing: 3px;
}

.loginframe #wpLoginAttempt {
	background-color: #ff9200;
	border: 0 none;
	color: white;
	font-size: 12px;
	font-weight: bold;
	padding: 8px 13px;
	margin-top: 15px;
	cursor: pointer;
}

.loginframe #wpLoginAttempt:hover {
	background-color: #ffa623;
}

.loginframe #wpMailmypassword {
	background-color: transparent;
	border: 0 none;
	color: #feb3ac;
	font-size: 12px;
	text-align: right;
	cursor: pointer;
	/*position: absolute;*/
	bottom: 28px;
	right: 9px;
}

.loginframe #wpMailmypassword:hover {
	color: #ffddd5;
}

#userloginForm table {
	margin-left: auto;
	margin-right: auto;
}

.loginframe .mw-input {
	padding-bottom: 0;
}

.firstHeading {
	display: none;
}

#userlogin h2 {
	font-size: 60px;
	font-weight: bold;
}

#userlogin2 table {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}

.mw-required-msg {
	padding-bottom: 15px;
}

.red-font {
	color: #bc1000;
}
.mw-label {
	padding-top: 5px;
	vertical-align: top;
	width: 25%;
}

.mw-label label {
	font-size: 14px;
	font-weight: bold;
}

.mw-input {
	padding-bottom: 10px;
	width: 75%;
}

.loginText,
.loginTelephone,
.loginCountry,
.loginPassword {
	border: 0 none;
	padding: 5px;
	width: 80%;
	font-size: 14px;
}

.prefsectiontip {
	width: 80%;
}

#userlogin2 #userloginlink + p {
	display: none;
}

.mw-submit input {
	background-color: #ff9200;
	border: 0 none;
	color: white;
	font-size: 12px;
	font-weight: bold;
	padding: 8px 10px;
	cursor: pointer;
	font-family: Oxygen, Verdana;
}

.mw-submit input:hover {
	background-color: #ffa623;
}

#wpCreateaccountMail {
	margin-top: 8px;
}

@media (min-width: 320px) {
	#wpCreateaccountMail {
		margin-top: 0px;
	}
}

@media (min-width: 322px) {
	#wpCreateaccountMail {
		margin-left: 5px;
	}
}
/** Main page carousel**/
.carouseldisplay {
	background-color: #fefefe;
	padding-left: 23px;
	padding-right: 23px;
	width: auto;
	padding-top: 18px;
	padding-bottom: 18px;

	border-radius: 5px 5px 5px 5px;
	-moz-border-radius: 5px 5px 5px 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
}

.car-inactive {
	display: none;
}

.car-inactive img {
	opacity: 0;
	filter: alpha(opacity=0); /* For IE8 and earlier */
	visibility: visible;
	z-index: 0;
	position: absolute;
}

.carouseldisplay .imgdescriptions {
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.7);
	bottom: 0;
	left: 0;
	padding: 10px;
	position: absolute;
}

.carouseldisplay .desc {
	color: white;
	font-size: 21px;
	font-family: 'Arial';
	vertical-align: middle;
	text-align: left;
	line-height: 1.2em;
}

.imgdescriptions-td {
	/*background: none repeat scroll 0 0 #E6E6E6;*/
	/*padding-left: 20px;
    padding-right: 20px;
	padding-bottom: 35px;*/
	text-align: left;
	width: 100%;
	/*display: inline-block;	*/
	/*display:table-cell;*/
	height: 100%;
	/*min-height: 356px;*/
}

.gallery-table {
	border-collapse: collapse;
	/*height: 356px;*/
	width: 100%;
	position: relative;
}

.gallery-title {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	position: absolute;
	padding: 20px;
	font-size: 16px;
	background-color: rgba(255, 255, 255, 0.5);
	bottom: 0;
	width: 100%;
	color: black;
	display: none;
}

.gallery-wrapper li {
	list-style: none outside none;
	display: inline-block;
	width: 195px;
	position: relative;
}

.gallery-separator {
	width: 100%;
}

.gallery-title-li {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	background-color: rgba(0, 0, 0, 0.5);
	bottom: 5px;
	color: #ffffff;
	padding: 5px;
	position: absolute;
	width: 180px;
	display: none;
}

.gallery-wrapper ul {
	padding: 0;
}

.cardispcont .carouseldisplay-table {
	display: inline-block !important;
}
.carouseldisplay-table {
	padding: 0;
	/*display: inline-block;*/
	/*display:table-cell;*/
	position: relative;
	text-align: center;
}

.carouselcontainer .carouseldisplay-table {
	width: 100%;
}

.gallery-logo {
	float: right;
	margin-right: 50px;
}

.descinactive {
	opacity: 0;
	filter: alpha(opacity=0); /* For IE8 and earlier */
	visibility: visible;
	z-index: 0;
	position: absolute;
	display: none;
}

.desc a {
	color: inherit;
	font-weight: normal;
}

/* .descactive a {
} */

.descactive a:hover {
	text-decoration: underline;
}

.desc a:hover {
	text-decoration: underline;
}

.leftarrow,
.rightarrow {
	font-weight: bold;
	color: #b51233;
	font-size: 17px;
	font-family: 'Arial';
}

.leftarrow {
	float: left;
	cursor: pointer;
}

.rightarrow {
	float: right;
	cursor: pointer;
}

.nav-circle {
	background-color: transparent;
	background-repeat: no-repeat;
	width: 21px;
	height: 21px;
	margin-right: 7px;
	cursor: pointer;
	display: inline-block;
}

.unselected-circle {
	background-image: url('../../../../assets/img/wx/unselected-circle.png');
}

.selected-circle {
	background-image: url('../../../../assets/img/wx/selected-circle.png');
}

.carousel-navigation {
	float: left;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.5);
	padding-left: 5px;
	padding-top: 5px;
	padding-bottom: 5px;
	border-radius: 25px;
}

.cardispcont {
	position: relative;
}

.carousel-navigation-controls {
	bottom: 10px;
	position: absolute;
	right: 30px;
}

.carousel-loading {
	width: 728px;
	height: 334px;
	background-color: #9c9898;
	padding-left: 36px;
	padding-right: 36px;
	padding-top: 22px;
	padding-bottom: 30px;
	border-radius: 5px 5px 5px 5px;
	-moz-border-radius: 5px 5px 5px 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	background-image: url('../../../../assets/img/wx/ajax-loader.gif');
	background-repeat: no-repeat;
	background-position: center center;
}

.mp-widgets {
	background-color: #fefefe;
	width: auto;
	border-radius: 5px 5px 5px 5px;
	-moz-border-radius: 5px 5px 5px 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	color: #153764;
	font-size: 12.5px;
	font-family: 'Arial';
}

.mp-widgets a {
	color: #153764;
	font-size: 12.5px;
	font-family: 'Arial';
}

.mp-widgets h2 {
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 14px;
	background-color: #153764;
	font-size: 16px;
	color: #fefefe;
	font-family: MuseoSans-500, 'Arial';
	font-weight: normal;
	border-radius: 5px 5px 0 0;
	-moz-border-radius: 5px 5px 0 0;
	-webkit-border-radius: 5px 5px 0 0;
}

.carouselcontainer {
	margin-bottom: 10px;
	margin-top: 18px;
	width: 100%;
}

.car-active img {
	/*max-width: 1029px;*/
	max-width: 100%;
	/*width: 100%;*/
}

.car-active {
	line-height: 0px;
	min-height: 350px;
	display: inline-block;
}

.car-active a {
	border-bottom: none;
}

.owl-page.active {
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
}
/** Main page carousel end**/

/** Breadcrumbs **/
.breadcrumbs {
	margin-top: 45px;
	margin-bottom: 7px;
}

.breadcrumbs-margin {
	margin-left: 15px;
}

.bread-item {
	font-size: 1.1em;
	line-height: 1.1em;
	cursor: pointer;
}

.bread-item-actual {
	color: #ffa200;
	cursor: auto;
}

.bread-separator {
	margin-left: 8px;
	margin-right: 8px;
}

.timeperiods-breadcrumbs {
	margin-left: 10px;
}
/** Breadcrumbs end**/

.timeperiods-tcont {
	padding-left: 11px;
	padding-right: 0;
}

/** Footer **/

.footer-social a {
	border-bottom: 0 none;
}

.gsmFooter {
	background: none repeat scroll 0 0 #000000;
	font-size: 11px;

	padding: 15px 0 15px;
	text-align: left;
	min-height: 60px;
	width: 100%;
}

.gsmFooterContainer {
	margin: auto;
	text-align: center;
}

.gsmFooter .tbl {
	width: 100%;
}
.gsmFooter .neg {
	font-weight: bold !important;
	font-size: 11px;
}
.gsmFooter .poweredby {
	border: 0;
}

.footer-links {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.footer-links a {
	color: #ffffff;
	font-size: 13px;
	margin: 0 10px;
	display: inline-block;
	margin-top: 10px;
}

.footer-social {
	margin-bottom: 15px;
}

.footer-row1 {
	margin-bottom: 20px;
	color: white;
	font-size: 12px;
}

.footer-copyright {
	color: #5f5f5f;
}

.footer-row1 .footer-links {
	margin-top: 5px;
}

.foot-fb {
	background: url('../../../../assets/img/wx/ourfb.png');
	display: inline-block;
	height: 18px;
	width: 7px;
}

.foot-fb:hover {
	background: url('../../../../assets/img/wx/ourfb-hov.png');
}

.foot-yt {
	background: url('../../../../assets/img/wx/ouryt.png');
	display: inline-block;
	height: 18px;
	width: 13px;
	margin-left: 17px;
}

.foot-yt:hover {
	background: url('../../../../assets/img/wx/ouryt-hov.png');
}

.foot-tw {
	background: url('../../../../assets/img/wx/ourtw.png');
	background-position: 0 2px;
	background-repeat: no-repeat;
	display: inline-block;
	height: 18px;
	width: 16px;
	margin-left: 14px;
}

.foot-tw:hover {
	background: url('../../../../assets/img/wx/ourtw-hov.png');
	background-position: 0 2px;
	background-repeat: no-repeat;
}

.foot-gp {
	background: url('../../../../assets/img/wx/ourg+.png') no-repeat scroll 0
		2px rgba(0, 0, 0, 0);
	display: inline-block;
	height: 18px;
	margin-left: 15px;
	width: 15px;
}

.foot-gp:hover {
	background: url('../../../../assets/img/wx/ourg+-hov.png') no-repeat scroll
		0 2px rgba(0, 0, 0, 0);
}

.foot-pi {
	background: url('../../../../assets/img/wx/ourpin.png') no-repeat scroll 0
		0px rgba(0, 0, 0, 0);
	display: inline-block;
	height: 18px;
	margin-left: 14px;
	width: 19px;
}

.foot-pi:hover {
	background: url('../../../../assets/img/wx/ourpin-hov.png') no-repeat scroll
		0 0px rgba(0, 0, 0, 0);
}

.foot-in {
	background: url('../../../../assets/img/wx/ourinst.png') no-repeat scroll 0
		1px rgba(0, 0, 0, 0);
	display: inline-block;
	height: 18px;
	margin-left: 14px;
	width: 17px;
}

.foot-in:hover {
	background: url('../../../../assets/img/wx/ourinst-hov.png') no-repeat
		scroll 0 1px rgba(0, 0, 0, 0);
}
/* @media (min-width: 820px) {
	.footer-links a {
	}
} */
/** Footer end **/

.groups-sidebar {
	background: none repeat scroll 0 0 #000000;
	color: #ffffff;
	float: left;
	padding: 10px 0 5px;
	font-size: 1.2em;
	width: 80%;
	margin-bottom: 10px;
}

.sidebar-entry {
	padding: 10px 15px;
}

.sidebar-entry a {
	color: #ffffff;
}

.sidebar-entry a:hover {
	color: #c1272d;
	text-decoration: none;
}

.sidebar-title {
	font-size: 2.2em;
}

.sidebar-src {
	font-size: 1.2em;
	margin-bottom: 5px;
	margin-top: 20px;
	width: 100%;
	padding: 2px 6px 2px 6px;
}

.sidebar-title a {
	color: #ffffff;
}

.sidebars {
	width: 17%;
	display: inline-block;
}

/** Infinite carousel **/
.infinitecarousel {
	background-color: transparent;
	margin-bottom: 15px;
	margin-top: 10px;
	padding-top: 7px;
	position: relative;
	width: 100%;
	min-height: 200px;
}

.infinitecarousel .gallery-wrapper {
	/*height: 120px;
    margin-right:0px;
	margin-top:0px;
	margin-bottom:0px;

	width: 607px;*/
	overflow: hidden;
	/*margin-left: 65px;
	width:90%;*/
	width: 100%;
	margin-left: -10px;
	background-color: black;
}

.gallery-wrapper li img {
	opacity: 0.5;
}

.gallery-wrapper .selected-thumb {
	opacity: 1;
}

.infinitecarousel .gallery-wrapper ul {
	list-style: none outside none;
	margin: 0;
	padding: 0;
	width: 99999px;
}

.infinitecarousel .gallery-wrapper ul li {
	display: block;
	float: left;
	padding: 10px;
}

.gallery-arrow-back {
	background: transparent url('../../../../assets/img/wx/carousel-left.png')
		no-repeat scroll 0 0;
	background: url('../../../../assets/img/wx/about-arr-left.png') no-repeat
		scroll 0 0 / 17px 22px transparent;
	background: url('../../../../assets/img/wx/about-arr-left.png') no-repeat
		scroll 3px 5px / 17px 22px rgba(0, 0, 0, 0.25);
	cursor: pointer;
	display: block;
	/*width:19px;*/
	width: 23px;
	/*height:22px;*/
	height: 31px;
	/*bottom: 82px;*/
	bottom: 42%;
	float: left;
	/*position:relative;*/
	position: absolute;
	margin-left: 5px;
}

.gallery-arrow-forward {
	background: transparent url('../../../../assets/img/wx/carousel-right.png')
		no-repeat scroll 0 0;
	background: url('../../../../assets/img/wx/about-arr.png') no-repeat scroll
		0 0 / 17px 22px transparent;
	background: url('../../../../assets/img/wx/about-arr.png') no-repeat scroll
		3px 5px / 17px 22px rgba(0, 0, 0, 0.25);
	cursor: pointer;
	display: block;
	/*width:19px;*/
	width: 23px;
	/*height:22px;*/
	height: 31px;
	/*position:relative;*/
	position: absolute;
	/*bottom: 82px;*/
	bottom: 42%;
	right: 0;
	float: right;
	margin-right: 15px;
}

a:hover.gallery-arrow-back {
	text-decoration: none;
}
a:hover.gallery-arrow-forward {
	text-decoration: none;
}

/* SEARCH */

.sr-hit-title .gda-metadata-part1 a {
	color: #000000;
	font-weight: bold;
	font-size: 12px;
}

.filtrar-por {
	color: white;
	display: inline-block;

	font-family: Oxygen;

	font-size: 24px;
	font-weight: normal;
	margin-bottom: 20px;
}

.maintitle1 {
	font-family: Verdana;
	font-size: 12px;
	font-weight: normal;
	color: black;
	margin-bottom: 4px;
	padding-left: 7px;
	margin-top: 15px;
}

.src-container-title {
	/* background: none repeat scroll 0 0 transparent; */
	/* padding: 4px 3px 4px 10px; */
	vertical-align: middle;

	/* font-family: Oxygen; */
	font-size: 18px;
	font-weight: normal;

	color: white;
	margin-bottom: 10px;

	/* cursor:pointer; */
}

.src-arrow-down,
.src-arrow-up,
.src-white-arrow-down,
.src-white-arrow-up {
	float: right;
	margin-right: 3px;
	margin-top: 4px;
}

.src-arrow-up {
	display: none;
}

.searchparam.frommonth,
.searchparam.fromday,
.searchparam.fromyear,
.searchparam.tomonth,
.searchparam.today,
.searchparam.toyear,
.searchparam.sortby,
.father-voice {
	font-size: 11px;
	padding: 0;
}

.searchparam.adminreports {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background: url('../../../../assets/img/wx/drop-arr.png') no-repeat scroll
		7px center rgba(255, 255, 255, 1);
	border: 0 none;
	padding: 5px 5px 5px 20px;
}

.searchdate .text {
	color: white;

	background: #727272;
	display: inline-block;
	height: 35px;
	vertical-align: top;
	padding-top: 7px;
	padding-left: 10px;
	font-weight: bold;
	padding-right: 10px;
}

.sr-themes {
	color: black;
	font-size: 12px;
}

.sr-adv-title {
	/* color:black; */
	font-size: 60px;
	font-weight: bold;
	/* font-family: Verdana; */
	/*width: 876px;	*/
	display: inline-block;
	/* width: 20%; */
	font-weight: normal;
}

.sr-adv-title-special {
	width: 100%;
	word-wrap: break-word;
}

.sr-adv-srtitle {
	display: none;
	font-family: Verdana;
	/*width: 150px;*/

	font-size: 14px;
	margin-right: 1px;

	/* position:absolute; */

	color: #b1b1b1;
	margin-top: 10px;
	vertical-align: top;
}

.sr-adv-search {
	/* border: 1px solid #BCBEC0; */

	/* height: 21px; */
	margin-right: 0;
	padding-left: 0;
	padding-top: 0;

	/* width: 80%; */
	position: relative;
	/* margin-left: 95px; */
	display: inline-block;

	/*** responsive-ness ***/
	width: 100%;
}

/* .sr-adv-search { */
/*display: inline-block;*/
/* width: 80%; */
/* } */

.sr-adv-search-report {
	width: 290px !important;
}

.sr-adv-srbox {
	bottom: 0;
	display: block;
	float: none;
	height: 25px;
	margin-bottom: 10px;
	margin-top: 0;
	position: relative;
	right: 0;
	text-align: right;
}

.sr-adv-srbox.adminpage {
	margin-top: 42px;
	pointer-events: none;
}

.sr-adv-search .mag-glass {
	left: 3px;
	position: relative;
	top: 4px;
}

.sr-adv-search .header-search-box {
	color: #939598;
	font-family: Verdana;

	font-size: 14px;

	padding-top: 2px;

	margin-top: 0px;

	padding-left: 5px;
	padding-right: 5px;
	/*width: 790px !important;*/

	border: 0px;

	height: 36px;
	vertical-align: top;

	/* responsive-ness */
	width: calc(100% - 51px);
}

/* .src-container-title-selected { */
/* color:white; */
/* background:#000000; */
/* } */

.src-white-arrow-up,
.src-white-arrow-down {
	display: none;
}

.src-container-title-selected .src-white-arrow-down {
	display: block;
}

.src-cont {
	width: 100%;
	border-collapse: collapse;
	display: inline-block;
}

.sr-head-tools {
	background-color: #3b3b3b;
	padding: 15px;
	position: relative;
	margin-bottom: 10px;
}

.src-save {
	display: inline-block;
}

.src-save a {
	background-color: black;
	color: white;
	float: left;
	width: 108px;
	padding-right: 13px;
	padding-left: 13px;
	padding-top: 6px;
	padding-bottom: 5px;
	vertical-align: middle;

	margin-left: 16px;
	font-size: 11px;

	cursor: pointer;
}

.sr-clear-filters {
	color: white;
	cursor: pointer;
	display: inline-block;
	/* float: right; */

	/* font-family: Verdana; */

	font-size: 12px;
	margin-bottom: 10px;
	margin-top: -2px;
	padding: 5px;
	vertical-align: top;
}

.sr-clear-filters-active {
	display: inline-block;
}

.sr-page-options {
	margin-top: 5px;
	/* vertical-align: middle; */
	display: block;
	right: 10px;
	top: 6px;
	position: static;
}

.cat-page-options {
	padding-bottom: 5px;
	padding-right: 50px;
	text-align: right;
}

.cat-page-options * {
	text-align: left;
}

.advancedsearch {
	margin-top: 20px;
}

.searchcourt {
	margin-top: 10px;
}

.searchparam.noclass {
	background: none repeat scroll 0 0 transparent;
	display: inline;
	padding: 0;
}

.src-container-title-legislacion {
	background: none repeat scroll 0 0 #dcddde;
	color: #000000;
	cursor: pointer;
	font-size: 12px;
	font-weight: normal;
	margin-bottom: 5px;
	padding: 4px 3px 4px 10px;
	vertical-align: middle;
}

/*------------------------
          REFERENCE
--------------------------*/

.reference h1 {
	font-size: 18px;
	color: #000000;
	font-weight: normal;
	margin-bottom: 0;
	padding-top: 20px;
	padding-bottom: 20px;
}

.reference {
	font-size: 11px;
	line-height: 16px;
}

.reference th {
	text-align: right;
	padding-right: 5px;
	vertical-align: top;
	padding-top: 13px;
}

.reference ul {
	margin: 0;
	padding-left: 13px;
}

.reference tr {
	border-bottom: 1px solid #bcbec0;
}

.reference td {
	padding-top: 13px;
	padding-bottom: 20px;
}

/*------------------------
          COURT
--------------------------*/

.court h1 {
	font-size: 18px;
	color: #000000;
	font-weight: normal;
	margin-bottom: 0;
	padding-top: 20px;
	padding-bottom: 20px;
}

.court {
	font-size: 11px;
	line-height: 16px;
}

.court th {
	text-align: right;
}

/*------------------------
          Summary
--------------------------*/

.summary h1 {
	font-size: 18px;
	color: #000000;
	font-weight: normal;
	margin-bottom: 0;
	padding-top: 20px;
	padding-bottom: 20px;
}

.summary {
	font-size: 11px;
	line-height: 16px;
}

.wx-addvoice-button {
	background-color: #ffffff;
	background-image: url('../../../../assets/img/wx/more.png');
	background-position: center center;
	background-repeat: no-repeat;
	border: 1px solid #c6c6c6;
	height: 23px;
	opacity: 0.8;
	margin-top: 1px;
	padding: 0 10px;
	/*position: relative;*/
	position: absolute;
	right: 0;
	width: 5px;
	cursor: pointer;
	float: right;
}

.wx-removevoice-button {
	background-color: #ffffff;
	background-image: url('../../../../assets/img/wx/delcriteria.png');
	background-position: center center;
	background-repeat: no-repeat;
	border: 1px solid #c6c6c6;
	border-left: 0 solid;
	height: 25px;
	opacity: 0.8;
	padding: 0 10px;
	position: relative;
	left: -32px;
	width: 5px;
	cursor: pointer;
	float: right;
}

.logo-small-footer > img {
	margin-bottom: -3px;
	margin-left: 4px;
}

.logo-small-footer {
	display: inline-block;
}

.total-matches,
.total-selected {
	color: #ffffff;
	float: right;
	padding-left: 10px;
}

#selected-sentences {
	display: inline;
}

.summary-author {
	text-align: right;
	display: none;
	font-size: 14px;
	font-family: Verdana;
}

.gda-summary-ajax p {
	text-align: justify;
}

.numpages {
	margin-right: 5px;
	margin-top: -5px;
	/*width: 50px;*/
	width: 132px;
	font-size: 11px;
}

.admin-sr-page-options,
.assign-sr-page-options {
	margin-top: 10px;
	display: inline-block;
}

.admin-sr-page-options.namespace-reports select {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background: url('../../../../assets/img/wx/drop-arr.png') no-repeat scroll
		7px center rgba(255, 255, 255, 1);
	border: 0 none;
	padding: 5px 5px 5px 20px;
}

.sr-adv-srbox.adminpage .admin-sr-page-options {
	pointer-events: auto;
}

.suggested-advise {
	background: none repeat scroll 0 0 #ffc0cb;
	color: #111111;
	display: inline-block;
	font-size: 14px;
	padding: 5px;
}

#dk_container_numpages .dk_toggle {
	width: 50px !important;
}

.trigger {
	padding-left: 8px;
	position: relative;
	top: 0px;
	cursor: pointer;
}

.hasDatepick {
	border: 1px solid #cecfd1;
	margin-bottom: 15px;
	cursor: pointer;
	font-size: 14px;
	font-weight: normal;
	margin-bottom: 5px;
	padding: 4px 3px 4px 10px;
	vertical-align: top;
	height: 35px;
}

.admin-sr-sort-options {
	display: inline;
	margin-left: 24px;
	font-size: 12px;
}

.admin-sr-block-options {
	display: inline;
	margin-left: 37px;
	font-size: 11px;
}

/* .managmentreport-type {
} */

.managmentreport-type select {
	font-size: 11px;
	margin-left: 28px;
	margin-right: 5px;
	margin-top: 15px;
}

.block-input {
	font-size: 11px;
	margin-left: 5px;
	height: 10px;
}

.search-header {
	padding-left: 23px;
	text-align: left;
}

.search-checkbox {
	padding-left: 25px;
}

.loading_overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	background-color: grey;
	opacity: 0.5;
}

.loading_image {
	position: fixed;
	top: 26%;
	left: 38%;
}

.assign-result {
	background-color: #d3d3d3;
	display: inline-block;
	left: 310px;
	padding: 7px;
	position: relative;
	top: 43px;
	display: none;
}

.category-sr-voice {
	display: inline-block;
	margin-top: 10px;
	margin-left: 15px;
}

.category-paging:hover {
	cursor: pointer;
}

.categorytext {
	padding-left: 15px;
	padding-right: 15px;
}

.category-siblings {
	text-align: right;
}

.category-siblings.owl-carousel {
	width: 90%;
	margin-left: 5%;
}

.category-siblings.owl-carousel .owl-item {
	background-color: transparent;
}

.category-siblings .owl-nav {
	display: none;
}

.category-siblings .owl-next,
.category-siblings .owl-prev {
	position: absolute;
	top: 0;
	height: 38px;
	width: 25px;
	background-repeat: no-repeat;
	background-position: center center;
	cursor: pointer;
}

.category-siblings .owl-next {
	right: -30px;
	background-image: url('../../../../assets/img/wx/main-carousel-right.png');
}

.category-siblings .owl-prev {
	left: -35px;
	background-image: url('../../../../assets/img/wx/main-carousel-left.png');
}

.category-siblings .owl-dots {
	display: none;
}

.category-sibling {
	background-color: #6a6a6a;
	background-image: url('../../../../assets/img/wx/arr-toplink.png');
	background-position: 10px 15px;
	background-repeat: no-repeat;
	display: inline-block;
	margin-right: 10px;
	padding: 10px 10px 10px 25px;
	margin-bottom: 15px;
	display: none;
}

.category-sibling.current-sibling {
	background-color: #d55a00;
	position: relative;
}

.category-sibling.current-sibling:after {
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 12px solid #d55a00;
	content: '';
	height: 10px;
	left: 45%;
	position: absolute;
	top: 100%;
	width: 10px;
	transform: translate(-10px, 0px);
}

.category-sibling-mobile select {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background: url('../../../../assets/img/wx/drop-arr.png') no-repeat scroll
		7px center rgba(255, 255, 255, 1);
	border: medium none;
	font-family: oxygen, verdana, sans-serif;
	padding: 10px 10px 10px 21px;
	text-indent: 1px;
	text-overflow: '';
	width: 100%;
}

.src-container-title-legislacion-selected {
	background: none repeat scroll 0 0 #000000;
	color: #ffffff;
}

.sr-hit-cont {
	height: 250px;
	width: 215px;
}

.sr-submit {
	background: none repeat scroll 0 0 #000000;
	border: 0 none;
	color: #ffffff;
	float: right;
	font-family: Verdana;
	height: 24px;
	margin-top: -1px;
	margin-right: 0px;
	position: absolute;
	font-weight: bold;
	right: 0;
}

.searchcriteriatitle {
	font-size: 14px;
	left: 25px;
	font-family: Verdana;
	top: 10px;
	opacity: 1;
	margin-top: 13px;
}

.searchcriteria-active .searchcriteria,
.searchcriteria-active .searchcriteriatitle {
	opacity: 1;
}

.sr-hit-description-cat {
	color: #706f6f;
	font-family: 'Arial';
	font-size: 11px;
	font-weight: bold;
}

.clear-filter {
	color: white;
	display: inline-block;
	font-family: Verdana;
	font-size: 13px;
	font-weight: bold;
	margin-left: 5px;
	margin-top: -1px;
	position: relative;
	vertical-align: top;
}

.clr-filter-text {
	border-bottom: 1px dotted #000000;
	display: inline-block;
	margin-top: -1px;
}

.legendchangetype {
	color: #bbbbbb;
	font-family: 'Arial', 'sans serif';
	font-size: 13px;
	font-weight: bold;
}

.legendchangetype:hover {
	color: #ffffff;
	text-decoration: none;
	cursor: pointer;
}

.searchparam.autor {
	width: 179px;
}

#typemenu {
	clear: left;
	list-style: none outside none;
	margin: 0;
	padding: 0;
	width: 145px;
}

#typemenu a {
	border: medium none;
	font-size: 12px;
	font-weight: normal;
	line-height: 18px;
	margin: 0;
	padding-left: 12px;
}

#typemenu li {
	cursor: pointer;
	list-style-image: none;
	margin: 0;
	padding: 0;
	width: 100%;
}

/*  DROPKICK, seccion para los dropdowns custom */

.dk_theme_black {
	line-height: 6px;
}
.dk_theme_black ul {
	list-style: none;
	background-color: white;
	padding-left: 0 !important;
}
.dk_theme_black .dk_toggle,
.dk_theme_black.dk_open .dk_toggle {
	background-color: transparent;
	/* background-image: url("dropdown1.png"); */
	background-position: right center;
	color: gray;
	text-shadow: none;
	border-color: #8aa9c5;
}
.dk_theme_black .dk_options a {
	color: gray;
	text-shadow: none;
	font-style: normal;
}
/* .dk_theme_black .dk_options a:hover,
.dk_theme_black .dk_option_current a {
} */
.dk_options {
	border-radius: 0;
	box-shadow: 0;
	top: 12px !important;
}

.dk_theme_court {
	line-height: 13px;
	float: none;
	width: 100%;
}
.dk_theme_court ul {
	list-style: none;
	background-color: white;
	padding-left: 0 !important;
}
.dk_theme_court .dk_toggle,
.dk_theme_court.dk_open .dk_toggle {
	background-color: #727272;
	background-image: url('../../../../assets/img/wx/src-arrow.png');
	background-position: 95% center;
	color: gray;
	text-shadow: none;
	border-color: #cecfd1;
	border: none;
}

.dk_theme_court .dk_toggle {
	font-size: 11px;

	width: 100% !important;
	min-height: 8px;
}
.dk_theme_court .dk_options a {
	color: gray;
	text-shadow: none;
	font-style: normal;
}

.dk_theme_date {
	line-height: 6px;
	display: inline-block !important;
	float: none;
	margin-left: 3px;
}

.dk_theme_date:first-child {
	margin-left: 0px;
}
.dk_theme_date ul {
	list-style: none;
	background-color: white;
	padding-left: 0 !important;
}
.dk_theme_date .dk_toggle,
.dk_theme_date.dk_open .dk_toggle {
	background-color: transparent;
	/* background-image: url("../../../../assets/img/wx/dropdown1.png"); */
	background-position: right center;
	color: gray;
	text-shadow: none;
	border-color: #cecfd1;
	height: 6px;
	padding: 7px 0 7px 5px;
	font-size: 10px;
}
.dk_theme_date .dk_options a {
	color: gray;
	text-shadow: none;
	font-style: normal;
}

.dk_theme_sr_options {
	line-height: 6px;
	display: inline-block !important;
	float: none;
	margin-left: 5px;
	background: #727272;
	border: 0;
}

.dk_theme_sr_options:first-child {
	margin-left: 0px;
}
.dk_theme_sr_options ul {
	list-style: none;
	background-color: white;
	padding-left: 0 !important;
}
.dk_theme_sr_options .dk_toggle,
.dk_theme_sr_options.dk_open .dk_toggle {
	background-color: transparent;
	background-image: url('../../../../assets/img/wx/dropdown2.png');
	background-position: 90% center;
	color: gray;
	text-shadow: none;
	/* border-color:#cecfd1; */
	/* height:6px; */

	padding: 10px 15px 9px 7px;

	font-size: 14px;
	border: 0;
}
.dk_theme_sr_options .dk_options a {
	color: gray;
	text-shadow: none;
	font-style: normal;
}

/* FIN DROPKICK*/

.src-date {
	display: inline-block;
	/* margin-left: 5px; */

	/* margin-right: initial; */
}

.searchcollection {
	margin-top: 10px;
	height: 64px;
}

.searchtype .searchparam {
	background: none repeat scroll 0 0 #727272;
	color: white;
	/* font-family: Verdana/*'Numans','Arial','Sans-serif'; */
	font-size: 12px;
	font-weight: bold;
	padding: 10px;
	vertical-align: middle;
	margin-bottom: 5px;
	cursor: pointer;
	font-size: 14px;
}

.searchcat .searchparam {
	color: black;
	font-family: Verdana; /*'Numans','Arial','Sans-serif';*/
	font-size: 11px;
	font-weight: normal;
	padding: 4px 3px 4px 10px;
	vertical-align: middle;
	margin-bottom: 5px;
	cursor: pointer;
	border: 1px solid #cecfd1;
	width: 187px;
}

.searchparamselected {
	/* border-bottom: 1px solid #000000; */
	background: #ff9201 !important;
}

.sr-tools {
	/* border-top: 3px solid #47453F; */
	height: 30px;
	/*margin-top: 20px;*/
	width: 100%;
}

.sr-paging-low {
	float: right;
}

.sr-page-numbers {
	display: inline;

	color: #2c1c0d;
	font-size: 11px;
	font-weight: bold;
}

.sr-pages-ellipsis {
	display: inline-block;
	color: white;
	font-size: 20px;
	margin-right: 5px;
	font-family: Oxygen;
	letter-spacing: 3px;
}

.sr-pages-anchors {
	font-size: 13px;
	color: white;

	height: 34px;
	width: 34px;
	display: inline-block;
	background-color: #bc1000;
	text-align: center;
	vertical-align: middle;
	padding-top: 8px;

	margin-top: 5px;
}

/* fieldset {
	border: none;
	display: block;
	margin-left: 0;
	margin-right: 0;
	padding: 0;
} */

.search-navmenu .maintitle1 {
	cursor: pointer;
}

.searchregioncont {
	/* background-image: url('../../../../assets/img/wx/regionbg.jpg'); */
	background-repeat: no-repeat;
	text-align: center;
}

.searchcat {
	margin-top: 35px;
}

.astitle {
	color: #ffffff;
	font-size: 21.83px;
	font-family: 'Arial';
	font-weight: bold;
	padding-left: 10px;
}

#fulltextsearch {
	width: 335px;
	border: none;
	border-radius: 9px 9px 9px 9px;
	-webkit-border-radius: 9px 9px 9px 9px;
	-moz-border-radius: 9px 9px 9px 9px;
	height: 23px;
	padding-left: 5px;
}

#searchbutton {
	/* background-image: url('../../../../assets/img/wx/searchbuttonbg.png'); */
	background-repeat: no-repeat;
	background-color: transparent;
	border: medium none;
	bottom: 26px;
	float: right;
	font-family: 'Arial';
	font-size: 16px;
	font-weight: bold;
	height: 28px;
	padding-bottom: 3px;
	padding-left: 36px;
	position: relative;
	right: 11px;
	vertical-align: middle;
	width: 121px;
	color: #20170e;
}

.searchcriteria {
	background-repeat: repeat-y;
	/*text-align: center;*/
	/*width: 490px;*/
	padding-left: 4px;

	/* border: 1px solid #E1E3E5; */
	opacity: 0;
	transition: opacity 1s;
	margin-top: 10px;
	background-color: #484848;
}

.searchcriteriacont {
	position: relative;
	margin-bottom: 12px;
	width: 80%;
	/* float: right; */
	display: inline-block;
}

.sc-field {
	background-color: #727272;
	border: none;

	/* font-family: Verdana; */ /*Numans,Arial,Sans-serif;*/
	font-size: 14px;
	display: inline;
	padding: 5px;
	/*border-radius: 4px 4px 4px 4px;
-webkit-border-radius:4px 4px 4px 4px;
-moz-border-radius:4px 4px 4px 4px;*/
	display: inline-block;
	margin: 5px;
}

.sc-field span {
	display: inline-block;

	color: white;
	background-color: #727272;
	vertical-align: middle;
	margin-right: 1px;
	/* margin-top: 0px; */
}

.wx-search-form .searchButton {
	/* margin-right: -1px; */
	/* position: absolute; */
	/* right: 0; */
	height: 36px;
	width: 46px;
}

.wx-search-header .searchButton {
	position: absolute;
	top: 7px;
	left: 3px;
}

.wx-search-ajax-update {
	box-sizing: border-box;
	display: inline-block;
	vertical-align: top;
	width: 100%;
}

.wx-search-ajax-update .firstHeading {
	font-size: 16px;
	margin: 0;
	padding: 0;
	display: inline-block;
}

.searchdate .text {
	display: inline-block;
}
.src-date {
	display: inline-block;
	/* margin-left: 5px; */
	/* margin-right: 64px; */
}

.sr-number-hits {
	display: inline-block;
	margin-left: 5px;
	position: relative;
	top: -2px;
}

.searchcriteria-active .searchcriteria,
.searchcriteria-active .searchcriteriatitle {
	opacity: 1;
}

.sr-paging {
	text-align: left;
	margin-bottom: 5px;
}
/* END SEARCH */

.mainpage-twitter {
	display: inline-block;
	/*max-width: 30%;*/
	width: 100%;
	margin-bottom: 5px;
	margin-top: 5px;
	max-height: 323px;
	height: 323px;
}

.articles-update {
	-moz-box-sizing: padding-box;
	box-sizing: padding-box;
	background: none repeat scroll 0 0 #000000;
	display: inline-block;
	width: 333px;
	padding: 10px;
	vertical-align: top;
}

/*** NEWS LANDING PAGE ***/
.newsl-categories {
	padding: 0;
	/*margin-left: 34px;
	width: 94%*/
}

.newsl-categories .owl-nav {
	display: none;
}

.newsl-category {
	background-image: url('../../../../assets/img/wx/news-arr.png');
	background-position: 0 6px;
	background-repeat: no-repeat;
	font-size: 22px;
	padding-left: 20px;
	padding-right: 5px;
	display: none;
	margin-right: 20px;
}

.newsl-featured {
	margin-top: 18px;
	padding: 0;
}

.newsl-new-featured {
	padding-left: 0;
	padding-right: 0;
}

.newsl-categories .owl-carousel .owl-item:nth-child(2n),
.newsl-categories .owl-carousel .owl-item:nth-child(2n + 1) {
	background-color: transparent;
}

.newsl-categories .owl-prev {
	background-image: url('../../../../assets/img/wx/main-carousel-left.png');
	left: -35px;
}

.newsl-categories .owl-next {
	background-image: url('../../../../assets/img/wx/main-carousel-right.png');
	right: -30px;
}

.newsl-categories .owl-next,
.newsl-categories .owl-prev {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 13px 17px;
	cursor: pointer;
	height: 28px;
	position: absolute;
	top: 0;
	width: 25px;
}

.newsl-new-featured img,
.newsl-blog-featured img,
.newsl-blog-mini img {
	width: 100%;
}

/* .newsl-categories .owl-stage-outer {
} */

.newsl-new-featured img {
	min-height: 200px;
}

.newsl-blog-featured img,
.newsl-blog-mini img {
	min-height: 100px;
}

.newsl-new-title,
.newsl-blog-title,
.newsl-blog-mini-title {
	bottom: 0px;
	left: 0;
	position: absolute;
	right: 0;
}

.newsl-new-title {
	right: 0px;
	font-size: 20px;
	font-weight: bold;
}

.newsl-new-date {
	font-size: 13px;
	color: #58bdff;
	display: block;
}

.newsl-blog-title {
	font-size: 20px;
	font-weight: bold;
}

.newsl-blog-mini-title {
	font-size: 16px;
	font-weight: bold;
}

.newsl-new-title a,
.newsl-blog-title a,
.newsl-blog-mini-title a {
	background-color: rgba(0, 0, 0, 0.6);
	display: block;
	padding: 20px;
}

.newsl-blog-featured {
	/*margin-bottom: 15px;*/
	margin-bottom: 29px;
	padding: 0;
	display: none;
}

.newsl-blogc-featured {
	padding: 0;
	display: none;
}

.newsl-blog-mini {
	padding: 0;
}

.newsl-blog-mini:first-child {
	padding: 0 15px 0 0;
}

.newsl-blog-mini:last-child {
	padding: 0 0 0 15px;
}

.newsl-separator {
	background-color: #282828;
	color: transparent;
	height: 2px;
	margin-bottom: 30px;
	margin-top: 30px;
	display: none;
}

.newsl-recent {
	/*padding: 0 25px 0 0;*/
	padding: 0;
	margin-top: 15px;
}

.newsl-popular {
	/*padding: 0 0 0 25px;*/
	padding: 0;
}

.newsl-bot-title {
	background-color: #5b5b5b;
	font-size: 22px;
	padding: 5px;
	text-align: center;
}

.newsl-item-cont {
	margin-top: 0px;
	padding: 0;
}

.newsl-item-row {
	float: left;
	margin-bottom: 15px;
	overflow: hidden;
	width: 100%;
}

.newsl-blog-image {
	/*width: 848px;*/
	width: 100%;
	height: 534px;
	background-size: cover;
}

.newsl-item {
	padding: 0;
	/*margin-bottom: 15px;*/
	display: none;
}

.newsl-item-first {
	display: block;
}

/* .newsl-item-odd { */
/*padding-right:7px;*/
/* }

.newsl-item-even { */
/*padding-left:7px;*/
/* } */

.newsl-item-subcont {
	background-color: #282828;
	/*padding: 20px;*/

	margin-bottom: -480px;
	padding: 20px 20px 500px;
}

.newsl-item-date {
	color: #a1a1a1;
	font-size: 14px;
	margin-bottom: 30px;
}

.newsl-item-title {
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 18px;
}

.newsl-item-desc {
	font-size: 12px;
	margin-bottom: 9px;
}

.newsl-bot-readmore {
	display: none;
}

.newsl-category-mobile {
	overflow: hidden;
}

.newsl-category-mobile select {
	background-image: url('../../../../assets/img/wx/drop-arr.png');
	background-position: 7px center;
	background-repeat: no-repeat;
	border-color: -moz-use-text-color -moz-use-text-color -moz-use-text-color
		#dddddd;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-image: none;
	border-style: none none none solid;
	border-width: 0 0 0 3px;
	padding: 12px 12px 12px 20px;
	width: 100%;
	font-size: 14px;
	font-weight: bold;
	color: #484848;
}

@media (min-width: 768px) {
	.newsl-new-featured {
		padding-right: 35px;
	}

	.newsl-new-title {
		right: 35px;
	}

	.newsl-blog-featured {
		display: block;
	}

	.newsl-blogc-featured {
		display: block;
	}

	.newsl-recent {
		padding: 0 15px 0 0;
	}

	.newsl-popular {
		padding: 0 0 0 15px;
	}

	.newsl-item {
		display: block;
	}

	.newsl-item-odd {
		padding-right: 9px;
	}

	.newsl-item-even {
		padding-left: 9px;
	}

	.newsl-bot-readmore {
		display: block;
	}

	.newsl-item-btn a {
		height: 56px;
	}

	.category-sibling {
		display: inline-block;
		white-space: nowrap;
	}

	.category-sibling-mobile {
		display: none;
	}

	.newsl-recent {
		margin-top: 0px;
	}

	.newsl-item-cont {
		margin-top: 15px;
	}

	.category-siblings .owl-nav {
		display: block;
	}
}

@media (min-width: 955px) {
	.newsl-item-btn a {
		height: 40px;
	}
}

@media (min-width: 992px) {
	.newsl-categories {
		padding: 0;
		margin-left: 34px;
		width: 94%;
	}

	.newsl-categories .owl-nav {
		display: block;
	}

	.newsl-category-mobile {
		display: none;
	}

	.newsl-category {
		display: inline-block;
		white-space: nowrap;
	}

	.newsl-new-title {
		font-size: 38px;
	}

	.newsl-new-date {
		font-size: 24px;
	}

	.newsl-recent {
		padding: 0 23px 0 0;
	}

	.newsl-popular {
		padding: 0 0 0 23px;
	}

	.newsl-separator {
		display: block;
	}

	.newsl-item-row {
		width: auto;
	}
}
/*** END NEWS LANDING PAGE ***/

/**NEWS PAGES**/
.sidebarNews {
	float: left;
	margin-right: 20px;
	max-width: 17%;
}

.about-news {
	margin-bottom: 12px;
}

.news-first-column {
	padding: 0;
}

.news-second-column {
	padding: 0 0 0 45px;
}

.news-title {
	font-family: 'Oxygen';
	font-size: 38px;
	font-weight: bold;
	margin-bottom: 15px;
}

.news-date {
	color: #58bdff;
	font-size: 24px;
	margin-bottom: 41px;
}

.news-img > img {
	/*width: 100%;*/
	width: 100% !important;
	height: auto !important;
}

.news-abstract {
	background-color: #fefefe;
	font-size: 12.5px;
	font-family: 'Arial';
	width: 753px;
	padding: 20px;
	color: #040000;
	border-radius: 5px 5px 5px 5px;
	-moz-border-radius: 5px 5px 5px 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	margin-bottom: 15px;
	margin-top: 15px;
	min-height: 114px;
	/*float:left;*/
}

.news-abstract-left img {
	float: right;
}

.news-gray-cont {
	background-color: #282828;
	padding: 17px 19px;
}

.news-next-story {
	margin-bottom: 13px;
}

.news-next-story .btn {
	margin-top: 0;
}

.news-blog-post {
	margin-bottom: 52px;
}

.news-blog-title {
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 12px;
}

.news-morenews-title {
	color: #8a8a8a;
	font-size: 30px;
	font-weight: bold;
	margin-bottom: 18px;
	text-align: center;
}

.news-morenews-item {
	padding: 0;
	margin-bottom: 24px;
}

.news-morenews-item-image {
	width: 100%;
	/*height: 132px;*/
	height: 0px;
	padding-bottom: 80%;
	background-size: cover;
}

.news-odd {
	padding-right: 15px;
}

.news-even {
	padding-left: 15px;
}

.news-morenews-item img {
	width: 100%;
}

.news-morenews-item a:hover {
	text-decoration: none;
}

.news-morenews-item .news-morenews-item-text {
	background-color: rgba(0, 0, 0, 0.6);
	bottom: 0px;
	display: block;
	left: 0;
	padding: 10px;
	position: absolute;
	text-align: center;
}

.news-even .news-morenews-item-text {
	left: 15px;
	right: 0px;
}

.news-odd .news-morenews-item-text {
	left: 0px;
	right: 15px;
}

.news-category a {
	background-image: url('../../../../assets/img/wx/arr-toplinktext.png');
	background-position: 8px center;
	background-repeat: no-repeat;
	background-size: 9px 11px;
	display: block;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 10px;
	padding: 11px 15px 11px 30px;
	width: 100%;
}

.news-category a:hover {
	text-decoration: none;
	background-color: #3f3f3f;
}

.news-blog-desc {
	color: #b2b2b2;
	font-size: 16px;
}

/*.news-title{
	font-family: 'Arial';
	font-weight:bold;
	font-size:37.5px;
	margin-bottom: 21px;
	margin-top: 12px;
	padding-top: 10px;
}*/

.news-abstract-title {
	font-size: 25px;
	line-height: 1.25em;
}
.news-abstract-title a {
	font-size: 25px;
	color: black;
}

/*.news-first-column .sharePopupParent{
	left: 45%;
}*/

.news-first-column .sharePopupParent,
.iframe-display .sharePopupParent {
	left: -10px;
}

.news-first-column .sharePopupParent .sharePopupContainer .sharePopup,
.iframe-display .sharePopupParent .sharePopupContainer .sharePopup {
	width: 100%;
	height: auto;
}

.iframe-display
	.sharePopupParent
	.sharePopupContainer
	.sharePopup.sharePopupIframe {
	width: 540px;
}

.news-first-column .sharePopupParent .sharePopup.sharePopupExpanded,
.iframe-display .sharePopupParent .sharePopup.sharePopupExpanded {
	height: 595px;
}

.iframe-display .sharePopupIframe .sociallinks {
	display: none;
}

.iframeview textarea.sharePopupIframe {
	height: 53px;
}

@media (min-width: 570px) {
	.news-first-column .sharePopupParent .sharePopup.sharePopupExpanded,
	.iframe-display .sharePopupParent .sharePopup.sharePopupExpanded {
		height: 520px;
	}
}

@media (min-width: 580px) {
	.news-first-column .sharePopupParent,
	.iframe-display .sharePopupParent {
		left: auto;
		right: 0;
	}
}

.news-btn-row-top {
	margin-top: 15px;
}

.news-btn-row-bottom {
	margin-top: 33px;
}

.img-right {
	float: right;

	margin-left: 5px;
}
.img-left {
	float: left;
	margin-right: 5px;
}

.news-abstract-author-date {
	font-size: 16px;
	margin-top: 10px;
}

.img-left p,
.img-right p {
	margin-bottom: 0;
	margin-top: 0;
}

.more-news-abstracts,
.more-news-abstracts a {
	color: #153764;
	font-weight: bold;
	cursor: pointer;
	float: left;
}

.search-news {
	background-color: white;
	border-radius: 5px 5px 5px 5px;
	-moz-border-radius: 5px 5px 5px 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	padding: 5px;
	padding-bottom: 9px;
	margin-bottom: 18px;
}

.search-news-title {
	text-align: center;
	font-weight: bold;
	font-size: 12px;
	color: #b51233;
	font-family: 'Arial';
}

.search-news .wx-main-search-input {
	margin-top: 6px;
	width: 150px;
	margin-left: 0px;
	margin-bottom: 0px;
	background-color: #fcfafa;
	border: 2px solid #b51233;
	border-radius: 3px 3px 3px 3px;
	-moz-border-radius: 3px 3px 3px 3px;
	-webkit-border-radius: 3px 3px 3px 3px;
}

.sidebar-widgets-title {
	text-align: center;
	font-family: MuseoSans-500, 'Arial';
	font-size: 15px;
	font-weight: normal;
	color: #fefefe;
	background-color: #b51233;
	padding-bottom: 5px;
	padding-top: 5px;
	/*width:100%;*/
	border-radius: 5px 5px 0 0;
	-moz-border-radius: 5px 5px 0 0;
	-webkit-border-radius: 5px 5px 0 0;
}

.news-featured {
	/*width:163px;*/
	/*margin-bottom:18px;*/
	margin-bottom: 8px;
}

.news-featured-loading {
	width: 100%;
	background-color: #fdfbfb;
	height: 200px;
	border-radius: 0px 0 5px 5px;
	-moz-border-radius: 0px 0 5px 5px;
	-webkit-border-radius: 0px 0 5px 5px;
}

.news-featured-wx-search-ajax-update {
	background-color: #fdfbfb;
	border-radius: 0px 0 5px 5px;
	-moz-border-radius: 0px 0 5px 5px;
	-webkit-border-radius: 0px 0 5px 5px;
	padding-top: 23px;
	padding-bottom: 23px;
	padding-right: 5px;
	padding-left: 5px;
}

/* a.widget-hit-title { * /
color: white;
/*color:#B51233;
font-weight:bold;
font-family:'Arial';*/
/* } */

/* a.widget-hit-title-results {
	color: white;
	font-weight: bold;
	font-family: 'Arial';
}
 */
.widget-hit {
	/*margin-bottom:15px;*/
	margin-bottom: 5px;
	margin-left: 6px;
}

.news-categories-content {
	/*padding-top:23px;
padding-bottom:23px;*/
	padding-right: 5px;
	padding-left: 5px;
	border-radius: 0px 0 5px 5px;
	-moz-border-radius: 0px 0 5px 5px;
	-webkit-border-radius: 0px 0 5px 5px;
	padding-bottom: 0;
	padding-top: 5px;
}

.news-categories {
	/*width:163px;
margin-bottom:18px;*/
	/*background: none repeat scroll 0 0 #000000;
    color: #FFFFFF;
    float: left;*/
	/*font-size: 1.2em;*/
	/*margin-bottom: 10px;
    padding: 10px 0 5px;
margin-bottom:8px;
width:100%;*/

	clear: both;
	margin-bottom: 50px;
	padding-top: 60px;
}

.news-category-title {
	font-size: 24px;
	margin-bottom: 10px;
}

.news-archive-content {
	background-color: #fefefe;
	/*padding-top:23px;
padding-bottom:12px;*/
	padding-right: 5px;
	padding-left: 5px;
	border-radius: 0px 0 5px 5px;
	-moz-border-radius: 0px 0 5px 5px;
	-webkit-border-radius: 0px 0 5px 5px;
	padding-bottom: 0;
	padding-top: 4px;
}

.news-archive-content ul {
	margin-left: 5px;
	padding-left: 0;
}

.news-archive-content li {
	list-style: none;
	/*padding-bottom:11px;*/
	padding-bottom: 5px;
}

.news-archive-content li a {
	color: #af3737;
	font-weight: bold;
	font-family: 'Arial';
}
.news-text {
	/*background-color:#ffffff;
font-size:12.5px;
font-family:'Arial';*/
	/*width: 753px;*/
	/*padding:0 20px 20px;
color:black;*/
	font-size: 15px;
	margin-top: 27px;
	/*border-radius:5px 5px 5px 5px;
-moz-border-radius:5px 5px 5px 5px;
-webkit-border-radius:5px 5px 5px 5px;*/
}

.news-text-title {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 5px;
}

.latest-news {
	color: #ffffff;
	font-size: 1.7em;
	margin-bottom: 10px;
}

.news-description {
	/*display: inline-block;
    vertical-align: top;
	color:white;
	margin-left: 10px;
    max-width: 250px;*/
	color: #b2b2b2;
	font-size: 16px;
}

.news-thumb {
	display: inline-block;
}

.news-more {
	font-size: 0.9em;
	text-align: right;
}

.news-second-column {
	display: none;
}

@media (min-width: 992px) {
	.news-second-column {
		display: block;
	}

	.news-date {
		margin-bottom: 17px;
	}

	.news-text {
		margin-top: 37px;
	}

	.about-news {
		margin-bottom: 30px;
	}

	.newsl-featured {
		margin-top: 30px;
	}
}
/* FB comments */
.news-comment-area {
	margin-top: 31px;
}

.news-comments {
	padding: 5px;
	background-color: white;
}

.news-comments > div > span {
	width: 100% !important;
}

.news-comments iframe {
	width: 100% !important;
}
/**NEWS END**/

/* div.floatright,
table.floatright {
	border: 0 none;
	clear: right;
	float: right;
	margin: 0 0 0.5em 0.5em;
	position: relative;
} */

.cp-list > table {
	width: 100%;
}

.imgdescriptions {
	padding-top: 27px;
}

.footer-poweredby {
	clear: both;
	height: 36px;
	margin-top: 5px;
	/*position:absolute;*/
	width: 100%;
	/*bottom:0;*/
}

.poweredby-mediawiki {
	float: right;
}

.gda-metadata {
	color: #ababab;
}

.gda-metatitle {
	font-weight: bold;
	text-align: right;
	color: #d5d5d5;
}

.meta-en,
.meta-fr,
.meta-kr {
	display: none;
}

.imgdescriptions.en .meta-en,
.imgdescriptions.fr .meta-fr,
.imgdescriptions.kr .meta-kr {
	display: table;
}

.gal-lang-control .control-en,
.gal-lang-control .control-kr,
.gal-lang-control .control-fr {
	display: inline;
	margin-right: 5px;
	cursor: pointer;
}

.imgdescriptions.en .gal-lang-control .control-en,
.imgdescriptions.kr .gal-lang-control .control-kr,
.imgdescriptions.fr .gal-lang-control .control-fr {
	display: none;
}

#lshare4 {
	font-size: 11px;
	left: 0;
	position: relative;
	top: 0;
	z-index: 999999;
}

#lshare4 table {
	padding-bottom: 2px !important;
	background: none repeat scroll 0 0 white !important;
	width: 80px !important;
	position: relative;
	z-index: 10;
}

.lista {
	display: none;
	height: 0;
	left: 105px;
	overflow: visible;
	position: relative;
	top: -125px;
	width: 0;
}

.metalabel {
	text-align: right;
}

/* Calendar */
.dpbtn {
	vertical-align: text-top;
}

/* the div that holds the date picker calendar */
/* .dpDiv {
} */

/* the table (within the div) that holds the date picker calendar */
.dpTable {
	font-family: Tahoma, Arial, Helvetica, sans-serif;
	font-size: 12px;
	text-align: center;
	color: #505050;
	background-color: #ece9d8;
	border: 1px solid #aaaaaa;
}

/* a table row that holds date numbers (either blank or 1-31) */
/* .dpTR {
} */

/* the top table row that holds the month, year, and forward/backward buttons */
/* .dpTitleTR {
} */

/* the second table row, that holds the names of days of the week (Mo, Tu, We, etc.) */
/* .dpDayTR {
} */

/* the bottom table row, that has the "This Month" and "Close" buttons */
/* .dpTodayButtonTR {
} */

/* a table cell that holds a date number (either blank or 1-31) */
.dpTD {
	border: 1px solid #ece9d8;
}

/* a table cell that holds a highlighted day (usually either today's date or the current date field value) */
.dpDayHighlightTD {
	background-color: #cccccc;
	border: 1px solid #aaaaaa;
}

/* the date number table cell that the mouse pointer is currently over (you can use contrasting colors to make it apparent which cell is being hovered over) */
.dpTDHover {
	background: #aaaacc;
	border: 1px solid #666699;
	cursor: pointer;
}

/* the table cell that holds the name of the month and the year */
/* .dpTitleTD {
}
 */
/* a table cell that holds one of the forward/backward buttons */
/* .dpButtonTD {
}
 */
/* the table cell that holds the "This Month" or "Close" button at the bottom */
/* .dpTodayButtonTD {
}
 */
/* a table cell that holds the names of days of the week (Mo, Tu, We, etc.) */
.dpDayTD {
	background-color: #cccccc;
	border: 1px solid #aaaaaa;
	color: white;
}

/* additional style information for the text that indicates the month and year */
.dpTitleText {
	font-size: 12px;
	color: gray;
	font-weight: bold;
}

/* additional style information for the cell that holds a highlighted day (usually either today's date or the current date field value) */
.dpDayHighlight {
	color: #4060ff;
	font-weight: bold;
}

/* the forward/backward buttons at the top */
.dpButton {
	font-family: Verdana, Tahoma, Arial, Helvetica, sans-serif;
	font-size: 10px;
	color: gray;
	font-weight: bold;
	padding: 0;
}

/* the "This Month" and "Close" buttons at the bottom */
.dpTodayButton {
	font-family: Verdana, Tahoma, Arial, Helvetica, sans-serif;
	font-size: 10px;
	color: gray;
	font-weight: bold;
}

/* End Calendar */

.nopermission {
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.85);
	color: #ffffff;
	font-weight: bold;
	margin: 100px auto auto;
	padding: 15px 10px;
	text-align: center;
}

.free-sidebar {
	display: none;
}

.left-column {
	display: inline-block;
	margin-right: 20px;
	vertical-align: top;
	width: 200px;
}

.right-column {
	display: inline-block;
	width: 80%;
}

@media (max-width: 480px) {
	.right-column {
		width: 100%;
	}
}

.mobmenu {
	/* background: none repeat scroll 0 0 #e6e6e6; */
	float: right;
	margin-right: 10%;
	height: 40px;
	padding-top: 15px;
}

.topMenuCont,
.divSearchForm {
	display: none;
}

.mobmenu img {
	height: 40px;
	cursor: pointer;
}

.mainpage-ft {
	display: inline-block;
	vertical-align: top;
	margin-bottom: 20px;
	text-align: center;
}

.mainpage-ft p {
	margin: 0;
	padding-top: 17px;
}

.mainpage-ft img {
	margin-top: 12px;
	margin-left: 5px;
	float: right;
}

.mainpage-car {
	display: inline-block;
	width: 100%;
}

.twitter-timeline.twitter-timeline-rendered {
	max-height: 323px;
	width: 100% !important;
}

.src-left-col {
	/*opacity:0;*/
	/*visibility:hidden;*/
	transition: all 0.5s ease-out 0s;
	max-height: 0;
	overflow: visible;
	width: 100%;
	left: -1500px;
	position: absolute;
	/* background:white; */
	box-sizing: border-box;
	height: 100%;
	padding: 10px;
	top: 0;
}

.src-left-col-active {
	/*opacity:1;*/
	/*visibility:visible;*/
	max-height: 1000px;
	left: 0px;
	z-index: 100;
}

.mobile-filters {
	display: inline-block;
	margin-right: 3px;
}

.close-filtrar-por {
	float: right;
	cursor: pointer;
}

.dk_theme_sr_options a {
	min-width: 61px;
}

@media (min-width: 385px) {
	.sr-page-options {
		/* float: right; */
		position: absolute;
		margin-bottom: 5px;
		display: inline-block;
	}
}

@media (min-width: 535px) {
	.sr-thumb {
		float: left;
		display: inline;
	}

	.sr-adv-srbox {
		height: 25px;
		margin-bottom: 10px;
		margin-top: 30px;
		width: 53%;
		display: inline-block;
		position: relative;
		right: 0;
		bottom: 0;
		text-align: right;
		/* float: right; */
	}
	.mainpage-buttons {
		margin-top: 110px;
		border-top: 1px solid #6e6c6b;
		/*margin-bottom: 50px;*/
		margin-bottom: -20px;
		padding-bottom: 70px;
		/* background-image: linear-gradient(rgba(0, 0, 0, 0), black); */
	}
	.mainpage-link {
		width: 49%;
		text-align: center;
		margin-bottom: 15px;
	}
	.mainpage-linkicon {
		color: white;
		cursor: pointer;
		display: inline-block;
		font-size: 30px;
		height: 100px;
		line-height: 30px;
		padding-top: 68px;
		vertical-align: bottom;
		width: 100%;
		margin-bottom: 30px;
		text-align: center;
	}

	.mainpage-linkicon:hover {
		text-decoration: none;
		color: #daab00;
	}
	.mainpage-collections {
		background: url('../../../../assets/img/wx/collections.png') no-repeat
			center 0;
	}

	.mainpage-collections:hover {
		background: url('../../../../assets/img/wx/collections-hov.png')
			no-repeat center 0;
	}

	.mainpage-featured {
		background: url('../../../../assets/img/wx/featured.png') no-repeat
			center 0;
	}

	.mainpage-featured:hover {
		background: url('../../../../assets/img/wx/featured-hov.png') no-repeat
			center 0;
	}

	.mainpage-stay {
		background: url('../../../../assets/img/wx/stay.png') no-repeat center 0;
	}

	.mainpage-stay:hover {
		background: url('../../../../assets/img/wx/stay-hov.png') no-repeat
			center 0;
	}

	.mainpage-blog {
		background: url('../../../../assets/img/wx/blog.png') no-repeat center 0;
	}

	.mainpage-blog:hover {
		background: url('../../../../assets/img/wx/blog-hov.png') no-repeat
			center 0;
	}
}

@media (min-width: 629px) {
	.mainpage-ft iframe {
		float: left;
	}

	.mainpage-ft img {
		float: right;
		margin-top: 3px;
	}
}

@media (min-width: 992px) {
	.divSearchForm {
		display: block;
	}
	.news-comment-area {
		margin-top: 57px;
	}
	.gda-metadata {
		margin-left: 35px;
	}
}

/*@media (min-width: 960px) {	*/
@media (min-width: 960px) {
	.mainpage-link {
		width: 24%;
	}

	.sr-adv-title {
		width: 20%;
	}

	.sr-adv-title-special {
		width: 100%;
	}

	.feat-audio-title {
		display: block;
	}

	.feat-audio-cont {
		display: block;
	}
	.topMenuCont {
		overflow-y: visible;
	}
	div.feat-content {
		width: 30%;
	}

	.close-filtrar-por {
		display: none;
	}

	.sr-adv-srbox {
		width: 80%;
		margin-bottom: 0;

		text-align: right;
	}

	.searchcriteriatitle {
		position: absolute;
		opacity: 0;
	}

	.searchcriteria {
		margin-left: 115px;
	}
	.mainpage-car {
		display: inline-block;
		width: 880px;
	}
	.header-logo2 {
		display: block;
	}

	#MainDiv {
		min-height: 370px;
		width: 100%;
	}
	.gsmFooterContainer {
		width: 1200px;
	}
	.footer-links {
		display: inline-block;
	}

	.headercont {
		height: 29px;
	}

	.free-sidebar {
		background: none repeat scroll 0 0 #000000;
		color: #ffffff;
		float: left;
		padding: 10px 0 5px;
		font-size: 1.2em;
		width: 200px;
		margin-bottom: 10px;
		margin-right: 20px;
		display: block;
	}

	.sr-adv-search .header-search-box {
		width: 65%;
	}

	.mainpage-twitter {
		display: inline-block;
		/*max-width: 30%;*/
		width: 18%;
	}

	#mainContent {
		padding-right: 5px;
	}

	.mainpage-twitter {
		margin-bottom: 0;
		margin-top: 0;
	}

	.mainpage-ft img {
		margin-left: 0px;
	}

	.sr-desc-td {
		width: 49%;
		display: table-cell;
		margin-right: 1%;
		border-right: 15px solid black;
	}

	.sr-desc-td-one-result {
		display: block;
	}

	.sr-desc-tr-document-three .sr-desc-td {
		width: 33%;
	}

	.sr-desc-td.sr-desc-td-parent-three {
		width: 32.5%;
		margin-right: 0.8%;
	}

	.sr-desc-td.sr-desc-td-document {
		width: 24%;
	}

	.src-left-col {
		opacity: 1;
		max-height: 1000px;
		display: inline-block;
		visibility: visible;
		vertical-align: top;
		width: 20%;
		padding: 0;
		position: static;
	}

	.mobile-filters {
		display: none;
	}

	.wx-search-ajax-update {
		padding-left: 25px;
		width: 80%;
	}

	.searchcriteriatitle {
		opacity: 0;
		transition: opacity 1s;
	}
}
@media (min-width: 992px) {
	.searchcriteriacont-sep {
		display: inline-block !important;
		width: 20%;
	}

	.topMenuCont {
		margin: auto;
		display: inline-block;
		list-style: none outside none;
		margin: 0 auto;
		padding: 0;
		position: relative;
		z-index: 100001;
		top: 43px;
		left: 0;
		width: 100%;
	}
	.mobmenu {
		display: none;
	}
	.topMenu,
	.topMenu.topMenu-odd {
		background: none;
	}
	.topMenuContMov {
		display: none !important;
	}

	/*ANKORA BASH*/
	.topMenu:first-child h5 {
		border-left: none;
	}

	.topMenu a {
		color: white;
		line-height: 2em;
	}

	.topMenu:hover a,
	.topMenu:hover h5 {
		text-decoration: none;
		color: #c1272d;
	}

	.topMenu:hover a {
		color: #ffffff;
	}

	.subcolumnfooter a:hover {
		color: #ff9201;
	}

	/* .topMenu.leftcolumn:hover a,
	.topMenu.rightcolumn:hover a {
		/*color:#FFFFFF;* /
	} */

	.topMenu:hover {
		/*background: none repeat scroll 0 0 #DDDDDD;*/
		cursor: pointer;
	}

	.topMenu h5 {
		color: #707070;
		margin: 0;
		font-weight: normal;
		line-height: 29px;
		font-size: 0.9em;
	}

	.topMenu {
		text-align: center;
		display: inline-block;
		position: relative;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		width: 20%;
	}

	.menu-dropdown {
		background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);
		display: none;
		opacity: 0.98;
		position: absolute;
		text-align: left;
		width: 155px;
		z-index: 90;
		left: 0;
	}

	.new-menu-dropdown {
		left: 0;
		/*position: fixed;*/
		right: 0;
		width: 100%;
		opacity: 1;
		border-bottom: 3px solid white;
		z-index: 100001;
	}

	.topMenuChild {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding: 5px;
		font-size: 14px;
	}

	.topMenuChild h5 {
		line-height: 17px;
		color: white !important;
	}

	.topMenuChild:hover {
		background: black;
	}

	#divSearchForm {
		padding-top: 4px;
		display: inline-block;
	}

	.menu-dropdown-shown {
		display: block;
	}

	.menu-logo {
		display: none;
	}
}

@media (min-width: 1200px) {
	.mainpage-ft iframe {
		float: left;
	}
	.mainpage-ft img {
		float: right;
		margin-top: 19px;
	}

	.mainpage-ft {
		margin-bottom: 0px;
		width: 291px;
	}
}

/*** MAIN PAGE CHANGE **/

.feat-content {
	background: none repeat scroll 0 0 #f7f7f7;
	border: 1px solid #efefef;
	box-sizing: border-box;
	display: inline-block;
	height: 321px;
	padding: 15px;
	vertical-align: top;
	width: 100%;
}

.feat-content-title {
	font-size: 18px;
	font-weight: bold;
}

.feat-news-title {
	font-weight: bold;
	margin-top: 10px;
	margin-bottom: 5px;
}

.feat-news-thumb {
	display: inline-block;
}

.feat-news-thumb img {
	width: 47px;
}

.feat-news-desc {
	display: inline-block;
	width: 80%;
	vertical-align: top;
}

.feat-news-more {
	float: right;
}

.feat-art-title {
	font-weight: bold;
	margin-top: 10px;
	margin-bottom: 5px;
}

.feat-audio-title {
	font-weight: bold;
	margin-top: 20px;
	margin-bottom: 5px;
}

.feat-art-content img {
	width: 100%;
	max-width: 300px;
}

/*** END MAIN PAGE CHANGE **/

/**GDA MOBILE STYLE **/
.gda-mobile-prev {
	text-align: center;
}

.gda-mobile-prev-fs {
	margin-left: -46px;
	position: relative;
	cursor: pointer;
}

.gda-mobile-prev-img {
	display: inline-block;
	max-width: 80%;
	border: 1px solid black;
	cursor: pointer;
}
/** END OF GDA MOBILE **/

.wx-template {
	background: none repeat scroll 0 0 #9a9a9a;
	border: 1px dashed black;
	padding: 5px;
}

.wx-template-xml {
	display: none;
}

/********* SHARE POPUP *********/
.shareTools {
	float: left;
	height: 1px;
	width: 1px;
	display: none;
}

.newShareTools {
	background-color: rgba(0, 0, 0, 0.75);
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100010;
}

.sharePopup {
	background-color: #f4f5f7;
	display: inline-block;
	height: 280px;
	width: 540px;
}

.sharePopupParent {
	/*display: table;*/
	display: none;
	/*height: 100%;
    width: 100%;*/
	z-index: 100011;
	position: absolute;
	top: 50px;
	left: 5%;
}

.sharePopupContainer {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
}

.sharePopup .header {
	background-color: #153764;
	position: relative;
}

.sharePopup .header h6 {
	border: 0 none;
	color: white;
	font-family: Oxygen;
	font-size: 24px;
	padding: 10px 25px;
	text-align: left;
	margin: 0;
}

.sharePopup .close {
	color: white;
	cursor: pointer;
	display: block;
	font-family: 'Arial';
	font-size: 16px;
	font-weight: bold;
	position: absolute;
	right: 18px;
	top: 17px;
}

.sharePopup .close {
	border: 0 none !important;
}

.header img {
	margin-right: 10px;
	margin-top: -4px;
	width: 25px;
}

.shareBody {
	height: 293px;
	padding: 20px;
	padding-top: 0;
}

.news-first-column .shareBody {
	height: auto;
}

.linksection h2 {
	border: 0 none;
	color: #456;
	font-size: 18px;
	margin-bottom: 10px;
	padding-top: 5px;
}

.linksection {
	left: 11px;
	text-align: left;
	width: 238px;
	display: inline-block;
}

.linkinput,
.mailinput {
	background-color: white;
	border: 3px solid #d2d2d2;
	padding-bottom: 4px;
	padding-right: 5px;
	padding-top: 6px;
	position: relative;
}

.linkinput input,
.mailinput input {
	border: medium none;
	color: #7b8084;
	font-size: 15px;
	line-height: 1;
	padding-left: 32px;
	padding-right: 5px;
	width: 194px;
}

.linkinput img,
.mailinput img {
	color: #d3d3d3;
	left: 9px;
	position: absolute;
	width: 15px;
}

.socialsection {
	display: inline-block;
	margin-left: 22px;
	width: 240px;
	position: relative;
	top: -7px;
	text-align: left;
}

.socialsection li {
	list-style: none;
	display: inline-block;
}

.socialsection h2,
.shareemail h2,
.embed h2 {
	border: 0 none;
	color: #456;
	font-size: 18px;
	margin-bottom: 10px;
	padding-top: 5px;
}

.socialsection ul {
	margin: 0;
	padding: 0;
}

.socialsection li a {
	border: none !important;
	cursor: pointer;
}

.shareemail {
	margin-top: 20px;
	text-align: left;
}

.embed {
	margin-top: 15px;
	text-align: left;
}

.uniqueembed {
	border: 3px solid #d2d2d2;
	box-sizing: border-box;
	margin-top: 0;
	padding: 6px 7px;
	resize: none;
	width: 100%;
	line-height: 18px;
	height: 36px;
	color: #7b8084;
}

.shareemail iframe {
	border: 0 none;
	box-sizing: border-box;
	width: 102.5%;
	margin-left: -4px;
	padding: 0;
	height: 40px;
	margin-top: -5px;
}

.timelabel {
	color: #9da2a8;
	font-family: Helvetica;
	font-size: 12px;
}

.timecoder input {
	border: medium none;
	margin-left: 3px;
	margin-right: 3px;
	margin-top: 3px;
	text-align: center;
	width: 50px;
}
.timecoder {
	position: absolute;
	display: none;
}

/********* END SHARE POPUP *********/

/** Grid(The_team) and Masonry(Featured) **/
.gridContainer {
	min-height: 100px;
}

.gridHeader {
	/*min-height:74px;*/
	padding-right: 0px;
	padding-left: 0px;
	margin-top: -10px;
}

.gridName {
	/*display: inline-block;*/
	font-family: oxygen, verdana, sans-serif;
	font-size: 60px;
	font-weight: bold;
	padding-left: 0px;
	padding-right: 0px;
}

.gridDescription {
	display: inline-block;
	font-size: 16px;
	line-height: 20px;
	/*width: 590px;*/
}

.grid-cell {
	padding: 0;
}

.masonry-cell {
	border: 1px solid #585858;
	height: 0;
	/*padding-bottom: 25.2%;*/
	overflow: hidden;
}

.masonry-cell.col-lg-5c {
	padding-bottom: 100%;
}

.masonry-cell.col-lg-2c {
	padding-bottom: 60%;
}

.grid-cell img {
	width: 100%;
	min-height: 295px;
	margin-bottom: -3px;
	-webkit-filter: grayscale(100%);
	-moz-filter: grayscale(100%);
	filter: grayscale(100%);
	filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
	filter: gray;
}

.grid-cell-img-div {
	background-size: cover;
	height: 100%;
	position: absolute;
	width: 100%;
	-webkit-filter: grayscale(100%);
	-moz-filter: grayscale(100%);
	filter: grayscale(100%);
	filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
	filter: gray;
}

.masonry-cell img {
	margin-bottom: -4px;
}

.gridBody {
	padding-bottom: 15px;
	padding-left: 0;
	padding-right: 0;
	margin-left: -20px;
}

.masonryBody {
	margin-left: 0px;
	margin-top: 49px;
}

.grid-cell-info {
	bottom: 0;
	margin-bottom: 20px;
	margin-left: 19px;
	position: absolute;
	z-index: 3;
}

.masonry-cell-info {
	margin-bottom: 2px;

	top: 0;
	margin-top: 21px;
}

.grid-cell-first-name {
	font-family: oxygen, verdana, sans-serif;
	font-size: 35px;
	line-height: 29px;
}

.masonry-cell-title {
	font-size: 18px;
	font-weight: bold;
	line-height: 22px;
}

.grid-cell-position {
	color: #ff9200;
	font-family: oxygen, verdana, sans-serif;
	font-size: 20px;
}

.grid-cell-reverse-info {
	/*background-color: #bc1000;*/
	background-color: rgba(188, 16, 0, 0.8);
	bottom: 0;
	left: 0;
	padding-left: 18px;
	padding-right: 18px;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 3;
}

.masonry-bigcell-reverse-info {
	background-color: rgba(188, 16, 0, 0.8);
	bottom: 0;
	padding-left: 29px;
	padding-right: 29px;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 3;
	width: 100%;
}

.grid-cell-inactive {
	display: none;
}

.grid-cell-reverse-first-name {
	font-family: oxygen, verdana, sans-serif;
	font-size: 35px;
	line-height: 29px;
}

.grid-cell-reverse-surname {
	font-family: oxygen, verdana, sans-serif;
	font-size: 35px;
	line-height: 37px;
	word-wrap: break-word;
}

.grid-cell-reverse-position {
	color: #fa2;
	font-family: oxygen, verdana, sans-serif;
	font-size: 20px;
	margin-bottom: 20px;
}

.grid-cell-reverse-description {
	font-family: oxygen, verdana, sans-serif;
	font-size: 13px;
}

.grid-call-reverse-body {
	bottom: 19px;
	position: absolute;
	width: 88%;
}

.masonry-reverse-body {
	width: 79%;
}

.grid-cell-shadow {
	width: 100%;
	background: none repeat scroll 0% 0% transparent;
	position: absolute;
	height: 100%;
	box-shadow: 0px 0px 69px 35px rgba(0, 0, 0, 0.5) inset;
	z-index: 2;
}

.grid-cell-image {
	position: absolute;
	background-size: cover;
	filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
	height: 100%;
	position: absolute;
	width: 100%;
}

.masonry-cell-nohighlight {
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 2;
}

.grid-cell-name-shadow {
	position: absolute;
	width: 100%;
	bottom: 0px;
	height: 27%;
	background: linear-gradient(transparent, rgba(0, 0, 0, 0.8)) repeat scroll
		0px 0px transparent;
}

.teamsContainer {
	position: relative;
}

.teamsContainer + p {
	display: none;
}

.teams {
	/*float: right;*/
	overflow: hidden;
	position: static;
	z-index: 5;
	text-align: right;
}

.teamTypeSelect,
.teamSelect {
	background: url('../../../../assets/img/wx/drop-arr.png') no-repeat scroll
		7px center rgba(255, 255, 255, 1);
	border: medium none;
	padding-bottom: 5px;
	padding-left: 21px;
	padding-top: 5px;
	width: 105%;
	font-family: oxygen, verdana, sans-serif;
	font-size: 14px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	text-indent: 1px;
	text-overflow: '';
}

.teamSelect {
	margin-top: 4px;
}

.teamTypeSelect::-ms-expand,
.teamSelect::-ms-expand {
	display: none;
}

.masonry-cell-reverse-first-name {
	font-family: oxygen, verdana, sans-serif;
	font-size: 18px;
	font-weight: bold;
}

.masonry-cell-reverse-description {
	font-family: oxygen, verdana, sans-serif;
	font-size: 12px;
	margin-top: 10px;
	max-height: 47px;
	overflow: hidden;
}

.masonry-cell-reverse-categories {
	color: #ff6b00;
	font-family: Oxygen, Verdana, sans-serif;
	font-size: 14px;
	margin-top: 5px;
}

.masonry-cell-reverse-content-type {
	background-color: #484848;
	display: inline-block;
	margin-top: 10px;
	padding: 8px 11px;
}

@media (min-width: 705px) {
	.teams {
		float: right;
		margin-top: 15px;
	}

	.masonry-cell-reverse-content-type {
		margin-top: 23px;
	}
}

@media (min-width: 768px) {
	.grid-cell-reverse-first-name {
		font-size: 26px;
		line-height: 27px;
	}

	.grid-cell-reverse-surname {
		font-size: 26px;
		line-height: 26px;
	}

	.grid-cell-reverse-position {
		font-size: 16px;
	}

	.grid-cell-reverse-description {
		font-size: 12px;
	}

	.teamsContainer {
		top: 45px;
	}

	.teams {
		margin-top: 0px;
	}
}

@media (min-width: 855px) {
	.grid-cell-reverse-first-name {
		font-size: 32px;
	}

	.grid-cell-reverse-surname {
		font-size: 32px;
	}

	.grid-cell-reverse-position {
		font-size: 19px;
	}

	.grid-cell-reverse-description {
		font-size: 13px;
	}
}

@media (min-width: 960px) {
	.teamsContainer {
		right: 20px;
	}
}

@media (min-width: 992px) {
	.gridName {
		width: 35%;
	}

	.gridDescription {
		padding-top: 8px;
	}

	.masonryBody {
		margin-left: -20px;
	}

	.masonry-bigcell-reverse-info {
		width: 50%;
	}

	.masonry-cell-info {
		top: auto;
		margin-top: 0px;
	}

	.grid-cell-reverse-first-name {
		font-size: 29px;
	}

	.grid-cell-reverse-surname {
		font-size: 29px;
	}

	.grid-cell-reverse-position {
		font-size: 17px;
		margin-bottom: 5px;
	}

	.grid-cell-reverse-description {
		font-size: 12px;
	}

	.masonry-cell-reverse-first-name {
		font-size: 16px;
	}

	.masonry-cell-reverse-categories {
		font-size: 13px;
	}

	.masonry-cell.col-lg-5c {
		padding-bottom: 25.2%;
	}

	.masonry-cell.col-lg-2c {
		padding-bottom: 25.2%;
	}
}

@media (min-width: 1030px) {
	.grid-cell-reverse-first-name {
		font-size: 33px;
	}

	.grid-cell-reverse-surname {
		font-size: 33px;
	}

	.grid-cell-reverse-position {
		font-size: 18px;
	}

	.grid-cell-reverse-description {
		font-size: 12px;
	}
}

@media (min-width: 1200px) {
	.gridName {
		width: 25%;
	}

	.gridDescription {
		padding-bottom: 16px;
		padding-top: 18px;
	}

	.teamsContainer {
		top: 0px;
		right: 0px;
	}

	.teams {
		right: 20px;
		/*top: 15px;*/
		top: -5px;
		position: absolute;
		margin-top: 0px;
	}

	.teamTypeSelect,
	.teamSelect {
		width: 68%;
	}

	.grid-cell-reverse-first-name {
		font-size: 25px;
	}

	.grid-cell-reverse-surname {
		font-size: 25px;
	}

	.grid-cell-reverse-position {
		font-size: 15px;
		margin-bottom: 5px;
	}

	.grid-cell-reverse-description {
		font-size: 12px;
	}

	.masonry-cell-reverse-categories {
		margin-top: 9px;
	}

	.masonry-cell-reverse-description {
		margin-top: 5px;
		max-height: none;
		overflow: visible;
	}

	.grid-cell-reverse-mail {
		margin-top: -10px;
	}

	.masonry-cell-reverse-first-name {
		font-size: 14px;
	}

	.masonry-cell-reverse-categories {
		font-size: 13px;
	}

	.masonry-cell-reverse-content-type {
		margin-top: 10px;
	}
}

@media (min-width: 1300px) {
	.grid-cell-reverse-first-name {
		font-size: 33px;
	}

	.grid-cell-reverse-surname {
		font-size: 33px;
	}

	.grid-cell-reverse-position {
		font-size: 18px;
		margin-bottom: 10px;
	}

	.grid-cell-reverse-description {
		font-size: 13px;
	}

	.teamTypeSelect,
	.teamSelect {
		width: 75%;
	}
}

@media (min-width: 1340px) {
	.masonry-cell-reverse-first-name {
		font-size: 18px;
	}

	.masonry-cell-reverse-categories {
		font-size: 14px;
	}

	.masonry-cell-reverse-content-type {
		margin-top: 23px;
	}

	.masonry-cell-reverse-description {
		margin-top: 20px;
	}
}
/*****/

/**** Contact Us *****/
.contact-span {
	font-size: 14px;
	padding: 17px 20px;
	background-color: #152631;
	display: inline-block;
}

.contact-span input {
	border: medium none;
	font-family: Oxygen, Verdana, sans-serif;
	font-size: 14px;
	padding: 9px;
	width: 328px;
}

.contact-span textarea {
	font-family: Oxygen, Verdana, sans-serif;
	font-size: 14px;
	height: 138px;
	padding: 9px 10px;
	width: 328px;
	resize: none;
}

.contact-row.contact-title-row {
	font-size: 22px;
	padding-bottom: 14px;
}

.aboutus-title {
	margin-top: 19px;
}

.aboutus-title span {
	line-height: 40px;
}

.aboutus-phones {
	margin-top: 66px;
}

.aboutus-mail {
	margin-top: 40px;
}

.aboutus-mail > p > span {
	word-wrap: break-word;
}

.aboutus-address {
	margin-top: 42px;
}

.aboutus-mail a {
	color: #6ad3ff;
}

.aboutus-viewmap .btn {
	background-position: 12px 10px;
	height: 30px;
	padding-top: 5px;
	margin-top: 6px;
}

.aboutus-contactform {
	border: 0 none;
	height: 530px;
	width: 100%;
	margin-top: 55px;
}

.aboutus-infocolumn {
	padding-left: 0;
	padding-right: 0px;
}

.aboutus-visitus {
	margin-top: 57px;
	padding-right: 0;
	padding-left: 0px;
	/*margin-bottom: 35px;*/
}

.aboutus-bg {
	background-image: url('../../../../assets/img/wx/contactusbg.png');
}

.aboutus-row {
	padding: 0;
}

.aboutus-block .title {
	background-image: url('../../../../assets/img/wx/drop-arr-big.png');
	background-position: 0 center;
	background-repeat: no-repeat;
	padding-left: 30px;
	cursor: pointer;
}

.aboutus-block.contactus-hiden-column .title {
	background-image: url('../../../../assets/img/wx/about-arr.png');
	background-position: 2px 1px;
	background-size: 15px 19px;
}

.aboutus-visitus div .contactus-hiden-column {
	min-height: 0px;
}

.aboutus-block .title p {
	cursor: auto;
}

.aboutus-block ul li {
	color: #727272;
	list-style: outside none disc;
}

.aboutus-plan-mail {
	color: #d55a00;
}
/*** Temporal ***/
/*.aboutus-vipvisits.aboutus-block {
    min-height: 169px;
}

.aboutus-planvisit.aboutus-block {
    min-height: 366px;
}

.aboutus-courtesy.aboutus-block {
    min-height: 329px;
}*/
/**** To display in iframe ****/
.iframe-display .contact-span {
	width: 100%;
}

.iframe-display .contact-span > table {
	width: 100%;
}

.iframe-display .contact-span input {
	width: 100%;
}

.iframe-display .contact-span textarea {
	width: 100%;
}

.iframe-display .gp-player {
	width: 100%;
}

.iframe-display embed {
	width: 100%;
}

.iframe-display #video1_controls {
	width: 100% !important;
}

.aboutus-block {
	background-color: #282828;
	float: left;
	margin: 6px;
	padding: 5px 23px 10px;
	position: relative;
	width: 100%;
}

@media (min-width: 768px) {
	.aboutus-infocolumn {
		padding-right: 50px;
	}

	.aboutus-visitus {
		padding-left: 30px;
	}
}

@media (min-width: 1200px) {
	.aboutus-block {
		background-color: #282828;
		float: left;
		margin: 6px;
		padding: 5px 23px 10px;
		position: relative;
		width: 48%;
	}
}
/*****/

/**** HIDE IN IFRAME *****/
.iframe-display .wx-action-tabs {
	display: none;
}

.iframe-display #gsmHeader {
	display: none;
}

.iframe-display .gsmFooter {
	display: none;
}

.iframe-display .firstHeading {
	display: none;
}

.iframe-display #mainContent {
	padding-left: 0px;
	padding-right: 0px;
}

.iframe-display {
	overflow: hidden;
}
/*****/

/**** PARENT LAYOUT (CAT PAGE)*****/
.sr-desc-td.sr-desc-td-parent,
.sr-desc-td.sr-desc-td-parent-three {
	display: inline-block;
	background-color: transparent;
	border: none;
	margin-bottom: 21px;
}

.sr-desc-td-parent .sr-desc-img,
.sr-desc-td-parent-three .sr-desc-img {
	background-image: url('https://genocidearchiverwanda.org.rw/rga/index.php?action=ajax&rs=importImage&rsargs[]=Munyakazi.PNG');
	display: block;
	min-height: 203px;
	height: 203px;
	width: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	text-decoration: none;
	margin-bottom: 5px;
}

.sr-desc-td-parent .sr-desc-img img,
.sr-desc-td-parent-three .sr-desc-img img {
	/*display: block;
    float: none !important;
    height: 100%;
    width: 100%;*/
	display: none;
}

.sr-desc-td-parent .sr-hit-title,
.sr-desc-td-parent-three .sr-hit-title {
	font-size: 27.14px;
	display: inline-block;
	margin-top: 9px;
	line-height: 1em;
	font-weight: normal;
}

.sr-desc-td-parent-three .sr-hit-title {
	width: 100%;
	word-wrap: break-word;
}

.sr-desc-td-parent .sr-desc-title,
.sr-desc-td-parent-three .sr-desc-title {
	text-decoration: none;
}

a.sr-desc-title:hover {
	text-decoration: none;
}

.sr-desc-td-parent .sr-hit-descripction,
.sr-desc-td-parent-three .sr-hit-descripction {
	font-size: 12px;
	margin-top: 15px;
	line-height: 1.6em;
}

.sr-desc-td-parent .sr-desc-cont:hover .sr-hit-title,
.sr-desc-td-parent-three .sr-desc-cont:hover .sr-hit-title {
	color: #ffa200;
}

.sr-desc-td-parent .sr-desc-cont:hover .sr-desc-effect,
.sr-desc-td-parent-three .sr-desc-cont:hover .sr-desc-effect {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(to left, transparent, rgba(0, 0, 0, 0.8));
	background-position: 20px center, center center;
}

.sr-desc-effect {
	background-image: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.8));
	background-position: center center;
	background-repeat: no-repeat;
	height: 100%;
	width: 30%;
}
/*****/

/**** COLLECTIONS ****/
.collections-bg {
	padding-top: 60px;
	background-image: url('../../../../assets/img/wx/collections-bg.png');
	background-repeat: no-repeat;
	background-size: cover;

	margin-left: -20px;
	margin-right: -5px;
	padding-left: 20px;
	padding-right: 20px;
}

.collections-title {
	font-family: Oxygen, Verdana, sans-serif;
	font-size: 40px;
	font-weight: bold;
	line-height: 60px;
	margin-bottom: 22px;
	word-wrap: break-word;
	margin-left: -3px;
}

.collections-desc {
	font-size: 16px;
	font-weight: normal;
	margin-bottom: 7px;
}

.collections-src {
	border: medium none;
	font-family: Oxygen, Verdana, sans-serif;
	font-size: 14px;
	margin-right: 8px;
	padding: 10px 10px 11px;
	width: 165px /*211px*/;
}

@media (min-width: 368px) {
	.collections-src {
		width: 211px;
	}
}

.collections-search {
	margin-bottom: 26px;
}

.collections-adv-src {
	color: #ff4837;
}

.collections-adv-src-cont {
	background-image: url('../../../../assets/img/wx/lupa-red.png');
	background-position: 1px center;
	background-repeat: no-repeat;
	color: #ff4837;
	display: inline;
	font-size: 17px;
	padding-left: 24px;
}

.collections-adv-search {
	margin-bottom: 32px;
}

.collections-list .link {
	background-repeat: no-repeat;
	background-size: auto auto;
	display: inline-block;
	font-size: 28px;
	font-weight: bold;
	padding-top: 202px;
	margin-bottom: 8px;
	width: 100%;
}

.collections-list .link:hover {
	text-decoration: none;
	color: #ffc800;
}
.collections-audiovisual .link {
	background-image: url('../../../../assets/img/wx/audiovisual-testimonies.png');
}
.collections-audiovisual .link:hover {
	/*  background-image: url("../../../../assets/img/wx/about-arr.png"), url("audiovisual-testimonies.png"); */
	background-position: 20px 65px, 0 0;
}
#mainContent .maps-pagetitle {
	word-wrap: break-word;
}
#mainContent .maps-pagetitle {
	font-size: 44px !important;
}
.collections-maps .link {
	background-image: url('../../../../assets/img/wx/interactive maps.png');
}
.collections-maps .link:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		url('../../../../assets/img/wx/interactive maps.png');
	background-position: 20px 65px, 0 0;
}
.collections-victims .link {
	background-image: url('../../../../assets/img/wx/victims-database.png');
	background-image: url('../../../../assets/img/wx/Kibeho small.png');
}
.collections-victims .link:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		url('../../../../assets/img/wx/Kibeho small.png');
	background-position: 20px 65px, 0 0;
}
.collections-postgenocide .link {
	background-image: url('../../../../assets/img/wx/post-genocide.png');
}
.collections-postgenocide .link:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		url('../../../../assets/img/wx/post-genocide.png');
	background-position: 20px 65px, 0 0;
}

.collections-list .description {
	font-size: 14px;
	margin-bottom: 5px;
}

.collection-col {
	margin-bottom: 65px;
	padding-left: 0;
	position: static;
}

.collections-contenttype-cont {
	background-color: rgba(0, 0, 0, 0.6);
	padding: 35px 50px 45px;
	margin-left: -50px;
	margin-right: -50px;
}

.collections-contenttype-infotd {
	padding-left: 0;
}

.collections-contenttype {
	font-size: 28px;
	margin-bottom: 17px;
	margin-left: -2px;
}

.collections-contenttype-desc {
	font-size: 14px;
	margin-bottom: 15px;
}

.photos,
.videos,
.audio,
.documents {
	padding-left: 10px;
	padding-right: 0;
	margin-bottom: 5px;
}

.videos {
	margin-bottom: 7px;
}
.photos a,
.videos a,
.audio a,
.documents a {
	text-decoration: none;
}

.collections-ct-list .shadow-overlay {
	background-color: rgba(0, 0, 0, 0.5);
	display: none;
	height: 100%;
	position: absolute;
	width: 100%;
}

.photos:hover .shadow-overlay,
.videos:hover .shadow-overlay,
.audio:hover .shadow-overlay,
.documents:hover .shadow-overlay {
	display: block;
}

.photos:hover .label,
.videos:hover .label,
.audio:hover .label,
.documents:hover .label {
	padding-left: 24px;
	background-image: url('../../../../assets/img/wx/about-arr.png');
	background-position: 0 8px;
	background-repeat: no-repeat;
	background-size: 16px auto;
}

.collections-ct-list .shadow-effect {
	background: linear-gradient(transparent, rgba(0, 0, 0, 0.8)) repeat scroll 0
		0 transparent;
	height: 100%;
	position: absolute;
	width: 100%;
}

.collections-ct-list div img {
	width: 100%;
}

.collections-ct-list .label {
	bottom: 15px;
	/*font-size: 28px;*/
	left: 30px;
	position: absolute;
	font-size: 23px;
	padding-top: 2px;
	pointer-events: none;
}

.cke_editable .collections-ct-list .label {
	pointer-events: auto;
}

.collections-ct-list .white-bar {
	background-color: #a09e97;
	height: 8px;
	margin-top: -4px;
	position: relative;
}

.collections-car-item {
	/*display: table-cell;*/
	/*min-height: 247px;*/
	position: static;
	vertical-align: top;
	padding: 0;
	/*** To simulate same height columns ***/
	margin-bottom: -500px;
	padding-bottom: 500px;
}

.collections-car-item :hover .about-car-title {
	color: #daab00;
}

.collections-redone {
	background-color: #bc1000;
}
.collections-redtwo {
	background-color: #9b0d00;
}
.collections-redthree {
	background-color: #a7291e;
}
.collections-redfour {
	background-color: #7c1e16;
}

.collections-car-item .about-car-title {
	font-size: 24px;
	height: 54px;
	vertical-align: top;
}
.collections-car-item .about-car-abstract {
	font-size: 12px;
}

@media (min-width: 445px) {
	.collections-title {
		font-size: 60px;
	}
}

@media (min-width: 768px) {
	.photos,
	.videos,
	.audio,
	.documents {
		padding-left: 20px;
	}

	.collections-bg {
		padding-left: 50px;
		padding-right: 40px;
	}

	.collections-car-item-row {
		float: left;
	}

	.collection-col {
		padding-right: 60px;
	}
}

@media (min-width: 992px) {
	.collections-car-item-row {
		float: none;
	}
}
/*****/

/** Press_room **/
.pressRoomContainer {
	background-repeat: no-repeat;
	background-size: cover;
}

.pressName {
	font-family: oxygen, verdana, sans-serif;
	font-size: 60px;
	font-weight: bold;
	padding-left: 0px;
	padding-right: 0px;
	margin-bottom: 35px;
}

.pressRoomBody {
	padding: 0;
	margin-top: 10px;
}

.pressRoomRow {
	overflow: hidden;
}

.pressRoomCell {
	padding: 0 5px 10px;
}

.pressRoomCellBG {
	background-color: #282828;
}

.pressRoomCell img {
	width: 100%;
	margin-bottom: -4px;
}

.pressRoomInfo {
	width: 100%;
	display: inline-block;
	padding: 15px 25px;
	vertical-align: top;
	background-color: #282828;
}

.pressRoom-cell-title {
	font-size: 16px;
	font-weight: bold;
}

.pressRoom-cell-description {
	font-size: 12px;
	margin-top: 15px;
}

.pressRoomContentType {
	background-color: rgba(255, 255, 255, 0.6);
	color: black;
	font-size: 12px;
	font-weight: bold;
	padding: 6px 8px;
}

.press-cel-front {
	margin-bottom: -5px;
}

.pressRoomImg {
	display: inline-block;
	position: relative;
	width: 100%;
}

.pressRoomCellSlim .pressRoomImg {
	width: 100%;
}

.pressRoom-shade {
	background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8));
	height: 100%;
	position: absolute;
	width: 100%;
}

.show-rec-mobile {
	background-image: url('../../../../assets/img/wx/drop-arr-big.png');
	background-position: 5px center;
	background-repeat: no-repeat;
	background-size: 10px 8px;
	font-size: 20px;
	padding-left: 25px;
	cursor: pointer;
}

.sidebar-mobile-hidden {
	display: none;
	transition: all 0.5s ease-out 0s;
}

.sidebar-mobile-active {
	display: block;
}

@media (min-width: 992px) {
	.pressRoomContainer {
		background-image: url('../../../../assets/img/wx/PressRoomBG.png');
		margin-left: -20px;
		margin-right: -10px;
		padding-left: 20px;
		padding-right: 10px;
	}

	.sidebar-cont {
		padding-left: 0;
		padding-right: 30px;
	}

	.pressRoomRow {
		margin-bottom: 10px;
		overflow: hidden;
	}

	.pressRoomCellBG {
		padding-bottom: 500px;
		margin-bottom: -500px;
	}

	.pressRoomCell {
		padding: 0 5px;
	}

	.pressRoomCellSlim img {
		width: 100%;
	}

	.pressRoomCellSlim .pressRoomImg {
		width: 100%;
	}

	.pressRoomInfoSlim {
		width: 100%;
		padding: 5px 25px 10px;
	}

	.pressRoomInfoSlim .pressRoom-cell-description {
		margin-top: 8px;
	}

	.pressRoomBody {
		margin-top: 0px;
	}

	.show-rec-mobile {
		display: none;
	}

	.sidebar-mobile-hidden {
		display: block;
	}
}
/*** End Press_room ***/

/*** Memorial page template ***/
.memorial-page-firstcolumn {
	padding-left: 0;
	padding-right: 0px;
}

.memorial-page-secondcolumn {
	padding-left: 0px;
	padding-right: 0;
}

.memorial-description {
	font-size: 14px;
}

.memorial-seemore .btn {
	font-size: 13px;
}

.memorial-gallery {
	margin-top: 45px;
}

.memorial-gallery .owl-stage-outer {
	margin-left: 0px;
	margin-right: 0px;
}

.memorial-gallery .owl-carousel .owl-item,
.unity-gallery .owl-carousel .owl-item,
.unity-people-gallery .owl-carousel .owl-item {
	background: none;
}

.memorial-gallery-link a {
	margin-top: 0;
}

.memorial-orange {
	background-color: #d65a00;
	margin-top: 50px;
	padding: 25px;
}

.memorial-orange-text {
	padding: 0 0 0 0px;
}

.memorial-orange-title {
	font-size: 32px;
	font-weight: bold;
}

.memorial-orange-desc {
	font-size: 14px;
}

.memorial-orange-image {
	padding: 0 0px 0 0;
}

.memorial-red {
	background-color: #7c1e16;
	padding: 40px 25px 30px;
}

.memorial-red-first-column {
	padding-left: 0;
	padding-right: 0px;
}

.memorial-red-second-column {
	padding-left: 0px;
	padding-right: 0;
	margin-top: 30px;
}

.memorial-red-subsection {
	margin-bottom: 30px;
}

.memorial-red-title {
	font-size: 24.41px;
	font-weight: bold;
	margin-bottom: 15px;
	background-image: url('../../../../assets/img/wx/drop-arr-big.png');
	background-position: 0 center;
	background-repeat: no-repeat;
	cursor: pointer;
	padding-left: 30px;
}

.memorial-red-hidden {
	background-image: url('../../../../assets/img/wx/about-arr.png');
	background-position: 0 9px;
	background-size: 15px 18px;
}

.unity-people-title {
	background-image: none;
	padding-left: 0px;
}

.memorial-red-desc {
	font-size: 14px;
}

@media (min-width: 992px) {
	.memorial-page-firstcolumn {
		padding-left: 0;
		padding-right: 25px;
	}

	.memorial-page-secondcolumn {
		padding-left: 30px;
		padding-right: 15px;
	}

	.memorial-orange-text {
		padding: 0 0 0 25px;
	}

	.memorial-orange-image {
		padding: 0 25px 0 0;
	}

	.memorial-red-first-column {
		padding-left: 0;
		padding-right: 30px;
	}

	.memorial-red-second-column {
		padding-left: 30px;
		padding-right: 0;
		margin-top: 0px;
	}

	.memorial-bg {
		background-image: url('../../../../assets/img/wx/memorialBG.png');
		background-position: 0 -115px;
		background-size: cover;
		margin-left: -20px;
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 10px;
	}
}
/******/

/**** Unity and Reconciliation ****/
.unityBG {
	background-image: url('../../../../assets/img/wx/unityBG.png');
	background-repeat: no-repeat;
	background-size: cover;
	margin-left: -20px;
	margin-right: -10px;
	padding-left: 20px;
	padding-right: 10px;
	padding-top: 1px;
}

.memorial-subtitle {
	font-size: 24.41px;
	font-weight: bold;
	margin-top: 43px;
}

.unity-video-frame {
	border: 0 none;
	min-height: 390px;
	width: 100%;
}

.unity-gallery .gallery-img-overlay {
	background-color: rgba(0, 0, 0, 0.3);
	display: block;
	height: 100%;
	position: absolute;
	width: 100%;
}

.unity-gallery .memorial-gallery-link {
	display: none;
}

.unity-gallery .owl-nav .owl-prev,
.unity-gallery .owl-nav .owl-next {
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 17px 22px;
	border-radius: 0;
	color: transparent;
	margin: 0;
	opacity: 1;
	padding: 0;
	position: absolute;
	top: 42%;
}

.unity-gallery .owl-nav .owl-prev {
	background-image: url('../../../../assets/img/wx/about-arr-left.png');
	left: 5px;
}

.unity-gallery .owl-nav .owl-next {
	background-image: url('../../../../assets/img/wx/about-arr.png');
	right: 12px;
}

.unity-people-gallery .owl-stage-outer {
	margin-left: 29px;
	margin-right: 29px;
}

.unity-people {
	background-color: #25455a;
	padding: 40px 25px;
	margin-top: 55px;
}

.unit-people-text {
	padding: 0;
}

.unity-people-desc {
	font-size: 14px;
}

.unity-people-gallery {
	padding-left: 0;
	padding-right: 30px;
}

.unity-people-gallery .owl-item {
	display: inline-block;
}

.unity-people-gallery .gallery-img,
.unity-people-gallery .owl-item {
	max-height: 117px;
	overflow: hidden;
}

@media (min-width: 700px) {
	.unity-people-gallery .gallery-img,
	.unity-people-gallery .owl-item {
		max-height: 188px;
	}

	.unity-people-gallery .owl-nav .owl-prev,
	.unity-people-gallery .owl-nav .owl-next {
		height: 87%;
	}
}

@media (min-width: 1000px) {
	.unity-people-gallery .gallery-img,
	.unity-people-gallery .owl-item {
		max-height: 105px;
	}

	.unity-people-gallery .owl-nav .owl-prev,
	.unity-people-gallery .owl-nav .owl-next {
		height: 81%;
	}
}

@media (min-width: 1200px) {
	.unity-people-gallery .gallery-img,
	.unity-people-gallery .owl-item {
		max-height: 117px;
	}

	.contactus-same-height-cell {
		background-clip: padding-box;
		border: 6px solid transparent;
		border-width: 6px 11px 6px 6px;
		display: table-cell;
		float: none;
	}

	.contactus-same-height-cell.aboutus-courtesy,
	.contactus-same-height-cell.aboutus-security,
	.contactus-same-height-cell.aboutus-vipvisits {
		border-left: 1px solid transparent;
		border-right: 15px solid transparent;
	}
}

@media (min-width: 1500px) {
	.unity-people-gallery .gallery-img,
	.unity-people-gallery .owl-item {
		max-height: 133px;
	}

	.contactus-same-height-cell {
		background-clip: padding-box;
		border: 6px solid transparent;
		border-width: 6px 13px 6px 6px;
		display: table-cell;
		float: none;
	}

	.contactus-same-height-cell.aboutus-courtesy,
	.contactus-same-height-cell.aboutus-security,
	.contactus-same-height-cell.aboutus-vipvisits {
		border-left: 0px solid transparent;
		border-right: 20px solid transparent;
	}
}

@media (min-width: 1700px) {
	.unity-people-gallery .gallery-img,
	.unity-people-gallery .owl-item {
		max-height: 153px;
	}

	.unity-people-gallery .owl-nav .owl-prev,
	.unity-people-gallery .owl-nav .owl-next {
		height: 85%;
	}
}

@media (min-width: 1900px) {
	.unity-people-gallery .gallery-img,
	.unity-people-gallery .owl-item {
		max-height: 173px;
	}

	.unity-people-gallery .owl-nav .owl-prev,
	.unity-people-gallery .owl-nav .owl-next {
		height: 85%;
	}

	.contactus-same-height-cell {
		background-clip: padding-box;
		border: 6px solid transparent;
		border-width: 6px 18px 6px 6px;
		display: table-cell;
		float: none;
	}

	.contactus-same-height-cell.aboutus-courtesy,
	.contactus-same-height-cell.aboutus-security,
	.contactus-same-height-cell.aboutus-vipvisits {
		border-left: 0px solid transparent;
		border-right: 31px solid transparent;
	}
}

.unity-people-gallery .owl-theme {
	padding-left: 38px;
	padding-right: 38px;
}

.unity-people-gallery .memorial-gallery-link {
	display: none;
}

.unity-people-gallery .owl-nav .owl-prev,
.unity-people-gallery .owl-nav .owl-next {
	background-color: rgba(0, 0, 0, 0.4);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 11px 13px;
	border-radius: 0;
	color: transparent;
	height: 82%; /*90%;*/ /*106px;*/
	margin: 0;
	opacity: 1;
	padding: 0;
	position: absolute;
	/*top: 0;*/
	top: 18px;
	width: 29px;
}

.unity-people-gallery .owl-nav .owl-prev {
	background-image: url('../../../../assets/img/wx/about-arr-left.png');
	left: 0;
}

.unity-people-gallery .owl-nav .owl-next {
	background-image: url('../../../../assets/img/wx/about-arr.png');
	right: 0;
}

.unity-video {
	margin-top: 25px;
}

@media (min-width: 992px) {
	.unity-video {
		margin-top: 0px;
	}
}
/*****/

/*** User list ***/
.userListTable {
	border: 2px solid #6e6c6b;
	width: 100%;
	margin-top: 15px;
}
.userListTable tr.table-headers {
	background-color: rgb(15, 15, 15) !important;
}
.userListTable tr:nth-child(2n + 1) {
	background-color: rgb(25, 25, 25);
}
.userListTable tr:nth-child(2n) {
	background-color: rgb(35, 35, 35);
}
.userListTable .table-headers td {
	border-bottom: 2px solid #6e6c6b;
}
.userListTable td {
	border-right: 1px solid #6e6c6b;
	padding: 5px;
}
/*****/

/*** Embedded gallery ***/
.gallery-img {
	padding-right: 9px;
}

.gallery-img-overlay {
	display: none;
	cursor: pointer;
}

.gallery-img img {
	width: 100%;
	cursor: pointer;
}

.memorial-gallery .gallery-img img,
.unity-people-gallery .gallery-img img {
	-webkit-filter: grayscale(100%);
	-moz-filter: grayscale(100%);
	filter: grayscale(100%);
	filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
	filter: gray;
}

.memorial-gallery .owl-nav .owl-prev,
.memorial-gallery .owl-nav .owl-next {
	background-color: rgba(84, 84, 84, 0.7);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 11px 13px;
	border-radius: 0;
	color: transparent;
	height: 75%; /*106px;*/
	margin: 0;
	opacity: 1;
	padding: 0;
	position: absolute;
	top: 18px;
	width: 29px;
}

.memorial-gallery .owl-nav .owl-prev {
	background-image: url('../../../../assets/img/wx/about-arr-left.png');
	left: 0;
}

.memorial-gallery .owl-nav .owl-next {
	background-image: url('../../../../assets/img/wx/about-arr.png');
	right: 0;
}

.memorial-gallery .owl-theme {
	padding-left: 0px;
	padding-right: 0px;
}

.memorial-gallery .owl-controls,
.memorial-gallery .owl-nav {
	display: none !important;
}

.gallery-modal-cont {
	background-color: rgba(0, 0, 0, 0.6);
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100002;
	overflow-x: hidden;
	display: none;
}

.gallery-modal {
	background-color: black;
	left: 11%;
	position: absolute;
	top: 5%;
	width: 77%;
	padding: 30px;
}

.gallery-modal .gall-cont {
	margin-top: 0px;
	margin-bottom: -50px;
}

.gall-cont {
	/*margin-bottom: -50px;*/
	margin-top: 40px;
}

.gall-metaauthor.gall-metauthor-first {
	display: inline-block;
	margin-left: 0;
}

.gall-metaauthor {
	display: block;
	margin-left: 60px;
}

.gall-metatitle {
	font-weight: bold;
	display: inline;
}

.gall-photometa {
	display: inline;
}

.imgdescriptions .gda-metatitle {
	vertical-align: top;
}

@media (min-width: 790px) {
	.memorial-gallery .owl-controls,
	.memorial-gallery .owl-nav {
		display: block !important;
	}

	.memorial-gallery .owl-theme {
		padding-left: 38px;
		padding-right: 38px;
	}

	.memorial-gallery .owl-stage-outer {
		margin-left: 30px;
		margin-right: 30px;
	}
}

@media (min-width: 790px) {
	.memorial-gallery .owl-nav .owl-prev,
	.memorial-gallery .owl-nav .owl-next {
		height: 76%;
	}
}

@media (min-width: 865px) {
	.memorial-gallery .owl-nav .owl-prev,
	.memorial-gallery .owl-nav .owl-next {
		height: 78%;
	}
}

@media (min-width: 981px) {
	.memorial-gallery .owl-nav .owl-prev,
	.memorial-gallery .owl-nav .owl-next {
		height: 81%;
	}
}

@media (min-width: 992px) {
	.memorial-gallery .owl-nav .owl-prev,
	.memorial-gallery .owl-nav .owl-next {
		height: 64%;
	}
}

@media (min-width: 1200px) {
	.memorial-gallery .owl-nav .owl-prev,
	.memorial-gallery .owl-nav .owl-next {
		height: 75%;
	}
}

@media (min-width: 1465px) {
	.memorial-gallery .owl-nav .owl-prev,
	.memorial-gallery .owl-nav .owl-next {
		height: 75%;
	}
}

@media (min-width: 1665px) {
	.memorial-gallery .owl-nav .owl-prev,
	.memorial-gallery .owl-nav .owl-next {
		height: 76%;
	}
}

@media (min-width: 1865px) {
	.memorial-gallery .owl-nav .owl-prev,
	.memorial-gallery .owl-nav .owl-next {
		height: 82%;
	}
}

@media (min-width: 2000px) {
	.memorial-gallery .owl-nav .owl-prev,
	.memorial-gallery .owl-nav .owl-next {
		height: 82%;
	}
}
/*** End embedded gallery ***/
/*** Gallery ***/
/* .gall-info {
	/*margin-bottom: 27px;* /
} */

.gall-title {
	font-size: 30px;
}

.gall-desc {
	font-size: 14px;
	/*margin-bottom: 27px;*/
	margin-top: 18px;
	min-height: 49px;
	margin-bottom: 5px;
}

.gall-place,
.gall-authors {
	font-size: 14px;
}

.gall-close {
	background: url('../../../../assets/img/wx/mainpage-vid-close.png')
		no-repeat scroll center center rgba(0, 0, 0, 0);
	color: transparent;
	cursor: pointer;
	display: inline-block;
	font-family: Verdana;
	font-size: 3em;
	text-align: center;
	vertical-align: top;
	width: 53px;
	/* float: right; */
	/*margin-top: 18px;*/
	margin-bottom: 27px;
	display: none;
}

.gallery-modal-cont .gall-close {
	display: inline-block;
}

.gall-button-bar {
	margin-top: 15px;
}

.gall-button-bar .sec2 {
	margin-left: -2px;
}

.btn-download {
	/* background: url('../../../../assets/img/wx/download-icon.png') no-repeat
		scroll 13px 8px #1e4c69; */
	margin-left: 15px;
	margin-top: 0;
	padding-left: 35px;
	padding-right: 15px;
	display: none;
}

.carouseldisplay-arr-left,
.carouseldisplay-arr-right {
	background-repeat: no-repeat;
	background-size: 29px 38px;
	height: 38px;
	position: absolute;
	/*top: 35%;*/
	top: 15%;
	width: 29px;
	cursor: pointer;
}

.carouseldisplay-arr-left {
	left: 10px;
	background-image: url('../../../../assets/img/wx/about-arr-left.png');
}

.carouseldisplay-arr-right {
	right: 10px;
	background-image: url('../../../../assets/img/wx/about-arr.png');
}

@media (min-width: 400px) {
	.carouseldisplay-arr-left,
	.carouseldisplay-arr-right {
		top: 20%;
	}

	.about-car-title {
		font-size: 30px;
	}

	.pressRoomImg {
		width: 44%;
	}

	.pressRoomCellSlim .pressRoomImg {
		width: 44%;
	}

	.pressRoomInfo {
		width: 56%;
	}
}

@media (min-width: 500px) {
	.carouseldisplay-arr-left,
	.carouseldisplay-arr-right {
		top: 25%;
	}
}

@media (min-width: 600px) {
	.carouseldisplay-arr-left,
	.carouseldisplay-arr-right {
		top: 35%;
	}
	#mainContent .maps-pagetitle {
		font-size: 60px !important;
	}
}

@media (min-width: 992px) {
	.pressRoomCellSlim .pressRoomImg {
		width: 100%;
	}
	.pressRoomInfoSlim {
		width: 100%;
	}
}
/*** End gallery ***/

/*** Embeded GSM ***/
.memorial-video-frame {
	min-height: 395px;
	width: 100%;
	margin-top: 15px;
}

.videoframe-display {
	background-color: transparent;
}

.videoframe-display .breadcrumbs,
.videoframe-display .catfirstHeading,
.videoframe-display .sidebar,
.videoframe-display .browse-main,
.videoframe-display .stylers,
.videoframe-display .metatable,
.videoframe-display #dock.gp-dock {
	display: none;
}
/*** End Embeded GSM***/

.maximized-creator {
	z-index: 1000000 !important;
}

.column-centered > p,
.column-centered-big > p {
	margin-top: 1.2em !important;
}

.mainpage-carousel .owl-item .mainpage-car-text {
	opacity: 0;
}
.mainpage-carousel .owl-item.active .mainpage-car-text {
	opacity: 1;
}

.mainpage-carousel .owl-next {
	/*  background: url("main-carousel-right.png") no-repeat scroll center center rgba(0, 0, 0, 0) !important; */
	height: 50px;
	position: absolute;
	right: 0;
	top: 45%;
	width: 40px;
	cursor: pointer;
	opacity: 0.4;
	display: none;
}

.mainpage-carousel .owl-prev {
	/*  background: url("main-carousel-left.png") no-repeat scroll center center rgba(0, 0, 0, 0) !important; */
	height: 50px;
	position: absolute;
	left: 0;
	top: 45%;
	width: 40px;
	cursor: pointer;
	opacity: 0.4;
	display: none;
}

.mainpage-carousel {
	margin-left: -10px;
	position: relative;
}

.mainpage-latest-news-title {
	background: none repeat scroll 0 0 white;
	display: inline-block;
	color: black;
	font-size: 20px;
	padding: 5px;
}

.mainpge-newsl-item-date {
	color: #a6a6a6;
	font-size: 15px;
}

.mainpge-newsl-item-title {
	font-size: 18px;
	font-weight: bold;
	margin-top: 12px;
}

.mainpage-latest-news-title {
	background: none repeat scroll 0 0 white;
	color: black;
	font-size: 20px;
	padding: 5px;
}

.mainpge-newsl-item-date {
	color: #a6a6a6;
	display: inline-block;
	font-size: 20px;
	margin-left: 10px;
}

.mainpge-newsl-item-title {
	font-size: 18px;
	font-weight: bold;
}

.mainpge-newsl-item-social-cont {
	margin-top: 35px;
}

.mainpge-newsl-item-social-cont a:hover {
	text-decoration: none;
}

.mainpge-newsl-item-fb {
	background-image: url('../../../../assets/img/wx/ourfb-hov.png');
	background-repeat: no-repeat;
	width: 7px;
	display: inline-block;
	height: 18px;
}

.mainpge-newsl-item-yt {
	background-image: url('../../../../assets/img/wx/ouryt-hov.png');
	background-repeat: no-repeat;
	width: 13px;
	display: inline-block;
	height: 18px;
	margin-left: 19px;
}

.mainpge-newsl-item-tw {
	background-image: url('../../../../assets/img/wx/ourtw-hov.png');
	background-repeat: no-repeat;
	background-position: 0 2px;
	width: 16px;
	display: inline-block;
	height: 18px;
	margin-left: 18px;
}

.mainpge-newsl-item-gp {
	background-image: url('../../../../assets/img/wx/ourg+-hov.png');
	background-repeat: no-repeat;
	background-position: 0 2px;
	width: 16px;
	display: inline-block;
	height: 18px;
	margin-left: 18px;
}

.mainpge-newsl-item-pi {
	background-image: url('../../../../assets/img/wx/ourpin-hov.png');
	background-repeat: no-repeat;
	width: 19px;
	display: inline-block;
	height: 18px;
	margin-left: 17px;
}

.mainpge-newsl-item-in {
	background-image: url('../../../../assets/img/wx/ourinst-hov.png');
	background-repeat: no-repeat;
	background-position: 0 1px;
	width: 17px;
	display: inline-block;
	height: 18px;
	margin-left: 16px;
}

.mainpage-news-landing-recent-replace {
	margin-top: 15px;
}

.news-landing-recent-replace {
	margin-top: 0px;
}

@media (min-width: 768px) {
	.news-landing-recent-replace {
		margin-top: 15px;
	}
}

@media (min-width: 992px) {
	.mainpage-news-landing-recent-replace {
		position: absolute;
		right: 5%;
		top: 200px;
	}

	.mainpge-newsl-item-date {
		font-size: 15px;
		display: block;
		margin-left: 0px;
	}

	.mainpage-latest-arrows {
		position: relative;
		margin-top: 10px;
		min-height: 17px;
		display: block;
	}

	.mainpage-latest-next {
		background: url('../../../../assets/img/wx/mainpage-news-next.png')
			repeat scroll 0 0 transparent;
		height: 17px;
		width: 17px;
		/*position:absolute;*/
		display: inline-block;
		cursor: pointer;
	}

	.mainpage-latest-back {
		background: url('../../../../assets/img/wx/mainpage-news-back.png')
			repeat scroll 0 0 transparent;
		height: 17px;
		width: 17px;
		position: absolute;
		display: inline-block;
		right: 20px;
		cursor: pointer;
	}

	.mainpge-newsl-item-title {
		max-width: 177px;
		margin-top: 0;
	}
}

/* Owl responsive arrows*/
.mainpage-carousel .owl-prev,
.mainpage-carousel .owl-next {
	top: 17%;
}

/* Namespace Page */
.wx-title2.namespace-report {
	word-wrap: break;
	font-size: 57.2px;
	font-weight: normal;
	line-height: 57.2px;
}

.registered-only {
	bottom: 0;
	color: #6ad3ff;
	text-align: right;
}

.confirmed-only {
	bottom: 0;
	color: #bc1000;
	text-align: right;
}

.tools ul li span.nobtn.numeric,
.tools ul li span.total_pages {
	font-size: 13px !important;
}

@media (min-width: 400px) {
	.mainpage-carousel .owl-prev,
	.mainpage-carousel .owl-next {
		top: 24%;
	}
}

@media (min-width: 470px) {
	.mainpage-carousel .owl-prev,
	.mainpage-carousel .owl-next {
		top: 32%;
	}
}

@media (min-width: 600px) {
	.mainpage-carousel .owl-prev,
	.mainpage-carousel .owl-next {
		top: 41%;
	}
}

@media (min-width: 680px) {
	.mainpage-carousel .owl-prev,
	.mainpage-carousel .owl-next {
		top: 53%;
		display: block;
	}
}

@media (min-width: 768px) {
	.mainpage-mobile-col-1 {
		display: inline;
		padding-right: 0;
		width: auto;
	}

	.mainpage-searchtxt {
		width: 212px;
	}

	.mainpage-mobile-col-2 {
		display: inline;
	}

	#userlogin2 table {
		width: 50%;
	}

	.mainpage-carousel .owl-prev,
	.mainpage-carousel .owl-next {
		top: 57%;
	}
}

@media (min-width: 830px) {
	.mainpage-carousel .owl-prev,
	.mainpage-carousel .owl-next {
		top: 64%;
	}
}

@media (min-width: 960px) {
	.login-btn-cont,
	.logout-btn-cont,
	.register-btn-cont {
		display: block;
	}

	.sr-desc-td-parent .sr-hit-title,
	.sr-desc-td-parent-three .sr-hit-title {
		font-size: 33.14px;
	}

	.mainpage-carousel .owl-prev,
	.mainpage-carousel .owl-next {
		top: 64%;
	}
}

@media (min-width: 992px) {
	.topMenu-current:after {
		border-left: 20px solid transparent;
		border-right: 20px solid transparent;
		border-top: 12px solid white;
		content: '';
		height: 10px;
		position: absolute;
		/*top: 10px;*/
		width: 10px;
		/*transform: translate(-43%, 1px);*/
		transform: translate(-14px, 9px);
		-ms-transform: translate(-14px, 9px);
		left: 50%;
	}

	.mainpage-title {
		margin-top: 115px;
	}

	.mainpage-description {
		margin-top: 0px;
	}

	.mainpage-description + p {
		margin-top: 1em;
	}

	.mainpage-search {
		margin-top: 38px;

		border-bottom: 0;
		padding-bottom: 0;
		margin-bottom: 0;
	}
}

@media (min-width: 643px) {
	.iframe-display .gp-player {
		width: 115%;
	}

	.iframe-display embed {
		height: 444px;
	}
}

@media (min-width: 1400px) {
	.unity-video-frame {
		min-height: 515px;
	}
}

@media (min-width: 743px) {
	.iframe-display .gp-player {
		width: 140%;
	}

	.iframe-display embed {
		height: 522px;
	}
}

@media (min-width: 1600px) {
	.unity-video-frame {
		min-height: 593px;
	}
}

@media (min-width: 843px) {
	.iframe-display .gp-player {
		width: 157%;
	}

	.iframe-display embed {
		height: 596px;
	}
}

@media (min-width: 1800px) {
	.unity-video-frame {
		min-height: 662px;
	}
}

@media (min-width: 325px) {
	.memorial-seemore .btn {
		font-size: 14px;
	}
}

@media (min-width: 922px) {
	.iframe-display .gp-player {
		width: 165%;
	}

	.iframe-display embed {
		height: 624px;
	}
}

@media (min-width: 2000px) {
	.unity-video-frame {
		min-height: 690px;
	}
}

@media (min-width: 980px) {
	.mainpage-carousel .owl-prev,
	.mainpage-carousel .owl-next {
		top: 58%;
	}
}

@media (min-width: 1200px) {
	.mainpage-carousel .owl-prev,
	.mainpage-carousel .owl-next {
		top: 60%;
	}

	.wx-search-header #wx-sh-fulltext:focus {
		width: 315px;
	}
}

@media (min-width: 1366px) {
	.mainpage-carousel .owl-prev,
	.mainpage-carousel .owl-next {
		top: 63%;
	}
}

@media (min-width: 1600px) {
	.mainpage-carousel .owl-prev,
	.mainpage-carousel .owl-next {
		top: 65%;
	}

	.wx-search-header #wx-sh-fulltext:focus {
		width: 415px;
	}
}

@media (min-width: 1800px) {
	.mainpage-carousel .owl-prev,
	.mainpage-carousel .owl-next {
		top: 68%;
	}

	.wx-search-header #wx-sh-fulltext:focus {
		width: 495px;
	}
}

.btn.blue-btn.document-more {
	bottom: 10px;
	display: inline-block;
	position: absolute;
	vertical-align: bottom;
}

.collections-list {
	position: relative;
}

@media (min-width: 1200px) {
	.collections-list .btn.blue-btn {
		bottom: 20px;
		position: absolute;
	}
}

.collection-col .btn.blue-btn {
	bottom: 0;
	position: absolute;
}
@media (max-width: 1199px) {
	.collection-col {
		padding-bottom: 50px;
		position: relative;
	}
}

@media (min-width: 1200px) {
	.collection-col .btn.blue-btn {
		bottom: 20px;
		position: absolute;
		margin-left: -3px;
	}
}

.sr-desc-td .sr-hit-title {
	color: #ff9201;
}

.sr-desc-tr-parent .sr-hit-title,
.sr-desc-td-parent-three .sr-hit-title {
	color: white;
}

@media (min-width: 992px) {
	.about-car-text .btn.btn-yellow {
		bottom: 20px;
		position: absolute;
	}
	.about-car-text {
		/*padding-bottom:70px;*/
		padding-bottom: 105px;
		padding-left: 49px;
		padding-right: 49px;
	}

	.about-car-item .btn {
		position: absolute;
	}

	.about-car-item {
		display: table-cell;
		float: none;
	}
}

.young-peace-description > p {
	display: block;
	height: 70px;
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
}

/*** one column big ***/
.column-centered-big-title {
	font-size: 25px;
	font-weight: bold;
	margin-bottom: 20px;
	margin-top: 5px;
}

.column-centered-big .left-column-img,
.column-centered-big .right-column-img {
	text-align: center;
}

.column-centered-big .left-column-img > img,
.column-centered-big .right-column-img > img {
	max-width: 100%;
}

.column-centered-row {
	margin-top: 5px;
}

@media (min-width: 748px) {
	.column-centered-big .right-column {
		display: inline-block;
		margin-left: 1%;
		vertical-align: top;
		width: 48.5%;
	}

	.column-centered-big .left-column {
		display: inline-block;
		margin-right: 1%;
		vertical-align: top;
		width: 49%;
	}

	.column-centered-big .left-column-img {
		display: inline-block;
		margin-right: 1%;
		width: 49%;
	}

	.column-centered-big .right-column-img {
		display: inline-block;
		margin-left: 1%;
		width: 49%;
		/* float: right; */
	}
}
/*******/

/**Editor btns **/
.cke_widget_wrapper:hover > .cke_widget_element {
	outline: none !important;
	/*border: 2px solid yellow;*/
}

.cke_widget_wrapper.cke_widget_focused > .cke_widget_element,
.cke_widget_wrapper .cke_widget_editable.cke_widget_editable_focused {
	outline: none !important;
	/*border: 2px solid #ace;*/
}

.wx-template {
	background: none repeat scroll 0 0 #9a9a9a;
	border: 1px dashed black;
	padding: 5px;
}

.wx-template-xml {
	display: none;
}

.edit-metadata {
	margin-bottom: 10px;
}
.blue-button {
	background-color: #4d90fe;
	background-image: linear-gradient(center top, #4d90fe, #4787ed);
	background-image: -moz-linear-gradient(center top, #4d90fe, #4787ed);
	border: 1px solid #3079ed;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	font-family: Arial;
	font-style: normal;
	font-weight: 700;
	height: 30px;
	line-height: 27px;
	padding: 0 8px;
	text-align: center;
	vertical-align: top;
	display: inline-block;
	font-size: 12px;
}

.blue-button:hover {
	background-color: #357ae8;
	background-image: linear-gradient(center top, #4d90fe, #357ae8);
	background-image: -moz-linear-gradient(center top, #4d90fe, #357ae8);
	border: 1px solid #2f5bb7;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
	outline: medium none;
}

.red-button {
	background-color: #d14836;
	background-image: linear-gradient(center top, #dd4b39, #d14836);
	background-image: -moz-linear-gradient(center top, #dd4b39, #d14836);
	border: 1px solid transparent;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	font-family: Arial;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	height: 28px;
	line-height: 29px;
	padding: 0 8px;
	text-align: center;
	vertical-align: top;
	display: inline-block;
}

.red-button:hover {
	background-color: #c53727;
	background-image: linear-gradient(center top, #dd4b39, #c53727);
	background-image: -moz-linear-gradient(center top, #dd4b39, #c53727);
	border: 1px solid #b0281a;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
	outline: medium none;
	text-decoration: none;
}

.gray-button {
	background-color: #555555;
	background-image: linear-gradient(center top, #555555, #505050);
	background-image: -moz-linear-gradient(center top, #555555, #505050);
	border: 1px solid transparent;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	font-family: Arial;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	height: 30px;
	line-height: 29px;
	padding: 0 8px;
	text-align: center;
	vertical-align: top;
	display: inline-block;
}

.gray-button:hover {
	background-color: #505050;
	background-image: linear-gradient(center top, #505050, #454545);
	background-image: -moz-linear-gradient(center top, #505050, #454545);
	border: 1px solid #b0281a;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
	outline: medium none;
	text-decoration: none;
}

.wxInsertImageDialogIframe {
	min-height: 400px;
	width: 100% !important;
}

.cke_dialog_contents {
	background-color: #fcfcfc !important;
}

.cke_dialog_contents_body {
	padding: 10px !important;
}

.ui-autocomplete {
	z-index: 10010;
}

.editTitle {
	border: 0 none;
	color: #272c37;
	font-family: Arial;
	font-size: 1em !important;
	padding: 0;
	font-weight: bold;
	/*line-height: 1em;*/
	width: 100%;
}

.create-options {
	background: none repeat scroll 0 0 black;
	border: 1px solid #444444;
	box-shadow: 1px 1px 2px 0 #333;
	position: absolute;
	display: none;
	padding: 5px;
	top: 35px;
}

.create-news-final,
.create-category-final {
	background: none repeat scroll 0 0 black;
	border: 1px solid #444444;
	position: absolute;
	display: none;
	padding: 10px;
	top: 35px;
}

.createNewsTitle,
.createCategoryTitle {
	position: relative;
	margin-bottom: 10px;
	width: 250px;
	z-index: 21;
}

.create-type-title {
	margin-bottom: 10px;
}

.chooseThumbnail {
	background: url('../../../../assets/img/wx/advancedimage.png') no-repeat
		scroll center center white;
	cursor: pointer;
	display: inline-block;
	height: 22px;
	vertical-align: top;
	width: 22px;
	border-color: #bababa #bababa #bababa -moz-use-text-color;
	border-image: none;
	border-style: solid solid solid none;
	border-width: 1px 1px 1px medium;
}

.cke_editable {
	background: none repeat scroll 0 0 black;
	cursor: text;
	height: 100%;
	/*font-size: 12.8px;
    color: #272c37;	*/
}

.control input {
	height: 18px;
	width: 325px;
}

/* figcaption {
	caption-side: bottom;
	display: table-caption;
} */

.thumbinner {
	width: 100% !important;
}

.thumb.tright {
	max-width: 100%;
}

/* blockquote {
	font-style: italic;
} */

@media (min-width: 480px) {
	/* figure[style*='float:left'] {
		margin-right: 15px;
	}

	figure[style*='float:right'] {
		margin-left: 15px;
	} */
}
/* figure, */
.thumbcaption {
	/*width: 100%;*/
	table-layout: fixed;
	display: table;
	color: #666666;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 11px !important;
	font-weight: bold;
	line-height: 1.1em;
	text-align: left;
	margin: 0px;
}

img[style*='float:right'],
.cke_widget_wrapper[style*='float:right'],
.cke_widget_wrapper[style*='float: right;'] {
	margin-left: 15px;
}

img[style*='float:left'],
.cke_widget_wrapper[style*='float:left'],
.cke_widget_wrapper[style*='float: left;'] {
	margin-right: 15px;
}

/* @media (max-width: 480px) {
	figure {
		margin: 0;
		width: 100%;
		margin-top: 10px;
		margin-bottom: 10px;
	}
} */

.cke_editable img,
.cke_editable figure {
	max-width: 100%;
}

.control > textarea {
	min-height: 150px;
	width: 100%;
}

.authorinput {
	margin-bottom: 0px;
	margin-left: 0px;
	width: 327px;
}
/** Editor End**/

/** Special user list **/
.specialTitle {
	font-family: Oxygen, Verdana, sans-serif;
	font-size: 60px;
	font-weight: bold;
	line-height: 60px;
	margin-bottom: 25px;
	word-wrap: break-word;
	margin-top: 25px;
}
/** Special user list END **/

/** GLIFOS hideable segment **/
.glifos-hideable-title {
	background-image: url('../../../../assets/img/wx/drop-arr-big.png');
	background-position: 0 center;
	background-repeat: no-repeat;
	cursor: pointer;
	/*margin-bottom: 15px;*/
	padding-left: 30px;
	font-size: 24.41px;
	font-weight: bold;
	display: inline-block;
}

.glifos-hideable-title.glifos-hideable-hidden {
	background-image: url('../../../../assets/img/wx/about-arr.png');
	background-position: 3px 10px;
	background-size: 15px 18px;
}

.glifos-hideable-title-text p {
	margin: 0;
}
/** GLIFOS hideable segment END**/

/** Blog template **/
.bt-cont {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	font-family: Verdana;
	margin-top: 45px;
}

.bt-cont-normal {
	margin-top: 0px;
}

.bt-main-title {
	font-family: Verdana;
	font-size: 60px;
	font-weight: bold;
	margin-bottom: 15px;
	/*margin-left: 15px;*/
	margin-top: 15px;
}

.bt-cont-normal .bt-main-title {
	font-family: Oxygen, Verdana;
	margin-top: 62px;
}

.bt-categories {
	color: #9d9d9d;
	/*font-family: Verdana;*/
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	display: none;
}

.bt-title {
	/*font-family: Verdana;*/
	font-size: 40px;
	font-weight: bold;
}

.bt-date {
	color: #9d9d9d;
	/*display: inline-block;*/
	font-size: 18px;
	margin-top: 15px;
	text-transform: uppercase;
	vertical-align: top;
	width: 100%;
	padding-bottom: 12px;
	display: none;
}

.bt-share {
	display: inline-block;
	margin-top: 15px;
	/*text-align: right;*/
	width: 100%;
	padding-bottom: 12px;
}

.bt-body {
	font-size: 16px;
	line-height: 24px;
	padding-top: 12px;
}

.bt-sidebar {
	background-color: #484848;
	display: inline-block;
	float: none;
	padding: 15px;
	margin-top: 20px;
}

.bt-sidebar-cont {
	display: inline-block;
	float: none;
	padding: 12px 0 0;
	margin-right: -4px;
}

.bt-sidebar-cont .bt-sidebar {
	width: 100%;
}

.bt-sb-title {
	display: inline-block;
	font-size: 24px;
	font-weight: normal;
	margin-bottom: 20px;
}

.bt-sb-link a {
	background: url('../../../../assets/img/wx/browse-arr.png') no-repeat scroll
		95% center #727272;
	cursor: pointer;
	display: block;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 5px;
	padding: 10px;
	/* vertical-align: middle; */
}

.bt-sb-link a:hover {
	background-color: #616161;
}

.bt-page {
	display: inline-block;
	float: none;
	padding-left: 15px;
	vertical-align: top;
	margin-top: 30px;
}

.bt-page img,
.bt-cont img {
	max-width: 100%;
}

.bt-share div a {
	width: 100%;
	height: 100%;
	display: inline-block;
	text-decoration: none;
}

/** campos repetibles gda **/
.gx_root .opslist .item {
	display: inline-block;
	margin-bottom: 5px;
}

.gx_root .moreinfocont {
	display: inline-block;
	margin-left: 5px;
}

.gx_root .moreinfo {
	cursor: pointer;
}

.gx_root .moreinfodiv {
	background: white none repeat scroll 0 0;
	border: 1px solid #bbbbbb;
	display: none;
	font-size: 12px;
	margin-left: 20px;
	padding: 5px;
	position: absolute;
	text-align: justify;
	width: 200px;
	z-index: 100;
	color: black;
}

.gx_root .opslist > span {
	display: block;
}
/******/

@media (min-width: 420px) {
	.bt-date {
		width: 48%;
	}
	.bt-share {
		/*width: 48%;*/
		text-align: right;
	}
}

@media (min-width: 768px) {
	.bt-cont {
		width: 74%;
	}

	/* .bt-main-title {
		/*margin-left: 15px;* /
	} */
}

@media (min-width: 992px) {
	.bt-page {
		padding-left: 60px;
		margin-top: 0px;
	}
}
/** Blog template END **/

/** Mobile Category **/
.category-body#mainContent {
	padding-left: 0;
	padding-right: 0;
}

.category-body .sr-desc-td {
	padding-left: 0px;
	padding-right: 0px;
}

/*.category-body > .col-md-9{
	padding-left: 0px;
	padding-right: 0px;
}*/

@media (min-width: 960px) {
	.category-body#mainContent {
		padding-left: 20;
		padding-right: 5;
	}

	.sr-desc-cont {
		padding-right: 15px;
	}

	.category-body .sr-desc-td {
		padding-left: 10px;
		padding-right: 10px;
	}

	/*.category-body > .col-md-9{
		padding-left: 15px;
		padding-right: 15px;
	}*/
}
/*****/

/*add your css rules in this file*/
/*** Post genocide image links  ***/
.post-gacaca {
	background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('https://genocidearchiverwanda.org.rw/rga/images/9/9d/GACACA.png');
}

.post-gacaca:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(to left, transparent, rgba(0, 0, 0, 0.4)),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('https://genocidearchiverwanda.org.rw/rga/images/9/9d/GACACA.png');
}

.post-ndi {
	background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('https://genocidearchiverwanda.org.rw/rga/images/2/23/Ndi_Umunyarwanda.jpg');
}

.post-ndi:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(to left, transparent, rgba(0, 0, 0, 0.4)),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('https://genocidearchiverwanda.org.rw/rga/images/2/23/Ndi_Umunyarwanda.jpg');
}

.post-tig {
	background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('../../../../assets/img/wx/post-TIG.png');
}

.post-tig:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(to left, transparent, rgba(0, 0, 0, 0.4)),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('../../../../assets/img/wx/post-TIG.png');
}

.post-ingando {
	background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('https://genocidearchiverwanda.org.rw/rga/images/f/fd/Ingando.jpg');
}

.post-ingando:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(to left, transparent, rgba(0, 0, 0, 0.4)),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('https://genocidearchiverwanda.org.rw/rga/images/f/fd/Ingando.jpg');
}

/*** Young people and peace building image links  ***/
.yp-blog {
	background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('../../../../assets/img/wx/yp-blog.png');
}

.yp-blog:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('../../../../assets/img/wx/yp-blog.png');
}

.yp-messages {
	background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('../../../../assets/img/wx/yp-messages.png');
}

.yp-messages:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('../../../../assets/img/wx/yp-messages.png');
}

.yp-art {
	background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('../../../../assets/img/wx/yp-artwork.png');
}

.yp-art:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('../../../../assets/img/wx/yp-artwork.png');
}

.yp-poems {
	background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('../../../../assets/img/wx/yp-poems.png');
}

.yp-poems:hover {
	background-image: url('../../../../assets/img/wx/about-arr.png'),
		linear-gradient(transparent, rgba(0, 0, 0, 0.8)),
		url('../../../../assets/img/wx/yp-poems.png');
}

/**MOVE TO MAIN**/
.sr-adv-title.sr-adv-title-alone {
	width: 100%;
}

.advancedsearch-box .header-search-box {
	border: 0 none;
	color: #939598;
	font-family: Verdana;
	font-size: 14px;
	height: 36px;
	margin-top: 0;
	padding-left: 5px;
	padding-top: 2px;
	vertical-align: top;
	width: 80%;
	width: calc(100% - 60px);
}

.advancedsearch-box {
	background: none repeat scroll 0 0 #484848;
	display: inline-block;
	padding: 20px;
	max-width: 100%;
	margin-bottom: 200px;
}

.advancedsearch-container-title {
	color: white;
	font-size: 18px;
	font-weight: normal;
	margin-bottom: 10px;
	vertical-align: middle;
}

.advancedsearch-filter {
	background: none repeat scroll 0 0 #727272;
	color: white;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 5px;
	margin-right: 10px;
	padding: 10px;
	vertical-align: middle;
	transition: all 0.4s;
}

.advancedsearch-container.advancedsearch-container-half {
	display: inline-block;
	box-sizing: border-box;
	padding-right: 85px;
	width: 50%;
	min-width: 400px;
}

.advancedsearch-container {
	margin-top: 38px;
}

.advancedsearch-container select {
	max-width: 100%;
}

.mainpage-latest-news-title > a {
	color: black;
}
