.item {
    padding: 40px;

    .title {
        display: flex;
        align-items: center;
        height: 110px;
        font-size: 30px;
    }

    @media (min-width: 992px) {
        /* padding-bottom: 70px; */
        padding: 40px;
    }
}