.gridBody{
	padding-bottom: 15px;
    padding-left: 0;
    padding-right: 0;
	margin-left:-20px;
    a{
        color: white;
        text-decoration: none;
    }
}
.col-xs-5c, .col-sm-5c, .col-md-5c, .col-lg-5c, .col-xs-2c, .col-sm-2c, .col-md-2c, .col-lg-2c{
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}
.masonryBody{
	margin-left: 0px;
	margin-top: 49px;
}

.grid-cell{
	padding:0;
}

.grid-cell img{
	width:100%;
	min-height: 295px;
	margin-bottom:-3px;
	-webkit-filter:grayscale(100%);
	-moz-filter:grayscale(100%);
	filter:grayscale(100%);
	filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
	filter: gray;
}

.grid-cell-img-div {
	background-size: cover;
	height: 100%;
	position: absolute;
	width: 100%;
	-webkit-filter:grayscale(100%);
	-moz-filter:grayscale(100%);
	filter:grayscale(100%);
	filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
	filter: gray;
}

.grid-cell-info{
	bottom: 0;
    margin-bottom: 20px;
    margin-left: 19px;
    position: absolute;
	z-index: 3;
}

.grid-cell-first-name {
    font-family: oxygen,verdana,sans-serif;
    font-size: 35px;
    line-height: 29px;
}

.grid-cell-position {
    color: #ff9200;
    font-family: oxygen,verdana,sans-serif;
    font-size: 20px;
}

.grid-cell-reverse-info {
    /*background-color: #bc1000;*/
	background-color:rgba(188, 16, 0, 0.8);
    bottom: 0;
    left: 0;
    padding-left: 18px;
    padding-right: 18px;
    position: absolute;
    right: 0;
    top: 0;
	z-index:3;
}

.grid-call-reverse-body {
    bottom: 19px;
    position: absolute;
    width: 88%;
}

.masonry-reverse-body{
	width: 79%;
}

.grid-cell-inactive{
	display: none;
}

.grid-cell-reverse-first-name {
    font-family: oxygen,verdana,sans-serif;
    font-size: 35px;
    line-height: 29px;
}

.grid-cell-reverse-surname {
    font-family: oxygen,verdana,sans-serif;
    font-size: 35px;
	line-height: 37px;
	word-wrap: break-word;
}

.grid-cell-reverse-position {
    color: #fa2;
    font-family: oxygen,verdana,sans-serif;
    font-size: 20px;
	margin-bottom: 20px;
}

.grid-cell-reverse-description {
    font-family: oxygen,verdana,sans-serif;
    font-size: 13px;
}

.grid-cell-shadow{
	width: 100%; background: none repeat scroll 0% 0% transparent;
	position: absolute;
	height: 100%;
	box-shadow: 0px 0px 69px 35px rgba(0, 0, 0, 0.5) inset;
	z-index:2;
}

.grid-cell-image{
	position:absolute;
	background-size: cover;
	filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
	height: 100%;
	position: absolute;
	width: 100%;
}

.grid-cell-name-shadow{
	position: absolute;
	width: 100%;
	bottom: 0px;
	height: 27%;
	background: linear-gradient(transparent, rgba(0, 0, 0, 0.8)) repeat scroll 0px 0px transparent;
}

.masonry-bigcell-reverse-info{
	background-color:rgba(188, 16, 0, 0.8);
    bottom: 0;
    padding-left: 29px;
    padding-right: 29px;
    position: absolute;
    right: 0;
    top: 0;
	z-index:3;
	width:100%;
}

.masonry-cell{
	border:1px solid #585858;
	height: 0;
	/*padding-bottom: 25.2%;*/
	overflow:hidden;
}

.masonry-cell.col-lg-5c {
	padding-bottom: 100%;
}

.masonry-cell.col-lg-2c {
	padding-bottom: 60%;
}

.masonry-cell img{
	margin-bottom:-4px;
}

.masonry-cell-info{
	margin-bottom:2px;
	
	top:0;
	margin-top: 21px;
}

.masonry-cell-title{
	font-size: 18px;
	font-weight: bold;
	line-height: 22px;
}

.masonry-cell-nohighlight{
	position: absolute;
	height:100%;
	width:100%;
	background-color:rgba(0,0,0,0.6);
	z-index:2;
}

.masonry-cell-reverse-first-name{
	font-family: oxygen,verdana,sans-serif;
	font-size: 18px;
	font-weight: bold;
}

.masonry-cell-reverse-description{
	font-family: oxygen,verdana,sans-serif;
	font-size: 12px;
	margin-top: 10px;
	max-height: 47px;
	overflow: hidden;
}

.masonry-cell-reverse-categories {
    color: #ff6b00;
    font-family: Oxygen,Verdana,sans-serif;
    font-size: 14px;
    margin-top: 5px;
}

.masonry-cell-reverse-content-type{
	background-color: #484848;
    display: inline-block;
    margin-top: 10px;
    padding: 8px 11px;
}

@media (min-width: 992px){
	.col-md-5c {
		width:20%;
		float:left;
	}
	
	.col-md-2c{
		width:40%;
		float:left;
	}
}

@media (min-width: 768px) {
	.grid-cell-reverse-first-name{
		font-size: 26px;
		line-height: 27px;
	}
	
	.grid-cell-reverse-surname{
		font-size: 26px;
		line-height: 26px;
	}
	
	.grid-cell-reverse-position{
		font-size: 16px;
	}
	
	.grid-cell-reverse-description{
		font-size: 12px;
	}
	
	.teamsContainer{
		top:45px;
	}
	
	.teams{
		margin-top:0px;
	}
}

@media(min-width: 855px){
	.grid-cell-reverse-first-name{
		font-size: 32px;
	}
	
	.grid-cell-reverse-surname{
		font-size: 32px;
	}
	
	.grid-cell-reverse-position{
		font-size: 19px;
	}
	
	.grid-cell-reverse-description{
		font-size: 13px;
	}
}

@media (min-width: 992px) {
	.gridName{
		width:35%;
	}
	
	.gridDescription {
		padding-top: 8px;
	}
	
	.masonryBody{margin-left:-20px;}
	
	.masonry-bigcell-reverse-info{width:50%;}
	
	.masonry-cell-info{
		top:auto;
		margin-top: 0px;
	}
	
	.grid-cell-reverse-first-name{
		font-size: 29px;
	}
	
	.grid-cell-reverse-surname{
		font-size: 29px;
	}
	
	.grid-cell-reverse-position{
		font-size: 17px;
		margin-bottom: 5px;
	}
	
	.grid-cell-reverse-description{
		font-size: 12px;
	}
	
	.masonry-cell-reverse-first-name{
		font-size: 16px;
	}
	
	.masonry-cell-reverse-categories{
		font-size: 13px;
	}
	
	.masonry-cell.col-lg-5c {
		padding-bottom: 25.2%;
	}

	.masonry-cell.col-lg-2c {
		padding-bottom: 25.2%;
	}
}

@media (min-width: 1030px) {
	.grid-cell-reverse-first-name{
		font-size: 33px;
	}
	
	.grid-cell-reverse-surname{
		font-size: 33px;
	}
	
	.grid-cell-reverse-position{
		font-size: 18px;
	}
	
	.grid-cell-reverse-description{
		font-size: 12px;
	}
}

@media (min-width: 1200px) {
	.gridName{
		width:25%;
	}
	
	.gridDescription {
		padding-bottom: 16px;
		padding-top: 18px;
	}
	
	.teamsContainer{
		top:0px;
		right:0px;
	}
	
	.teams {
		right: 20px;
		/*top: 15px;*/
		top:-5px;
		position: absolute;
		margin-top:0px;
	}
	
	.teamTypeSelect, .teamSelect {
		width: 68%;
	}
	
	.grid-cell-reverse-first-name{
		font-size: 25px;
	}
	
	.grid-cell-reverse-surname{
		font-size: 25px;
	}
	
	.grid-cell-reverse-position{
		font-size: 15px;
		margin-bottom: 5px;
	}
	
	.grid-cell-reverse-description{
		font-size: 12px;
	}
	
	.masonry-cell-reverse-categories {
		margin-top: 9px;
	}
	
	.masonry-cell-reverse-description{
		margin-top: 5px;
		max-height: none;
		overflow: visible;
	}
	
	.grid-cell-reverse-mail {
		margin-top: -10px;
	}
	
	.masonry-cell-reverse-first-name{
		font-size: 14px;
	}
	
	.masonry-cell-reverse-categories{
		font-size: 13px;
	}
	
	.masonry-cell-reverse-content-type{
		margin-top: 10px;
	}
}

@media (min-width: 1300px) {
	.grid-cell-reverse-first-name{
		font-size: 33px;
	}
	
	.grid-cell-reverse-surname{
		font-size: 33px;
	}
	
	.grid-cell-reverse-position{
		font-size: 18px;
		margin-bottom: 10px;
	}
	
	.grid-cell-reverse-description{
		font-size: 13px;
	}
	
	.teamTypeSelect, .teamSelect {
		width: 75%;
	}
}

@media (min-width: 705px) {
	.teams{
		float:right;
		margin-top:15px;
	}
	
	.masonry-cell-reverse-content-type{margin-top: 23px;}
}

@media (min-width: 1340px) {
	.masonry-cell-reverse-first-name{
		font-size: 18px;
	}
	
	.masonry-cell-reverse-categories{
		font-size: 14px;
	}
	
	.masonry-cell-reverse-content-type{
		margin-top: 23px;
	}
	
	.masonry-cell-reverse-description{
		margin-top: 20px;
	}
}

@media (min-width: 1200px){
	.col-lg-5c {
		width:20%;
		float:left;
	}
	
	.col-lg-2c{
		width:40%;
		float:left;
	}
}
