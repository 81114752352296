.ColCar {
    position: static;
    vertical-align: top;
    padding: 20px;

    .Info {
        padding: 40px;

        .title {
            font-size: 24px;
            height: 54px;
            vertical-align: top;
        }

        .description {
            font-size: 15px;
        }

        .btn {
            width: auto;
            //margin-left: 12px;
            color: white;
            background-color: #ff9200;
            border: none;
            margin-top: 17px;
            .icon {
                margin-right: 10px;
                font-size: 1.2rem;
            }

            &:hover {
                background-color: #ffa623;
            }

            @media (min-width: 992px) {
                bottom: 20px;
                position: absolute;
            }
        }

        @media (min-width: 992px) {
            /* padding-bottom: 70px; */
            padding-bottom: 105px;
            padding-left: 49px;
            padding-right: 49px;
        }
    }

    &:hover{
        .Info{
            .title{
                color: #daab00;
            }
        }
    }


}