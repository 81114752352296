.card {
    border: none;
    background-color: black;
    margin-bottom: 24px;
    border-radius: 0 !important;
    cursor: pointer;
    position: relative;

    //height: 90%;
    //height: 274px;
    /* .top {
        display: flex;
        justify-content: center;
        flex-direction: column;
        .imagess{
            border-radius: 0 !important;
            height: 100%;
        }
    } */
    .imagess {
        width: 100%;
        /* height: 132px; */
        height: 0px;
        padding-bottom: 80%;
        background-size: cover;
    }

    .title {
        //margin-top: -35px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        bottom: 0px;
        display: block;
        left: 0;
        padding: 10px;
        position: absolute;
        text-align: center;
        text-overflow: ellipsis !important;
        overflow: hidden;
        white-space: nowrap;
    }
}