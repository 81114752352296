.shareLink {
    margin: 16px 0 16px 0;

    .title {
        border: 0 none;
        color: #456;
        font-size: 18px;
        margin-bottom: 10px;
        padding-top: 5px;
    }

    .value {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        background-color: white;
        //border: 3px solid white;
        padding-bottom: 4px;
        padding-right: 5px;
        //padding-top: 3px;
        position: relative;

        input {
            width: 95%;
            height: 32px;
            //border-radius: 5px 0px 0px 5px;
            border: 1px solid #d1d1d1;
        }

        .btn {
            text-align: center;
            height: 32px;
            width: 32px;
            padding: 0;
            //border-radius: 0 5px 5px 0;
            background-color: #d1d1d1;
            color: black;
            border: 1px solid #d1d1d1;

            &:hover {
                background-color: #9c9c9c;
                color: black;
            }
        }
    }

    @media screen and (max-width: 991px) {
        margin-top: 0;  
    }
}

.socialLinks {
    margin: 16px 0 16px 0;

    .title {
        border: 0 none;
        color: #456;
        font-size: 18px;
        margin-bottom: 10px;
        padding-top: 5px;
    }

    .value {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .btn {
            cursor: pointer;
            border-radius: 5px;

            .icon {
                color: white;
                font-size: 30px;
            }

            img{
                width: 32px;
                height: 32px;
            }

            //padding: 5px;
            margin: 0 8px 0 0;
        }
    }
    @media screen and (max-width: 991px) {
        margin-top: 0;  
    }
}

.embedCode {
    width: 100%;
    margin-bottom: 16px;

    .title {
        font-size: 19.5px;
        font-weight: bold;
    }

    .value {
        textarea {
            width: 100%;
        }
    }
}

.shareemail {
    iframe {
        border: 0 none;
        box-sizing: border-box;
        width: 102.5%;
        margin-left: -4px;
        padding: 0;
        height: 40px;
        margin-top: -5px;
    }

    .title {
        border: 0 none;
        color: #456;
        font-size: 18px;
        margin-bottom: 10px;
        padding-top: 5px;
    }
}

.mBody {
    background-color: white !important;
    color: black !important;
}