.search{
    /* max-width: 350px; */
    /* display: flex; */
    margin-top: 38px;
    .input{
        width: 212px;
        font-family: Oxygen, Verdana, Sans-serif;
        color: black;
    }
    .btn{
        margin-left: 5px;
        width: autp;
    }
}