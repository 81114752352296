.MostR{
    width: 100%;
    padding-left: 23px;
    padding-right: 23px;
    .title{
        background-color: #5b5b5b;
        font-size: 22px;
        padding: 5px;
        text-align: center;
    }
    .col{
        margin-bottom: 10px;
        margin-top: 10px;
    }
    @media screen and (max-width: 993px) {
        .nonFirst{
            display: none;
        }
    }
        
}