.notfound {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    width: 100%;
    height: 100%;

    .content {
        background-color: rgb(0, 0, 0, 0.6);
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .m404 {
            font-size: 200px;
            font-weight: 700;
            color: #ffa200;
            letter-spacing: 15px;
            width: 100%;
            text-align: center;
        }

        .message {
            text-align: center;
            font-size: 30px;
            font-weight: 700;
            color: #fff;
            letter-spacing: 5px;
        }

    }

}