.btn {
    width: auto;
    //margin-left: 12px;
    color: white;
    background-color: #25455a;
    border: none;
    //bottom: 20px;
    //position: absolute;
    .icon {
        margin-right: 6px;
        font-size: 1.2rem;
    }
    &:hover {
        background-color: #4c7892 !important;
    }
}