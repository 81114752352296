.card {
    border: none;
    background-color: black;
    margin-bottom: 24px;

    //height: 274px;
    .top {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 203px;
        .imagess{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        .icon {
            font-size: 80px;
            position: absolute;
            left: 0px;
            display: none;
            z-index: 1;
            /* visibility: hidden;
            opacity: 0;
            transition: visibility 0.5s, opacity 0.5s, transform 0.6s linear; */
        }
        span{
            height: 100%;
        }
        .srDescEffect {
            position: absolute;
            left: 0px;
            background-image: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.8));
            background-position: center center;
            background-repeat: no-repeat;
            height: 100%;
            width: 30%;
        }
    }

    .body {
        padding: 20px;

        .title {
            font-size: 30px;
            z-index: 1;
            position: sticky;
            text-transform: uppercase;
        }
        .description{
            z-index: 1;
            position: sticky;
            font-size: 12px;
            margin-top: 15px;
            line-height: 1.6em;
        }
    }

    &:hover {
        cursor: pointer;

        .top {
            .icon {
                display: block;
                /* visibility: visible;
                opacity: 1;
                transform: translateX(15px); */
            }
        }

        .body {
            .title {
                color: #ffa200;
            }
        }
    }
}