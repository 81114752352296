.card {
    padding: 20px;
    background-color: #282828;
    min-height: 100%;
    margin-top : 10px;
    margin-bottom : 10px;
    //position: relative;
    .date {
        color: #a1a1a1;
        font-size: 14px;
        margin-bottom: 30px;
    }

    .title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 18px;
    }

    .description {
        font-size: 12px;
        margin-bottom: 9px;
    }

    .btn{
        .icon{
            margin-right: 5px;
        }
        /* bottom: 20px;
        position: absolute; */
        background-color: #bc1000;
        color: white;
        &:hover{
            background-color: #d12c10;
        }
    }
    @media screen and (max-width: 992px) {
        min-height: unset;
    }
}