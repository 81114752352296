.main {
    padding-left: 20px !important;
    padding-right: 20px !important;
    min-height: 370px;

    .welcome {
        margin-right: 60px;
    }

    .videoRow {
        justify-content: flex-end;
        margin-right: 60px;
    }
    .options{
        margin-top: 110px;
        border-top: 1px solid gray;
        margin-bottom: 70px;
    }
    @media (max-width: 991px) {
        .welcome {
            margin-right: 0px;
            .colNews {
                margin-top: 41px;
                border-top: 1px solid gray;
            }
        }
        .videoRow {
            //float: center;
            margin-top: 50px;
            margin-right: 0px;
            width: 100%;
            display: flex;
            justify-content: center;
        }
        .options {
            //float: center;
            margin-top: 50px;
        }

    }
}