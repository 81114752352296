.cont {
    display: flex;
    justify-content: center;

    .fGroup {
        //margin-bottom: 20px;
        font-family: "Oxygen", Verdana, sans-serif;
        ;
        color: black;

        .control {
            font-family: "Oxygen", Verdana, sans-serif;
            ;
            color: black;
        }
    }

    .btn {
        background-color: #ff9200;
        border-color: #ff9200;

        &:hover {
            background-color: #ffa623;
            border-color: #ffa623;
        }
    }
}

.other {
    margin-top: 25px;

    a {
        color: white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}