.swiper {
    margin-top: 40px;

    .categorie {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .title {
            font-size: 22px;
            padding-left: 5px;
        }

        .icon {
            font-size: 20px;
        }
    }

    @media screen and (max-width: 992px) {
        display: none;
    }
}

.selectCont {
    padding: 0 35px 0 20px;
    .select {
        margin-top: 40px;
        color: black;
        font-family: Oxygen, Verdana, Sans-serif;
        width: 100%;
        display: none;

        @media screen and (max-width: 992px) {
            display: inherit;
        }
    }
}