.gpwrapper {
    width: 100%;
    /* height: 100%; */
    position: relative;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
    //aspect-ratio: 4/3;
}

.overlay {
    color: red;
    font-weight: bold;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px !important;
    right: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity ease 0.3s;
    z-index: 100;

    /* @media screen and (max-width: 331px) {
        height: 97% !important;
    }

    @media screen and (min-width: 332px) and (max-width: 460px) {
        height: 98% !important;
    }

    @media screen and (min-width: 461px) and (max-width: 768px) {
        height: 98.4%;
    } */

    /* &:hover {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAECCAYAAAA/9r2TAAAAAXNSR0IArs4c6QAAASpJREFUOE9tyOdHBQAYhfHb3nvvuu2997jNe29TJJEkkkgSSSSJJJJEEkkiifRH5nm/HC+dDz+eEwj8vxhuI9YTR4p4UiSQRqIniRTJpEghjVRPGinSSZFBikzSyPJkkyKHFLmkyCONfE8BKQpJUUQaxZ4SUpSSoow0yj0VpKgkRRUpqkmjxhMkRS0p6khRTxoNnkZSNJGimTRaPK2kaCNFO2l0eDpJ0UWKblL0kEavp48U/aQYIMUgaQx5hkkxQopR0hjzjJNighQh0pj0TJFimhQzpJgljTlPmBQRUkRJMU8aC55FUiyRYplcgVVYg3XYgE3Ygm3YgV3Yg304gEM4gmM4gVM4g3O4gEu4gmu4gVu4g3t4gEd4gmd4gVd4g3f4gE/4gm/4gd8/PKs0rZfQuEsAAAAASUVORK5CYII=");
        background-repeat: repeat-x;
        background-position: bottom;
        opacity: 1;
    }

    @supports (-webkit-touch-callout: none) {
        // Reglas específicas para Safari
        &:hover {
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAECCAYAAAA/9r2TAAAAAXNSR0IArs4c6QAAASpJREFUOE9tyOdHBQAYhfHb3nvvuu2997jNe29TJJEkkkgSSSSJJJJEEkkiifRH5nm/HC+dDz+eEwj8vxhuI9YTR4p4UiSQRqIniRTJpEghjVRPGinSSZFBikzSyPJkkyKHFLmkyCONfE8BKQpJUUQaxZ4SUpSSoow0yj0VpKgkRRUpqkmjxhMkRS0p6khRTxoNnkZSNJGimTRaPK2kaCNFO2l0eDpJ0UWKblL0kEavp48U/aQYIMUgaQx5hkkxQopR0hjzjJNighQh0pj0TJFimhQzpJgljTlPmBQRUkRJMU8aC55FUiyRYplcgVVYg3XYgE3Ygm3YgV3Yg304gEM4gmM4gVM4g3O4gEu4gmu4gVu4g3t4gEd4gmd4gVd4g3f4gE/4gm/4gd8/PKs0rZfQuEsAAAAASUVORK5CYII=");
            background-repeat: repeat-x;
            background-position: bottom;
            opacity: 1;
        }

        /* .statuspaused {
            // ... (propiedades específicas para .statuspaused en Safari)
        } 
    } */

}

.overlayOver {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAECCAYAAAA/9r2TAAAAAXNSR0IArs4c6QAAASpJREFUOE9tyOdHBQAYhfHb3nvvuu2997jNe29TJJEkkkgSSSSJJJJEEkkiifRH5nm/HC+dDz+eEwj8vxhuI9YTR4p4UiSQRqIniRTJpEghjVRPGinSSZFBikzSyPJkkyKHFLmkyCONfE8BKQpJUUQaxZ4SUpSSoow0yj0VpKgkRRUpqkmjxhMkRS0p6khRTxoNnkZSNJGimTRaPK2kaCNFO2l0eDpJ0UWKblL0kEavp48U/aQYIMUgaQx5hkkxQopR0hjzjJNighQh0pj0TJFimhQzpJgljTlPmBQRUkRJMU8aC55FUiyRYplcgVVYg3XYgE3Ygm3YgV3Yg304gEM4gmM4gVM4g3O4gEu4gmu4gVu4g3t4gEd4gmd4gVd4g3f4gE/4gm/4gd8/PKs0rZfQuEsAAAAASUVORK5CYII=");
    background-repeat: repeat-x;
    background-position: bottom;
    opacity: 1 !important;
    z-index: 100;
}

.Ofullscreen {
    height: 100%;
}

/* .overlay:hover {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAECCAYAAAA/9r2TAAAAAXNSR0IArs4c6QAAASpJREFUOE9tyOdHBQAYhfHb3nvvuu2997jNe29TJJEkkkgSSSSJJJJEEkkiifRH5nm/HC+dDz+eEwj8vxhuI9YTR4p4UiSQRqIniRTJpEghjVRPGinSSZFBikzSyPJkkyKHFLmkyCONfE8BKQpJUUQaxZ4SUpSSoow0yj0VpKgkRRUpqkmjxhMkRS0p6khRTxoNnkZSNJGimTRaPK2kaCNFO2l0eDpJ0UWKblL0kEavp48U/aQYIMUgaQx5hkkxQopR0hjzjJNighQh0pj0TJFimhQzpJgljTlPmBQRUkRJMU8aC55FUiyRYplcgVVYg3XYgE3Ygm3YgV3Yg304gEM4gmM4gVM4g3O4gEu4gmu4gVu4g3t4gEd4gmd4gVd4g3f4gE/4gm/4gd8/PKs0rZfQuEsAAAAASUVORK5CYII=");
    background-repeat: repeat-x;
    background-position: bottom;
    opacity: 1;
}
 */
.firstTouch {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAECCAYAAAA/9r2TAAAAAXNSR0IArs4c6QAAASpJREFUOE9tyOdHBQAYhfHb3nvvuu2997jNe29TJJEkkkgSSSSJJJJEEkkiifRH5nm/HC+dDz+eEwj8vxhuI9YTR4p4UiSQRqIniRTJpEghjVRPGinSSZFBikzSyPJkkyKHFLmkyCONfE8BKQpJUUQaxZ4SUpSSoow0yj0VpKgkRRUpqkmjxhMkRS0p6khRTxoNnkZSNJGimTRaPK2kaCNFO2l0eDpJ0UWKblL0kEavp48U/aQYIMUgaQx5hkkxQopR0hjzjJNighQh0pj0TJFimhQzpJgljTlPmBQRUkRJMU8aC55FUiyRYplcgVVYg3XYgE3Ygm3YgV3Yg304gEM4gmM4gVM4g3O4gEu4gmu4gVu4g3t4gEd4gmd4gVd4g3f4gE/4gm/4gd8/PKs0rZfQuEsAAAAASUVORK5CYII=");
    background-repeat: repeat-x;
    background-position: bottom;
    opacity: 1;
    z-index: 100;
}

.viewarea {
    background-color: transparent;
    height: 100%;

    width: 100%;
    cursor: pointer;
}

.controlarea {
    height: 48px;
    display: flex;
    flex-direction: row;
    color: white;
    position: relative;
    /* left:2px;
    right:2px; */
}

.playbtn {
    width: 48px;
    height: 42px;
    background: transparent;
    border: none;
    padding: 0px;
    display: flex;
    align-items: center;
    vertical-align: middle;

}

.spkrbtncnt,
.timesection {
    width: 48px;
    height: 42px;
    background: transparent;
    border: none;

}

.spkrbtn {
    width: 40px;
    height: 42px;
    background: transparent;
    border: none;
    padding-left: 0px;
    padding-right: 0px;
}

.spkrbtncnt {
    transition: width ease 0.1s;
    width: auto;
    display: flex;
    flex-direction: row;
}

/* @media screen and (max-width: 768px) {
    .spkrbtncnt {
        display: none !important;
    }
} */

.spkrbtncnt:hover {
    width: 96px;
}

.soundbarmoving .soundbarcont,
.spkrbtncnt:hover .soundbarcont {
    width: 48px;
}

.soundbarcont {
    transition: width ease 0.2s;
    cursor: pointer;
    width: 0px;
    height: 42px;
    background: transparent;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.soundbarhandle {
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 6px;
    position: absolute;
    transition: left ease 0.1s;
    cursor: pointer;
    z-index: 0;
}

.soundbarhandle::after,
.soundbarhandle::before {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    left: 0;
    height: 3px;
    margin-top: -2px;
    width: 64px;

}

.soundbarhandle::before {
    background-color: rgba(255, 255, 255, 1);
    left: -58px;
}

.soundbarhandle::after {
    background-color: rgba(255, 255, 255, .2);
    right: 6px;
}

.timesection {
    width: 56px;
    height: 42px;
    background: transparent;
    border: none;
    font-size: 10px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.playicon {
    stroke: white;
    color: white;
    fill: white;
}

.iconfill {
    fill: #fff;
}

.soundbarmoving,
.statuspaused {
    opacity: 1 !important;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAECCAYAAAA/9r2TAAAAAXNSR0IArs4c6QAAASpJREFUOE9tyOdHBQAYhfHb3nvvuu2997jNe29TJJEkkkgSSSSJJJJEEkkiifRH5nm/HC+dDz+eEwj8vxhuI9YTR4p4UiSQRqIniRTJpEghjVRPGinSSZFBikzSyPJkkyKHFLmkyCONfE8BKQpJUUQaxZ4SUpSSoow0yj0VpKgkRRUpqkmjxhMkRS0p6khRTxoNnkZSNJGimTRaPK2kaCNFO2l0eDpJ0UWKblL0kEavp48U/aQYIMUgaQx5hkkxQopR0hjzjJNighQh0pj0TJFimhQzpJgljTlPmBQRUkRJMU8aC55FUiyRYplcgVVYg3XYgE3Ygm3YgV3Yg304gEM4gmM4gVM4g3O4gEu4gmu4gVu4g3t4gEd4gmd4gVd4g3f4gE/4gm/4gd8/PKs0rZfQuEsAAAAASUVORK5CYII=");
    background-repeat: repeat-x;
    background-position: bottom;
    z-index: 100;
    //opacity: 1;
}

.statusplaying {
    opacity: 0;
}

.timebarc {
    height: 7px;
    text-align: center;
    cursor: pointer;
    position: relative;
    margin: 0px 10px;

    /* display: flex;
    justify-content: center; */
    .timeline {

        background-color: rgba(255, 255, 255, 0.5);
        height: 4px;
        width: 100%;
        //margin: 0px 10px;
        position: relative;
        transition: all ease 0.1s;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: calc(100% - var(--progress));
            background-color: red;
            //transition: all ease 150ms;
        }

    }

    .progressindicator {
        --scale: 0;
        z-index: 1;
        position: absolute;
        transform: translateX(-50%) scale(var(--scale));
        height: 200%;
        top: -50%;
        left: var(--progress);
        background: white;
        border-radius: 50%;
        transition: transform 150ms ease-in-out;
        aspect-ratio: 1/1;
    }

    &:hover {
        .timeline {
            height: 100%;

            .progressindicator {
                --scale: 1;
            }
        }

        .progressindicator {
            --scale: 1;
        }
    }

}

.timebar {
    width: calc(100% - 20px);
    background: transparent;
    height: 5px;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.5);
    transition: all ease 0.1s;
    cursor: pointer;
    margin-top: -5px;
    overflow: hidden;
}

.timebarc:hover .timebar {
    height: 8px;
    margin-top: -10px;
}

.progressibar {
    position: relative;
    left: 0px;

    background-color: red;
    width: 2.5%;
    height: 100%;

}

.timebarc:hover .progressmarkbar {
    //top: -8px;
    opacity: 1;
}

.progressmarkbar {
    position: absolute;
    left: 0px;
    background-color: orange;
    z-index: 200;
    width: 2.5%;
    height: 100%;
    //top: -80%;
    opacity: 0.8;
}

.timebarc:hover .progressnudge {
    height: 16px;
    width: 16px;
    //top: -13px;

}

.seekthumbc {
    display: none;
}

.timebarc:hover .seekthumbc {
    display: block;
    background: black;
    width: 48px;
    height: 36px;
    position: absolute;
    top: -68px;
    box-shadow: 2px 2px 2px rgba(255, 255, 255, .2);
    border: 1px rgba(255, 255, 255, .2) solid;
    font-size: 7px;
    color: white;
}

.progressnudge {
    border-radius: 16px;
    background: white;
    position: relative;
    top: -10px;
    width: 14px;
    height: 14px;
    left: 2.5%;
    transition: all ease 0.1s;
    cursor: pointer;
}

.pipbtnc {

    width: 48px;
    height: 42px;
    background: transparent;
    border: none;
    padding: 0px;
    display: flex;
    align-items: center;
    vertical-align: middle;

}

.fullscrbtnc {
    width: 48px;
    height: 42px;
    background: transparent;
    border: none;
    padding: 0px;
    display: flex;
    align-items: center;
    vertical-align: middle;

}

.filler {
    flex-grow: 1;
}

@media only screen and (max-width: 600px) {}