.option {
    .title {
        font-size: 45.63px;
    }

    .description {
        margin-top: 15px;
    }

    .btn {
        background-color: #25455a;
        border-color: #25455a;
        color: white;
        display: inline-block;
        font-size: 14px;
        //height: 40px;
        margin-top: 17px;
        &:hover {
            background-color: #4c7892;
            border-color: #4c7892;
            text-decoration: none;
        }
        .icon {
            margin-right: 3px;
            font-size: 1.2rem;
        }
    }
}