.orderby {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        .title {
            width: 100px;
            font-size: 11px;
        }

        select {
            color: black;
            font-family: Oxygen, Verdana, Sans-serif;
        }
    }
}