.btn {
    width: auto;
    margin-top: 17px;
    color: white;

    .icon {
        margin-right: 6px;
        font-size: 1.2rem;
    }

    background-color: #d55a00;
    border-color: #d55a00;

    &:hover {
        background-color: #e46713 !important;
        border-color: #e46713 !important;
    }
}