.menu {
    padding: 20px;

    .top {
        margin-right: 0 !important;
        margin-bottom: 25px;

        .imageOptions {
            display: flex;
            flex-direction: row;
            padding-top: 20px;

            @media screen and (max-width: 570px) {
                flex-direction: column;
            }

            /*  border-right: 1px solid gray; */
            @media screen and (max-width: 992px) {
                border-right: none !important;
            }
        }

        .menuOptions {
            padding: 20px;
            display: flex;
            flex-direction: row;

        }
    }

    .extraOption {
        margin: 0 !important;
        display: flex;
        justify-content: flex-start;
        color: #fff;
        text-decoration: none;

        .btn {
            border: none;
            background: none;
            color: #fff;
            font-size: 14px;
            text-align: left;
            width: auto;
            color: #fff;
            text-decoration: none;

            .icon {
                margin-right: 5px;
                color: #fff;
            }

            &:hover {
                background: none;
                color: #ff9200 !important;
            }
        }
    }
}