.nopermission {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0.85);
    color: #FFFFFF;
    font-weight: bold;
    margin: 100px auto auto;
    padding: 15px 10px;
    text-align: center;
    a{
        color: #FFFFFF;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
