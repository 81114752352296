.button {
    cursor: pointer;
    display: flex;
    font-size: 14px;
    margin-bottom: 5px;
    padding: 10px;
    justify-content: space-between !important;
    align-items: center;
    background-color: transparent;
    border-color: transparent;
    width: 100%;

    &:hover {
        //font-size: 15px;
        font-weight: bold;
        background-color: transparent !important;
        border-color: transparent !important;
    }

    .Icon{
        display: flex;
    }

    .icon {
        width: 7px;
        height: 7px;
        margin-right: 10px;

    }

    .iconPlus {
        width: 15px;
        height: 15px;
        margin-right: 10px;
        z-index: 100;
    }


}

.timeMark {
    width: 100%;
    /* display: flex;
    justify-content: space-between; */
    align-items: center;
    text-align: start;
}

.active {
    font-weight: bold;
    background-color: #727272;

    &:hover {
        //font-size: 15px;
        font-weight: bold;
        background-color: #727272 !important;
        border-color: #727272 !important;
    }

}