/* Color Overrides */

/* $primary: #6ab42f;
$nav-pills-link-active-color: $primary;
$primary-light: rgba($primary, 0.2); */

/* This theme colors */

$theme-odd-even-color: #efefef;
$theme-review-data-color: blue;

/* padding overrides */
$input-padding-y: 2px;
$input-padding-x: 2px;
$input-font-family: 'Courier New';
$input-border-radius: 2px;

/* Fonts */
$font-size-base: 0.9rem;
$font-family-sans-serif: 'Oxygen', Verdana, sans-serif;

/*spaces*/
$spacer: 0.4rem;
$modal-header-padding: 10px;
$modal-md: 50vw;

/* Text-color */
$body-color: white;

/* modal */
$modal-inner-padding:   20px;
$modal-content-bg:      black;

$navbar-light-hover-color:  #C1272D;
//$dropdown-hover-bg: purple;

//button border radius
$btn-border-radius:  0px;

/*pagination*/
$pagination-active-bg: #ff9201 !important;
$pagination-active-color: #fff !important;
$pagination-active-border-color:#ff9201!important;

$pagination-hover-bg:               #960c00 !important;
$pagination-hover-border-color:     #960c00 !important;
$pagination-hover-color:     #fff  !important;

$pagination-color:                 #fff !important;
$pagination-border-color:                 #bc1000  !important;
$pagination-bg:                     #bc1000 !important;

$pagination-border-radius:          0 !important;

/* Color Overrides */

$primary: #2874a6;

$nav-pills-link-active-color: $primary;

$login-brand-width: 300px;