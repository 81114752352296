.btn{
    width: auto;
    //margin-left: 12px;
    background-color: #bc1000;
    border-color: #bc1000;
    .icon{
        margin-right: 10px;
        font-size: 1.2rem;
    }
    &:hover{
        background-color: #d12c10 !important;
        border-color: #d12c10 !important;
    }
}