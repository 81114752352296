.cont{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    .fGroup{
        margin-bottom: 20px;
        .control{
            font-family: "Oxygen", Verdana, sans-serif;;
            color: black;
        }
    }
    .btn{
        background-color: #ff9200;
        border-color: #ff9200;
        &:hover{
            background-color: #ffa623;
            border-color: #ffa623;
        }
    }
}

.ad{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    .redFont{
        color: red;
    }
}

.redFont{
    color: red;
}