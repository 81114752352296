
    .content {
        margin-top: 30px;
        .title {
            //font-family: "Oxygen";
            font-size: 38px;
            font-weight: bold;
            margin-bottom: 15px;
        }

        .date {
            color: #58bdff;
            font-size: 24px;
            margin-bottom: 41px;

            @media (min-width: 992px) {
                margin-bottom: 17px;
            }
        }

        .image {
            text-align: center;
            height: 300px;
            span{
                height: 100%;
            }
        }
        .share{
            margin-top: 20px;
            margin-bottom: 20px;

        }
    }