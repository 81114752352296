.main{
    display: flex;
    flex-direction: row;
    gap: 20px;
    .link{
        color: #ff4837;
        font-size: 17.14px;
        font-weight: normal;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
}