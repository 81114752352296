.heading {
	h1 {
		font-size: clamp(1.5rem, 5vw, 2.5rem);
		font-weight: 600;
		line-height: 1.2;

		letter-spacing: 2px;
	}
	h2 {
		font-size: clamp(1.25rem, 5vw, 2rem);
		font-weight: 600;
		line-height: 1.2;

		letter-spacing: 2px;
	}
	h3 {
		font-size: clamp(1.125rem, 5vw, 1.75rem);
		font-weight: 600;
		line-height: 1.2;

		letter-spacing: 2px;
	}
	h4 {
		font-size: clamp(1rem, 5vw, 1.5rem);
		font-weight: 600;
		line-height: 1.2;

		letter-spacing: 2px;
	}
	h5 {
		font-size: clamp(0.875rem, 5vw, 1.25rem);
		font-weight: 600;
		line-height: 1.2;

		letter-spacing: 2px;
	}
	h6 {
		font-size: clamp(0.75rem, 5vw, 1rem);
		font-weight: 600;
		line-height: 1.2;

		letter-spacing: 2px;
	}
}
