.typesRow {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 35px 50px 45px;
    margin-right: 0 !important;
    .container {
        .title {
            font-size: 28px;
            margin-bottom: 17px;
        }
        .description {
            font-size: 15px;
        }
    }
}