.whatWeDo {
    width: 100%;
    background-color: #484848;
    display: inline-block;
    float: none;
    padding: 15px;
    margin-top: 20px;

    .title {
        display: inline-block;
        font-size: 24px;
        font-weight: normal;
        margin-bottom: 20px;
    }

    .options {
        display: flex;
        flex-direction: column;

        .btn {
            cursor: pointer;
            display: flex;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
            padding: 10px;
            justify-content: space-between;
            align-items: center;
            background-color: #727272;
            border-color: #727272;
            .icon{
                margin-right: 20px;
            }
            &:hover {
                background-color: #616161 !important;
                border-color: #616161 !important;
            }
        }
    }
}