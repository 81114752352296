.options {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    .mainPart {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        background-color: #152631;
        padding: 6px;
    }

    .title {
        font-weight: bold;
        /* margin-right: 04px; */
        font-size: 12px;
        white-space: nowrap;
        display: inline-block;
        vertical-align: top;
    }

    .option {
        text-align: center !important;
        display: flex;
        flex-direction: row;

        .value {
            text-align: center !important;
            width: 65px !important;

            input {
                /* width: 100%;
                border: none !important;
                box-shadow: none !important;
                //height: 12px !important;
                //line-height: 12px;
                padding: 3px !important;
                font-size: 10px; */
                width: 100%;
                border: none !important;
                box-shadow: none !important;
                padding-top: 3px !important;
                font-size: 12px;
                /* height: 22px;*/

                &:focus {
                    outline: none !important;
                }
            }
        }

        .btn {
            background-color: #2b4e63;
            height: 22px;
            display: flex;
            flex-direction: row;
            cursor: pointer;
            padding: 0px 3px 3px;

            img {
                width: 33px !important;
                height: 33px !important;
            }

            .icon {
                font-size: 25px !important;
            }

            //width: 33px !important;
        }

        .btnStyle {
            //background-color: white;
            color: White;
            border: 1px solid black;
            width: auto !important;
            gap: 10px;
            padding: 4px !important;
            height: auto !important;
        }

    }

    /* @media screen and (max-width: 1020px) {
        justify-content: center;
        
    } */
}