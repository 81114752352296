.card {
    width: 100%;
    margin: 5px;
    cursor: pointer;
    text-decoration: none !important;
    color: #fff;
    .image {
        margin-bottom: 10px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: grayscale(100%);
        }

    }
    .btn{
        border: none;
        background: none;
        color: #fff;
        font-size: 14px;
        text-align: left;
        width: auto;

        .icon{
            margin-right: 5px;
            color: #fff;
        }
        
    }
    .options{
        .list{
            list-style-type: none;  
            text-decoration: none !important;
            li{
                margin-left: -20px;
                text-decoration: none !important;
                color: #fff;
            }
        }
    }
    &:hover{
        .btn{
        background: none;
        color: #ff9200;
        }
    }

}

@media screen and (max-width: 570px) {
    .card{
        width: 100%;
    }
}