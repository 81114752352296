.container {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    font-size: 17px;
    align-items: center;
    margin-top: 26px;
    margin-bottom: 32px;
    color: #ff4837;
    cursor: pointer;
    width: 230px;

    .icon {
        margin-right: 10px;
    }

    &:hover {
        .icon {
            color: #ff4837;
        }

        .text {
            text-decoration: underline;
            color: #ff4837;
        }
    }
}