.sideBar {
    margin-top: 30px;
    background-color: #282828;
    padding: 17px 19px;

    .nextNew {
        margin-bottom: 52px;

        .content {
            margin-top: 13px;

            .title {
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 12px;
            }

            .detail {
                color: #b2b2b2;
                font-size: 16px;
                max-height: 145px;
                //white-space: nowrap;
                text-overflow: ellipsis !important;
                overflow: hidden;
            }
        }
    }

    .moreNews {
        .title {
            color: #8a8a8a;
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 18px;
            text-align: center;
        }
    }

    .categories {
        clear: both;
        margin-bottom: 50px;
        padding-top: 60px;

        .title {
            font-size: 24px;
            margin-bottom: 10px;
        }

        .list {
            border-radius: 0;

            .item {
                background-color: #282828 !important;
                border: none;
                color: white;
                margin-top: 5px;
                margin-bottom: 5px;
                display: block;
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 10px;
                padding: 11px 15px 11px 0;
                width: 100%;

                .icon {
                    margin-right: 10px;
                    font-size: 1.2rem;
                }

                &:hover {
                    text-decoration: none;
                    background-color: #3f3f3f !important;
                }
            }

        }
    }

}