.search {
    
    .inputGroup {
        border: gray solid 1px;
        //border-radius: 5px;
        //transition: width 1s ease-in-out;
        &:focus-within {
            border: solid 2px black;
            border-radius: 5px;
            @media screen and (min-width: 1207px) {
                //transition: width 1s ease-in-out;
                width: 400px !important;
            }
        }

        .icon {
            background-color: transparent;
            color: gray;
            border: none;
            padding: 5px;
        }

        .input {
            border: none;
            background-color: transparent;
            color: gray;
            padding: 5px;
            font-family: Oxygen, Verdana, Sans-serif;
            outline: none !important;
            &:focus {
                box-shadow: none;
            }
        }
    }
    @media screen and (min-width: 1092px) {
        margin-left: 60px;
    }
}