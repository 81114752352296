.content {
    margin-top: 13px;

    .title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 12px;
    }

    .detail {
        color: #b2b2b2;
        font-size: 16px;
        max-height: 145px;
        //white-space: nowrap;
        text-overflow: ellipsis !important;
        overflow: hidden;
    }
}