.card {
    border: none;
    width: 100%;
    min-height: 410px;

    .imagess {
        width: 100%;
        height: 200px !important;
        object-fit: cover;
    }

    .body {
        color: white;
        display: flex;
        flex-direction: column;

        .title {
            font-weight: bold;
            margin: 20px 0 20px 0;
            font-size: 30px;
        }

        .btn {
            /* position: absolute;
                bottom: 5px; */
            margin-top: auto;
            color: white;
            border: none;
            width: 141px;
            .icon {
                margin-right: 10px;
                font-size: 1.2rem;
            }
        }

    }
}