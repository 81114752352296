.card{
    margin-bottom: 50px;
    .title{
        font-size: 45px;
        line-height: 45px;
        margin-bottom: 15px;
        display: inline-block;
    }
    .paragraph{
        font-size: 14px;
        line-height: 1.8em;
        max-width: 670px;
        margin-bottom: 25px;
    }
}

