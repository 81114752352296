.optionList{
    //margin: 10px;
    .title{
        width: auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .icon {
            margin-top: 5px;
            margin-right: 5px;
            //font-size: 20px !important;
            color: #fff;
        }
        
        &:hover {
            .icon {
                color: #ff9200;
            }
        }
    }
    .active{
        &:hover {
            background: none;
            color: #ff9200;
            cursor: pointer;
            .icon {
                color: #ff9200;
            }
        }
    }
    .options{
        /* display: flex;
        flex-direction: row; */
        
        color: #fff;
        text-decoration: none !important;
        .list{
            list-style-type: none;  
            .op{
                width: auto;
                margin-left: -12px;
                a{
                    color: #fff;
                    text-decoration: none !important;
                    &:hover {
                        cursor: pointer;
                        color: gray !important;
                    }
                }
            }
            .nonop{
                width: auto;
                margin-left: -12px;
            }
        }
    }
}