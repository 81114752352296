.swiper {
    margin-top: 40px;
    margin-bottom: 40px;

    .categorie {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        width: 100%;
        height: 35px;

        .title {
            font-size: 13px;
            padding-left: 5px;
            width: auto;
        }

        .icon {
            font-size: 13px;
            height: 100%;
        }

        &:hover {
            .title {

                text-decoration: underline;
            }
        }
    }

    .slide{
        width: 135px !important;
        margin-right: 10px !important;
    }

    @media screen and (max-width: 992px) {
        display: none !important;
    }
}

.selectCont {
    padding: 0 35px 0 20px;

    .select {
        margin-top: 40px;
        margin-bottom: 40px;
        color: black;
        font-family: Oxygen, Verdana, Sans-serif;
        width: 100%;
        display: none;

        @media screen and (max-width: 992px) {
            display: inherit;
        }
    }
}