@import "styles/variables.scss";

.wrapper {
  width: 100vw;
  min-height: 100vh;
}

.row {
  height: 100vh;
}

.brand {
    height: auto;
    width: $login-brand-width;
}

.background {
    //background-image: url('/assets/img/loginBackground.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.button {
  font-size: 16pt;
  font-weight: 500;
}

.link {
    text-decoration: none;
    font-size: 13pt;
    font-weight: 400;
}
