.item {
    //border: 0;
    border-radius: 0 !important;
    background-color: #484848;
    //margin-bottom: 10px;

    .header {
        border-radius: 0 !important;
        button {
            border-radius: 0 !important;
            background-color: rgb(34, 59, 75);
            //font-weight: bold;
            color: white;
            //text-transform: capitalize;
            padding: 10px;
        }

        background-color: black;
    }

    .body {
        background-color: rgb(37, 69, 90);
        max-height: 350px !important;
        overflow-y: scroll;
        .button {
            cursor: pointer;
            display: flex;
            font-size: 14px;
            margin-bottom: 5px;
            padding: 10px;
            justify-content: space-between !important;
            align-items: center;
            background-color: transparent;
            border-color: transparent;
            width: 100%;

            &:hover {
                //font-size: 15px;
                font-weight: bold;
            }
            .icon{
                width: 5px;
                height: 5px;
                margin-right: 10px;
        
            }
            .iconPlus{
                width: 15px;
                height: 15px;
                margin-right: 10px;
            }
        }
    }
}