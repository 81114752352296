.brand {
    cursor: pointer;
}

.navdd {
    //background-color: #000;
    position: unset !important;

    a {
        &:hover {
            color: #C1272D !important;
        }
    }
}

.loginop{
    @media screen and (min-width: 992px) {
        display: none !important;
        
    }
}

.logbtns {
    gap: 10px;

    .register {
        width: auto;
        //margin-left: 12px;
        background-color: #555555;
        border-color: #555555;
        padding: 5px 10px 5px 5px;
        color: white;
        text-decoration: none;

        .icon {
            margin-right: 5px;
            font-size: 1.2rem;
        }

        &:hover {
            background-color: #555555 !important;
            border-color: #555555 !important;
        }
    }

    .login {
        width: auto;
        //margin-left: 12px;
        background-color: #bc1000;
        border-color: #bc1000;
        padding: 5px 10px 5px 5px;
        color: white;
        text-decoration: none;

        .icon {
            margin-right: 5px;
            font-size: 1.2rem;
        }

        &:hover {
            background-color: #bc1000 !important;
            border-color: #bc1000 !important;
        }
    }

    @media screen and (max-width: 992px) {
        display: none !important;
        
    }
}

.search {
    .inputGroup {
        border: gray solid 1px;
        border-radius: 5px;

        .icon {
            background-color: transparent;
            color: gray;
            border: none;
            padding: 5px;
        }

        .input {
            border: none;
            background-color: transparent;
            color: gray;
            padding: 5px;
            font-family: Oxygen, Verdana, Sans-serif;
            transition: 1s ease-in-out;
            outline: none !important;

            &:focus {
                transition: 1s ease-in-out;
                width: 300px !important;
            }
        }
    }
}