.container {
    margin-top: 45px;
    margin-left: 11px;

    .title {
        font-size: 4.4em;
        line-height: 1em;
        font-weight: normal;
        margin-bottom: 15px;
        margin-top: 21px;
        padding-bottom: 13px;
        text-decoration: none;
        word-wrap: break-word;
    }

    .description {
        font-size: 18px;
    }

    .orderBy {
        display: flex;
        justify-content: flex-end;
        margin: 25px 12px 41px 0;
    }

    @media (max-width: 640px) {

        /* .top {
            .icon {
                font-size: 60px;
            }
        } */
        /* .body {
            margin-top: -67px;
            .title {
                font-size: 20px;
            }
        } */
        .title {
            font-size: 3em;
        }
    }
}