.card {
    position: relative;
    width: 100%;
    margin-bottom: 24px;
    .shadowEffect {
        background: linear-gradient(transparent, rgba(0, 0, 0, 0.8)) repeat scroll 0 0 transparent;
        height: 100%;
        position: absolute;
        width: 100%;
    }

    .image {
        width: 100%;
    }

    .label {
        bottom: 15px;
        /* font-size: 28px; */
        left: 10px;
        position: absolute;
        font-size: 23px;
        padding-top: 2px;
        pointer-events: none;
    }

    .bar {
        background-color: #a09e97;
        height: 8px;
        margin-top: -4px;
        position: relative;
    }
    
    &:hover{
        cursor: pointer;
        .shadowEffect {
            background-color: rgba(0, 0, 0, 0.5);
        }
        .label{
            transform: translateX(15px);
        }
    }
}