.swiper-button-next,
.swiper-button-prev {
  color: #fff !important; // Ajusta el color de las flechas según tu preferencia
  /* top: 50%;
  transform: translateY(-50%);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5); // Ajusta el color de fondo según tu preferencia
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10; */
}

/* .swiper-wrapper{
  display: flex;
  justify-content: center;
}
.swiper-button-next {
  //right: -20px; // Ajusta esta distancia según tu preferencia
}

.swiper-button-prev {
  //left: -20px; // Ajusta esta distancia según tu preferencia
} */

.memorial-showon {
  //width: auto;
  margin-top: 10px;

  //margin-bottom: 45px;
  .blue-btn {
    background-color: #25455a;
  }
}

.memorial-orange {
  background-color: #d65a00;
  margin-top: 50px;
  padding: 25px;
}

.memorial-red {
  background-color: #7c1e16;
  padding: 40px 25px 30px;
  display: flex;
  gap: 10px;
  width: 100%;

  .memorial-red-title {
    font-size: 24.41px;
    font-weight: bold;
    margin-bottom: 15px;
    background-image: url('../../../../assets/img/wx/drop-arr-big.png');
    background-position: 0 center;
    background-repeat: no-repeat;
    cursor: pointer;
    padding-left: 30px;
  }

  .memorial-red-hidden {
    background-image: url('../../../../assets/img/wx/about-arr.png');
    background-position: 0 9px;
    background-size: 15px 18px;
  }

  /* .memorial-red-desc {
    display: block;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .memorial-red-desc.hidden {
    display: none;
    opacity: 0;
  }
 */
 .memorial-red-desc {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.memorial-red-desc.hidden {
  opacity: 0;
  pointer-events: none; /* Opcional, para que no se pueda interactuar con el elemento mientras es invisible */
}
  .memorial-red-subsection {
    margin-bottom: 30px;
  }
}